<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-button icon="el-icon-plus" type="primary" @click="addSuperUserOpen"
					>添加管理员</el-button
				>
			</div>
			<not-found v-if="SuperUserData.length === 0" text="暂时还没有超级用户啦"></not-found>
			<el-row :gutter="20">
				<el-col :span="6" v-for="(item, index) in SuperUserData" :key="index">
					<el-card class="theSuperUser">
						<div class="name">
							<div class="SuperUserBox" :title="item.name">
								<i class="el-icon-user"></i>
								{{ item.name }}
							</div>
							<div class="btnBox">
								<el-button size="mini" type="primary" @click="editSuperUserOpen(item)">修改</el-button>
								<el-popconfirm title="这是一段内容确定删除吗？" @confirm="delSuperUser(item.id)">
									<template #reference>
										<el-button size="mini" type="danger">删除</el-button>
									</template>
								</el-popconfirm>
							</div>
						</div>
						<div>
							<i class="el-icon-mobile"></i>
							{{ item.phone }}
						</div>
						<div class="roles">
							<span class="tag" v-for="(role, index) in item.roles" :key="index">
								{{ role.role_name }}
								<el-popconfirm title="确定解除绑定该角色吗？" @confirm="unBindRole(item.id, role.role_id)">
									<template #reference>
										<span class="el-icon-close unBind"></span>
									</template>
								</el-popconfirm>
							</span>
							<span class="tag" @click="openSelectRole(item.id)">+</span>
						</div>
					</el-card>
				</el-col>
			</el-row>
			<div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
		</div>
		<!-- 弹出框 -->
        <el-dialog title="定义您的数据" v-model="dialog" width="30%">
            <el-form ref="form" :model="theSuperUserData" label-width="100px">
                <el-form-item label="超级用户名">
                    <el-input v-model="theSuperUserData.name"
					placeholder="请输入超级用户名"></el-input>
                </el-form-item>
                <el-form-item label="超级用户密码">
                    <el-input v-model="theSuperUserData.password"
					placeholder="请输入超级用户密码(若不修改请勿输入)"></el-input>
                </el-form-item>

                <el-form-item label="手机号" v-if="theSuperUserData.super_user_id === 0">
                    <el-input
                        v-model="theSuperUserData.phone"
                        placeholder="请输入超级用户手机号"
                        rows="3"
                    ></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="operateData"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
		<!-- 绑定角色弹出框 -->
		<el-dialog title="选择角色" v-model="selectRole" width="30%">
			<el-form ref="form" :model="theSuperUserData" label-width="100px">
                <el-form-item label="超级用户名">
                    <el-select v-model="theRoleId" placeholder="请选择角色">
						<el-option
						v-for="item in roleData"
						:key="item.id"
						:label="item.name"
						:value="item.id"
						>
						</el-option>
					</el-select>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="selectRole = false">取 消</el-button>
                    <el-button type="primary" @click="bindRole()"
                        >确 定</el-button
                    >
                </span>
            </template>
		</el-dialog>
	</div>
</template>
<script>

import NotFound from "../../components/NotFound.vue";
import { fetchData } from "../../api/api";

export default {
	name: "SuperUserlist",
	setup() {
		
	},
	created() {
        this.getData();
    },
	computed: {
		operateData() {
			return ()=>{
				this.operate == "add" ? this.SuperUserAdd() : this.SuperUserEdit();
			}
		}
	},
	components: {
		NotFound
	},
	data() {
		return {
			query: {
				page: 1,
				limit: 10,
			},
			pageTotal: 0,
			SuperUserData:[],
			roleData:[],
			dialog: false,
			selectRole: false,
			theRoleId: "",
			theBindUserId: 0,
			operate: "add", //add  edit
			theSuperUserData: {
				super_user_id: 0,
				name: '',
				password: '',
				phone: '',
			}
		}
	},
	methods: {
		getData() {
			this.getUserList();
			this.getRolesList();
		},
		getUserList() {
			fetchData(
				{ page: this.query.page, limit: this.query.limit},
				"/admin/v1/listSuperUsers",
				"GET"
			).then((res) => {
				this.pageTotal = res.data.count;
				this.SuperUserData = res.data.list;
				console.log(res);
			});
		},
		getRolesList() {
			fetchData(
				{ page: 1, limit: 999999},
				"/admin/v1/listRoles",
				"GET"
			).then((res) => {
				this.roleData = res.data.list;
			});
		},
		theSuperUserDataInit () {
			this.theSuperUserData = {
				super_user_id: 0,
				name: '',
				phone: '',
			};
		},
		addSuperUserOpen() {
			this.theSuperUserDataInit();
			this.dialog = true;
			this.operate = "add";
		},
		SuperUserAdd() {
			fetchData(
				{ name: this.theSuperUserData.name, phone: this.theSuperUserData.phone},
				"/admin/v1/addSuperUser",
				"POST"
			).then(() => {
				this.dialog = false;
				this.theSuperUserDataInit();
				this.$message.success("添加成功");
				this.getData();
			});
		},
		editSuperUserOpen(item) {
			this.theSuperUserData.super_user_id = item.id;
			this.theSuperUserData.name = item.name;
			this.theSuperUserData.phone = item.phone;
			this.dialog = true;
			this.operate = "edit";
		},
		SuperUserEdit() {
			let data = {
				super_user_id: this.theSuperUserData.super_user_id,
				name: this.theSuperUserData.name
			};
			if (this.theSuperUserData.password !== '') {
				data.password = this.theSuperUserData.password;
			}
			fetchData(
				data,
				"/admin/v1/editSuperUser",
				"POST"
			).then(() => {
				this.dialog = false;
				this.theSuperUserDataInit();
				this.$message.success("修改成功");
				this.getData();
			});
		},
		delSuperUser(id) {
			fetchData(
				{super_user_id: id},
				"/admin/v1/deleteSuperUser",
				"POST"
			).then(() => {
				this.$message.success("删除成功");
				this.getData();
			});
		},
		handlePageChange(val) {
			this.query.page = val;
			this.getData();
		},
		openSelectRole(id) {
			this.selectRole = true;
			this.theBindUserId = id;
		},
		bindRole() {
			fetchData(
				{ super_user_id: this.theBindUserId, role_id: this.theRoleId},
				"/admin/v1/bindRole",
				"GET"
			).then(() => {
				this.theRoleId = "";
				this.selectRole = false;
				this.getUserList();
				this.$message.success("绑定成功");
			});
		},
		unBindRole(userid, id) {
			fetchData(
				{ super_user_id: userid, role_id: id},
				"/admin/v1/unbindRole",
				"GET"
			).then(() => {
				this.getUserList();
				this.$message.success("解绑成功");
			});
		}
	}
}
</script>
<style scoped lang="scss">
.theSuperUser {
	margin-bottom: 1.5rem;
	.name{
		position: relative;
		line-height: 2rem;
		div {
			display: inline-block;
			&.SuperUserBox {
				max-width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-weight: bold;
				i {
					margin-right: 8px;
				}
			}
			&.btnBox {
				position: absolute;
				top: 0;
				right: 0;
				opacity: 0;
				transition: opacity 0.4s;
			}
		}
	}
	.roles {
		margin-top: 5px;
		.tag {
			display: inline-block;
			top: 3px;
			right: 20px;
			border: 1px solid #f5dab1;
			background-color: #fdf6ec;
			color: #e6a23c;
			border-radius: 5px;
			font-size: 12px;
			padding: 1px 9px;
			margin-right: 5px;
			.unBind{
				color: red;
				display: none;
			}
			&:hover {
				.unBind {
					display: inline-block;
				}
			}
		}
		span {
			cursor: pointer;
		}
	}
	.text{
		border-radius: 7px;
		background-color: #f3f3f3;    
		min-height: 60px;
		padding: 8px 17px;
		font-size: 14px;
	}
	&:hover {
		& .name .btnBox {
			opacity: 1;
		}
	}
}
</style>
