<template>
  <div class="main">
    <div class="container">
      <div class="handle-box">
        <el-button icon="el-icon-plus" type="primary" @click="openDialog('add')">添加模板
        </el-button>
      </div>
      <!-- <not-found v-if="bureauData.length === 0" text="机构找不到啦"></not-found> -->
      <el-tabs v-model="theTypeId" @tab-click="handleClick" class="the-tabs">
        <!-- <el-tab-pane label="所有模板方案" name="all"></el-tab-pane> -->
        <el-tab-pane :label="item.type_name" :name="item.type_id" v-for="(item, index) in actTypes" :key="index">
          <not-found v-if="templateList.length === 0" text="暂时还没有模板啦,快去添加吧！"></not-found>
          <div class="main-class">
            <div class="box-name-class" v-for="(item, index) in templateList" :key="index">
              <div class="border-name-class">
                <div class="title-class"><span></span>活动封面</div>
                <div><img :src="item.act_image_url" alt="" /></div>
              </div>
              <div class="border-name-class">
                <div class="title-class"><span></span>活动名称</div>
                <div>{{ item.act_name }}</div>
              </div>
              <div class="border-name-class">
                <div class="title-class"><span></span>活动标签</div>
                <div class="tag-class">
                  <span v-for="(value, key) in item.tags" :key="key">{{ value.tag_name }}
                    <i class="el-icon-close" @click="deleteTag(value, item)"></i>
                  </span>
                </div>
              </div>
              <div class="name-class">
                <div class="title-class"><span></span>是否推荐</div>
                <!--                                <div>{{ DataUtils.formatDate(item.act_expired_time) }}</div>-->
                <el-switch v-model="item.is_highlighted" active-color="#13ce66" inactive-color="#ff4949"
                  :active-value="activeValue" :inactive-value="inactiveValue" @click="handleSwitch(item)">
                </el-switch>
              </div>
              <div class="name-class">
                <div class="title-class"><span></span>合适的月份</div>
                <!--                                <div>{{ DataUtils.formatDate(item.act_expired_time) }}</div>-->
                <el-select v-model="item.fit_month" placeholder="请选择合适的月份" @change="handleSwitch(item)">
                  <el-option v-for="item in 12" :key="item" :label="item + '月份'" :value="item">
                  </el-option>
                </el-select>
              </div>

              <div class="cover-class">
                <el-button type="primary" plain size="mini" @click="actCreateTag(item)">
                  创建标签
                </el-button>
                <el-button type="success" plain size="mini" @click="actBodyDialog(item.act_id)">
                  活动页面
                </el-button>
                <el-button type="success" plain size="mini" @click="openDialog('edit', index, item)">
                  编辑
                </el-button>
                <el-popconfirm title="确定删除该模板吗？" @confirm="delTemplate(item.act_id)">
                  <template #reference>
                    <el-button type="danger" plain size="mini">删除</el-button>
                  </template>
                </el-popconfirm>
              </div>
            </div>
            <div class="after-add"></div>
            <div class="after-add"></div>
          </div>
          <!--                    <el-row gutter="30">-->
          <!--                        <el-col :span="6" v-for="(item, index) in templateList" :key="index" class="text item">-->
          <!--                            <el-card class="box-card">-->
          <!--                                <div class="img-box">-->
          <!--                                    <img :src="item.act_image_url" alt="">-->
          <!--                                </div>-->
          <!--                                <div class="title-box">-->
          <!--                                    <p class="title">-->
          <!--                                        {{ item.act_name }}-->
          <!--                                    </p>-->
          <!--                                    <div class="btn-box">-->
          <!--                                        <el-button type="success" plain size="mini" @click="actBodyDialog(item.act_id)">-->
          <!--                                            活动页面-->
          <!--                                        </el-button>-->
          <!--                                        <el-button type="success" plain size="mini" @click="openDialog('edit', index)">-->
          <!--                                            编辑-->
          <!--                                        </el-button>-->
          <!--                                        <el-popconfirm title="确定删除该模板吗？" @confirm="delTemplate(item.act_id)">-->
          <!--                                            <template #reference>-->
          <!--                                                <el-button type="danger" plain size="mini">删除</el-button>-->
          <!--                                            </template>-->
          <!--                                        </el-popconfirm>-->
          <!--                                    </div>-->
          <!--                                </div>-->
          <!--                            </el-card>-->
          <!--                        </el-col>-->
          <!--                    </el-row>-->
        </el-tab-pane>
      </el-tabs>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :current-page="query.page" :page-size="query.limit"
          :total="pageTotal" @current-change="handlePageChange"></el-pagination>
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <el-dialog title="修改模板" v-model="dialogBox" width="40%" custom-class="dialog-box">
      <div class="dialog-box">
        <el-form class="form-box" ref="form" :model="form" label-width="170px" :hide-required-asterisk="true"
          :status-icon="true">
          <el-form-item label="活动标题">
            <el-input v-model="form.act_name" autosize></el-input>
          </el-form-item>
          <el-form-item label="活动类型">
            <el-select v-model="form.act_type_id" placeholder="请选择活动类型">
              <el-option v-for="item in typeList" :key="item.type_id" :label="item.type_name" :value="item.type_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动介绍">
            <el-input type="textarea" v-model="form.act_intro"></el-input>
          </el-form-item>
          <el-form-item label="背景颜色选择">
            <el-color-picker v-model="form.act_theme_color"></el-color-picker>
          </el-form-item>
          <el-form-item label="活动封面">
            <el-upload class="avatar-uploader" action="/resource/v1/uploadFile" :headers="headersObj"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <el-image v-if="form.act_image_url" :src="form.act_image_url"></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="自定义报名表单">
            <div class="customize-menus">
              <div style="display: flex; margin-bottom: 10px" v-for="(item, index) in customizeMenu" :key="index">
                <el-input v-model="item.field_name" style="width: 60%" placeholder="输入自定义名字"></el-input>

                <el-input v-model="item.field_type" style="width: 60%" placeholder="输入自定义类型"></el-input>
                <el-select v-model="item.can_empty" placeholder="是否为空">
                  <el-option label="不为空" value="true"></el-option>
                  <el-option label="为空" value="false"></el-option>
                </el-select>
              </div>
              <div class="but-class">
                <span @click="addClick">+</span>
                <span @click="minusClick">-</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="是否展示这个活动">
            <el-radio v-model="form.act_can_show" :label="1">是</el-radio>
            <el-radio v-model="form.act_can_show" :label="0">否</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogBox = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 活动页面弹出框 -->
    <!-- <el-dialog
      title="活动页面"
      v-model="bodyDialog"
      width="40%"
      custom-class="dialog-box"
    >
      <div class="dialog-box bodyDialog">
        <div
          class="introduce-box"
          v-for="(item, index) in actBodyList"
          :key="index"
        >
          <div v-if="item.body_type === 'image'" class="introduce-item">
            <img :src="item.body_image_url" alt="" />
          </div>
          <div v-else class="introduce-item">
            <el-input
              type="textarea"
              v-model="item.body_text"
              :ref="'input' + index"
              @change="editBody(item.id, 'text', item.body_text)"
              @click="inputClick(index)"
            ></el-input>
          </div>
          <span
            class="del-btn el-icon-close"
            @click="delIntroduceItem(index, item.id)"
          ></span>
          <span
            v-if="index !== 0"
            class="up-btn el-icon-top"
            @click="sortUp(index)"
          ></span>
          <span
            v-if="index !== actBodyList.length - 1"
            class="down-btn el-icon-bottom"
            @click="sortDown(index)"
          ></span>
        </div>
        <div class="but-class">
          <el-button
            type="primary"
            icon="el-icon-edit"
            @click="addTextItem"
            style="height: 30px"
            >添加文本
          </el-button>
          <el-upload
            class="avatar-uploader"
            action="/resource/v1/uploadFile"
            :headers="headersObj"
            :show-file-list="false"
            :on-success="actBodySuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-button type="primary" icon="el-icon-share">添加图片</el-button>
          </el-upload>
        </div>
        <div class="emojis-but-class">
          <EmoJis @appendEmoJis="appendEmoJis" />
        </div>
      </div>
    </el-dialog> -->

    <ActivityTag v-if="createTagState" :actData="actData" @returnTag="returnTag"></ActivityTag>
    <!-- 活动页面弹出框 -->
    <div class="alert-class">
      <el-dialog title="活动文本" v-model="bodyDialog" width="80%" custom-class="dialog-box"
        :before-close="handleCloseBody">
        <div class="dialog-box bodyDialog">
          <div class="dropx" v-if="actBodyState">
            <ImgTextSort ref="childrenDom" :imageList="imageList" :sortType="'exchange'" :item-class="'citem'"
              @change="sortChange" @delIntroduceItem="delIntroduceItem" />
          </div>
          <div style="text-align: right; padding: 10px 0">
            <el-button type="primary" icon="el-icon-share" @click="bindSortImg">确认修改</el-button>
          </div>

          <div class="emojis-but-class">
            <EmoJis @appendEmoJis="appendEmoJis" />
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { fetchData } from "../../api/api";
import NotFound from "../../components/NotFound.vue";
import EmoJis from "../../components/emojis/EmoJis";
import DataUtils from "../../utils/dateUtils";
import ActivityTag from "../../components/activity/ActivityTag";
import axios from "axios";
import ImgTextSort from "../../components/imgSort/ImgTextSort";

export default {
  setup() { },
  data() {
    return {
      imageList: [
        "https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500",
        "https://img1.baidu.com/it/u=1407750889,3441968730&fm=253&fmt=auto&app=120&f=JPEG?w=1200&h=799",
        "https://img0.baidu.com/it/u=1721391133,702358773&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=625",
        "https://img1.baidu.com/it/u=3316754777,2519856621&fm=253&fmt=auto&app=138&f=JPEG?w=653&h=500",
        "https://img1.baidu.com/it/u=3851364429,4209170710&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
        "https://img1.baidu.com/it/u=3851364429,4209170710&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
      ],
      actBodyState: true,
      inactiveValue: 0,
      activeValue: 1,
      is_highlighted: "0",
      value: "1",
      customizeMenu: [{ field_name: "", field_type: "", can_empty: "不为空" }],
      query: {
        page: 1,
        limit: 8,
      },
      color1: "#409EFF",
      pageTotal: 1,
      templateList: [],
      dialogBox: false,
      bodyDialog: false,
      dialogTitle: "添加模板",
      form_obj: {
        act_id: 0,
        act_name: "",
        act_type_id: "",
        act_intro: "",
        act_expired_time: "",
        act_image_id: 0,
        act_image_url: "",
        act_can_show: 1,
        act_pos: 0,
      },
      form: {},
      theTypeId: 3,
      visitUrl: "",
      headersObj: {
        token: JSON.parse(localStorage.getItem("adminInfo")).token,
      },
      actBodyList: [],
      actBodyObj: {
        id: 0,
        act_id: 0,
        body_type: "",
        body_text: "",
        body_image_url: "",
        body_image_id: 0,
        body_pos: 0,
      },
      actTypes: [
        // {
        //     type_id: 0,
        //     type_name: "所有类型",
        // }
      ],
      typeList: [],
      theActId: 0,
      textIndex: "",
      DataUtils: DataUtils,

      createTagState: false,
      actData: {},
      loadingAlert: false,
    };
  },
  created() {
    // this.$set(123,'aaa','123')
    if (localStorage.getItem("adminInfo") !== null) {
      // 服务端拿的 token 可以从 vuex、localStorage 等地方取
      axios.defaults.headers["token"] = JSON.parse(
        localStorage.getItem("adminInfo")
      ).token;
    } else {
      axios.defaults.headers["token"] = ``;
    }
    this.form = this.form_obj;
    this.getTypes();
    this.getTemplateList(this.theTypeId);
  },
  mounted() {
    // this.$set(this.typeList, "type", "type");
  },
  components: {
    NotFound,
    EmoJis,
    ActivityTag,
    ImgTextSort,
  },
  methods: {
    handleCloseBody() {
      this.imageList = [];
      this.bodyDialog = false;
    },
    addTextContent() {
      let item = {
        act_id: this.theActId,
        body_image_id: 0,
        body_image_url: "",
        body_pos: 0,
        body_text: "",
        body_type: "text",
        id: 0,
      };
      //   console.log(this.imageList)
      //   console.log(item)
      // //   this.imageList = [item, ...this.imageList];
      //   this.imageList.unshift(item);
      //   console.log(this.imageList)
      this.$refs.childrenDom.addTextList(item);
      //   console.log(this.$refs.childrenDom.imageList)
      //   console.log(this.imageList);
    },
    bindSortImg() {
      console.log(this.imageList);
      this.$refs.childrenDom.bindSortImg(this.theActId);
      // return false;
      // this.imageList.forEach((val, index) => {
      //   if (val.id !== 0) {
      //     let data = {
      //       body_id: val.id,
      //       body_pos: index + 1,
      //     };
      //     fetchData(data, "/act/v1/editBody", "GET").then(() => {});
      //   } else {
      //     let data = {
      //       act_id: this.theActId,
      //       body_type: val.body_text,
      //       body_text: val.body_text,
      //       body_pos: index + 1,
      //     };
      //     fetchData(data, "/act/v1/createBody", "GET").then((res) => {
      //       console.log(res);
      //     });
      //   }
      // });
    },
    sortChange(newArr) {
      console.log(newArr);
      this.imageList = newArr;
    },
    // delIntroduceItem(data) {
    //   console.log(data);
    // },
    handleSwitch(item) {
      let form_obj = {
        act_id: item.act_id,
        act_name: item.act_name,
        act_type_id: item.act_type_id,
        act_intro: item.act_intro,
        act_expired_time: item.act_expired_time,
        act_image_id: item.act_image_id,
        act_image_url: item.act_image_url,
        act_can_show: item.act_can_show,
        act_pos: item.act_pos,
        is_highlighted: item.is_highlighted,
        fit_month:item.fit_month
      };
      // this.form.is_highlighted = value
      fetchData(form_obj, "/act/v1/editAct", "POST").then(() => {
        console.log(item);
        let index = this.templateList.findIndex(
          (value) => value.act_id == item.act_id
        );
        this.templateList[index].is_highlighted = item.is_highlighted;
      });
    },
    addClick() {
      this.customizeMenu = [
        ...this.customizeMenu,
        { field_name: "", field_type: "", can_empty: "不为空" },
      ];
    },
    minusClick() {
      this.customizeMenu.pop();
    },
    // 标签返回数据
    returnTag(emit) {
      console.log(emit);
      let act_id = emit.act_id;
      this.templateList.forEach((item) => {
        if (item.act_id === act_id) {
          item.tags = [emit.tagData, ...item.tags];
        }
      });
    },
    deleteTag(item, infoItem) {
      fetchData({ act_tag_id: item.tag_id }, "/act/v1/deleteTag", "GET").then(
        () => {
          this.$message("删除成功");
          console.log(infoItem);
          this.templateList.forEach((val) => {
            if (val.act_id === infoItem.act_id) {
              val.tags.forEach((v, k) => {
                if (v.tag_id === item.tag_id) {
                  val.tags.splice(k, 1);
                }
              });
            }
          });
        }
      );
    },
    appendEmoJis(emo) {
      this.actBodyList[this.textIndex].body_text += emo;
      this.editBody(
        this.actBodyList[this.textIndex].id,
        "text",
        this.actBodyList[this.textIndex].body_text
      );
    },
    inputClick(index) {
      this.textIndex = index;
    },
    getTypes() {
      fetchData({ page: 1, limit: 999999 }, "/act/v1/listActTypes", "GET").then(
        (res) => {
          this.typeList = res.data.list;
          res.data.list.forEach((item) => {
            this.actTypes.push({
              type_name: item.type_name,
              type_id: item.type_id,
            });
          });
        }
      );
    },
    getTemplateList(type_id) {
      let data = this.query;
      if (type_id != 0) {
        data.act_type_id = type_id;
      } else {
        data.act_type_id = undefined;
      }
      fetchData(data, "/act/v1/listActs", "GET").then((res) => {
        this.pageTotal = res.data.count;
        let arr = [];
        res.data.list.forEach((item) => {
          if (item.act_type_id != 7) {
            arr.push(item);
          }
        });
        this.templateList = arr;
      });
    },
    //标签列出
    getListTagInfo(info) {
      info.forEach((item) => {
        fetchData({ act_id: item.act_id }, "/act/v1/listTags", "GET").then(
          (res) => {
            item.tags = res.data;
            this.templateList = info;
          }
        );
        this.templateList = info;
      });
    },
    handleClick() {
      this.getTemplateList(this.theTypeId);
    },
    openDialog(type, index, item) {
      this.dialogBox = true;
      this.handleType = type;

      //这里有问题

      switch (type) {
        case "add":
          this.dialogTitle = "添加模板";
          this.visitUrl = "/act/v1/createAct";
          this.form = this.form_obj;
          this.customizeMenu = [
            { field_name: "", field_type: "", can_empty: "不为空" },
          ];
          break;
        case "edit":
          this.form = this.templateList[index];
          this.dialogTitle = "修改模板";
          this.visitUrl = "/act/v1/editAct";

          this.customizeMenu = JSON.parse(item.act_user_attm);
          this.customizeMenu.forEach((item) => {
            if (item.can_empty === true) {
              item.can_empty = "不为空";
            } else if (item.can_empty === false) {
              item.can_empty = "为空";
            }
          });
          break;
      }
    },
    saveEdit() {
      // 用户自定义附加字段，json格式,  { "field_name":"", "field_type":"", "can_empty": false}
      let data = this.form;
      if (this.form.type_image_id === 0) delete data.type_image_id;

      let fieldState = true;
      this.customizeMenu.forEach((item) => {
        if (item.field_name === "" || item.field_type === "") {
          fieldState = false;
        }
      });

      if (fieldState === false) {
        this.$message.error("自定义报名表单必传");
        return false;
      }

      this.form.act_user_attm = JSON.stringify(this.customizeMenu);
      // if (typeof this.form.act_expired_time !== "number") {
      //     this.form.act_expired_time = this.form.act_expired_time.getTime() / 1000;
      // }
      fetchData(this.form, this.visitUrl, "POST").then(() => {
        this.$message.success("操作成功");
        this.dialogBox = false;
        this.getTemplateList(this.theTypeId);
      });
    },
    delTemplate(id) {
      fetchData({ act_id: id }, "/act/v1/deleteAct", "GET").then(() => {
        this.$message.success("删除成功");
        this.getTemplateList(this.theTypeId);
      });
    },
    handleAvatarSuccess(res) {
      this.form.act_image_id = res.data.id;
      this.form.act_image_url = res.data.relativeUrl;
      this.loadingAlert.close();
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // beforeAvatarUpload(file) {
    //   if (file.type.indexOf("video") > -1) {
    //     alert("不可以上传视频封面");
    //     return;
    //   }

    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isLt2M) {
    //     this.$message.error("上传头像图片大小不能超过 2MB!");
    //   }
    //   return isLt2M;
    // },
    beforeAvatarUpload(file) {
      console.log(123);
      if (file.type.indexOf("video") > -1) {
        alert("不可以上传视频封面");
        return;
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      this.loadingAlert = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return isLt2M;
    },
    handlePageChange(val) {
      this.query.page = val;
      this.getTemplateList();
    },

    actBodyDialog(id) {
      this.theActId = id;
      fetchData({ act_id: id }, "/act/v1/listBodies", "GET").then((res) => {
        console.log(res.data);
        this.actBodyList = res.data;
        this.imageList = res.data;

        this.bodyDialog = true;
        setTimeout(() => {
          this.$refs.childrenDom.updateImgList(res.data);
        }, 100);
      });
    },

    delIntroduceItem(data) {
      console.log(data);

      if (data.data.id !== 0) {
        console.log(this.imageList);
        fetchData({ body_id: data.data.id }, "/act/v1/deleteBody", "GET").then(
          () => {
            // this.imageList.splice(data.index, 1);
            this.$refs.childrenDom.deleteTextClick(data.index);
            this.$message.success("删除成功");
          }
        );
      } else {
        // this.imageList.splice(data.index, 1);
        this.$refs.childrenDom.deleteTextClick(data.index);
        this.$message.success("删除成功");
      }
      //    let id = this.imageList.findIndex(
      //     (val,index) => val.body_image_url === data.url
      //   ).id;
      //   fetchData({ body_id: id }, "/act/v1/deleteBody", "GET").then(() => {
      //     this.$message.success("删除成功");
      //   });
      //   this.actBodyList.splice(index, 1);
    },
    sortUp(index) {
      let forVal = [this.actBodyList[index - 1], this.actBodyList[index]];
      forVal.forEach((val, index) => {
        let data = {
          body_id: val.id,
          body_pos: forVal[Math.abs(index - 1)].body_pos,
        };
        fetchData(data, "/act/v1/editBody", "GET").then(() => { });
      });

      let value = this.actBodyList[index];
      this.actBodyList[index] = this.actBodyList[index - 1];
      this.actBodyList[index - 1] = value;
    },
    sortDown(index) {
      let forVal = [this.actBodyList[index + 1], this.actBodyList[index]];
      forVal.forEach((val, index) => {
        let data = {
          body_id: val.id,
          body_pos: forVal[Math.abs(index - 1)].body_pos,
        };
        fetchData(data, "/act/v1/editBody", "GET").then(() => { });
      });

      let value = this.actBodyList[index];
      this.actBodyList[index] = this.actBodyList[index + 1];
      this.actBodyList[index + 1] = value;
    },
    addTextItem() {
      fetchData(
        {
          act_id: this.theActId,
          body_type: "text",
          body_text: "",
          body_pos: this.getBodyPos(),
        },
        "/act/v1/createBody",
        "GET"
      ).then((res) => {
        console.log(this.actBodyList);
        let value = {
          id: res.data,
          body_type: "text",
          body_text: "",
          body_pos: this.getBodyPos(),
        };
        this.actBodyList.push(value);
      });
    },
    addImgItem() {
      fetchData(
        {
          act_id: this.theActId,
          body_type: "image",
          body_image_id: this.actBodyObj.body_image_id,
          body_pos: this.getBodyPos(),
        },
        "/act/v1/createBody",
        "GET"
      ).then((res) => {
        let value = {
          id: res.data,
          body_type: "image",
          body_image_url: this.actBodyObj.body_image_url,
          body_image_id: this.actBodyObj.body_image_id,
          body_pos: this.getBodyPos(),
        };
        this.actBodyList.push(value);
      });
    },
    getBodyPos() {
      if (this.actBodyList.length === 0) return 1;
      return this.actBodyList[this.actBodyList.length - 1].body_pos + 1;
    },
    // actBodySuccess(data) {
    //   this.actBodyObj.body_image_url = data.data.relativeUrl;
    //   this.actBodyObj.body_image_id = data.data.id;
    //   this.addImgItem();
    // },
    actBodySuccess(data) {
      console.log(data);
      this.actBodyObj.body_image_url = data.data.relativeUrl;
      this.actBodyObj.body_image_id = data.data.id;
      let item = {
        act_id: this.theActId,
        body_image_id: data.data.id,
        body_image_url: data.data.relativeUrl,
        body_pos: 0,
        body_text: "",
        body_type: "image",
        id: 0,
      };
      //   this.imageList = [item, ...this.imageList];
      this.$refs.childrenDom.addTextList([item, ...this.imageList]);
      //   console.log(this.imageList);
      //   this.addImgItem(data.data.relativeUrl);
      this.loadingAlert.close();
    },
    editBody(id, type, value = null, pos = 0) {
      console.log(value);
      if (value === null && pos === 0) return;

      let data = {
        body_id: id,
      };
      if (value === null) {
        data.pos = pos;
      }
      if (pos === 0) {
        type === "text"
          ? (data.body_text = value)
          : (data.body_image_id = this.actBodyObj.body_image_id);
      }
      fetchData(data, "/act/v1/editBody", "GET").then(() => { });
    },
    actCreateTag(item) {
      if (item.tags.length >= 2) {
        this.$message.error("活动标签只能添加两个，请先删除");
        return false;
      }
      this.actData = item;
      this.createTagState = true;
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
}

.container {
  .main-class:after {
    content: "";
    width: 120px;
  }

  .main-class {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-content: flex-start;
    width: 100%;

    div[class^="box"] {
      border-radius: 10px;
      background: #f6f6fb;
      padding: 10px 0;
      width: 23%;
      margin: 1%;
      position: relative;
    }

    .box-name-class:after {
      content: "";
      flex: auto;
    }

    .after-add {
      border-radius: 10px;
      padding: 10px 0;
      width: 23%;
      margin: 1%;
      position: relative;
    }

    .box-name-class:hover {
      cursor: pointer;
    }

    .box-name-class {
      div[class^="border"] {
        border-bottom: 1px solid rgba(51, 51, 51, 0.10196078431372549);
      }

      .cover-class {
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
        margin: 10px 10px 0px;
        position: relative;
      }

      .border-name-class,
      .name-class {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 10px;
        background: #ffffff;

        .title-class {
          display: flex;
          align-items: center;

          span {
            align-items: center;
            margin-right: 5px;
            width: 5px;
            height: 5px;
            background: #ff8000;
            opacity: 1;
            border-radius: 2px 0px 2px 0px;
          }
        }

        div:nth-child(2) {
          flex-shrink: 2;
          color: #ff8000;

          img {
            max-width: 150px;
            height: 90px;
            position: relative;
          }
        }
      }
    }
  }
}

.tag-class {
  display: flex;

  color: #ffffff !important;

  span:nth-child(1) {
    margin-right: 10px;
  }

  span {
    background: #c4c4c4;
    padding: 5px 10px;
    border-radius: 10px 0 10px 0;
  }

  i:hover {
    color: red;
  }
}

.the-tabs {
  margin-top: 1.5rem;

  .item {
    margin-bottom: 1.5rem;

    .box-card {
      .img-box {
        overflow: hidden;
        height: 11.5rem;
      }

      .title-box {
        position: relative;

        .title {
          font-size: 20px;
          margin: 1rem 0;
        }

        .btn-box {
          position: absolute;
          opacity: 0;
          right: 0;
          top: 0;
          transition: opacity 0.4s;
        }
      }
    }

    &:hover {
      & .box-card .title-box .btn-box {
        opacity: 1;
      }
    }
  }
}

.dialog-box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}

.dialog-box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(201, 199, 199, 0.2);
  background: #c4c4c4;
}

.dialog-box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(196, 195, 195, 0.2);
  border-radius: 10px;
  background: #ededed;
}

.dialog-box {
  .form-box {
    width: 600px;
  }

  overflow-y: auto;
  max-height: 60vh;
}

.introduce-box {
  position: relative;
  width: 90%;
  margin: 1rem auto;

  .introduce-item {
    padding-top: 5px;
    margin-bottom: 1rem;
  }

  .del-btn,
  .up-btn,
  .down-btn {
    position: absolute;
    cursor: pointer;
    top: -8px;
    right: -8px;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    padding: 3px;
    background-color: #f56c6c;
  }

  .up-btn {
    right: 92%;
    background-color: #409eff;
  }

  .down-btn {
    right: 82%;
    background-color: #409eff;
  }
}

.emojis-but-class {
  margin-left: auto;
  margin-right: 20px;
  position: absolute;
  top: 30%;
  right: -50px;
  width: 30px;
  height: 30px;
}

.bodyDialog {
  .el-button {
    margin-left: 5%;
  }

  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
    overflow: inherit;
  }

  ::v-deep .avatar-uploader {
    display: inline-block;
  }
}

.create-tag-alert {
  width: 300px;
  height: 50px;
  //background: #c4c4c4;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 30px;
  z-index: 50;
  //display: none;
}

.customize-menus {
  position: relative;
  padding-bottom: 10px;

  .but-class {
    position: absolute;
    right: -100px;
    top: 0;

    span {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #c4c4c4;
      text-align: center;
      line-height: 24px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
</style>
