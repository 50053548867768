<template>
    <div>
        <div class="container">
            <el-row :gutter="30">
                <el-col :span="6">
                    <el-card
                        class="cardPickBox"
                        shadow="hover"
                        style="padding: 0"
                    >
                        <div class="videoNum" style="float: left">
                            22
                            <div class="pointer"></div>
                        </div>
                        <div class="groupName">
                            <p class="text" title="哈喽">
                                视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称视频组名称
                            </p>
                            <div class="bindPointer pointer">
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <el-button
                                            size="mini"
                                            style="width: 100%"
                                            >绑定代码</el-button
                                        >
                                    </el-col>
                                    <el-col :span="12">
                                        <el-popover
                                            placement="bottom"
                                            title="标题"
                                            width="60%"
                                            trigger="click"
                                        >
                                            <template #reference>
                                                <el-button
                                                    size="mini"
                                                    style="width: 100%"
                                                    >小视频</el-button
                                                >
                                            </template>
                                        </el-popover>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>

            <el-dialog
                v-model="dialogVisible"
                width="80%"
                :before-close="handleClose"
                top="3vh"
                >
                    <el-row class="dialogBox" :gutter="40">
                        <el-col :span="6" v-for="item in 6" :key='item'>
                            <div class="smallvideoBox">
                                <div>
                                    <video style="width:100%;" src="http://bookperiod.oss-cn-shanghai.aliyuncs.com/sinatv/lessonVideo/%E4%BF%9D%E5%8D%AB%E5%9C%B0%E7%90%83.mp4"
                                            controls></video>
                                </div>
                                <div class="videoInfo">
                                    <div class="videoName">
                                        <span>视频名称视频名称视频名称视频名称视频名称视频名称视频名称视频名称视频名称视频名称视频名称</span>
                                        <i class="el-icon-caret-right"></i>
                                        <div style="clear:both;display:line-block;"></div>
                                    </div>
                                    <div class="videoTag">
                                        <span v-for="item in 10" :key="item">标签一<i class="el-icon-close"></i></span>
                                        <el-popover
                                            placement="top-start"
                                            :width="200"
                                            trigger="click"
                                        >
                                            <template #reference>
                                                <span class="el-icon-plus addTagBtn"></span>
                                            </template>
                                            <el-form ref="form" label-width="80px" size="mini">
                                                <el-form-item label="选择标签">
                                                    <el-select v-model="value" filterable placeholder="请选择标签">
                                                        <el-option
                                                        v-for="item in options"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="添加标签">
                                                    <el-input></el-input>
                                                </el-form-item>
                                                <p style="text-align:right;margin-top:1rem;">
                                                    <el-button size="mini">添加新标签</el-button>
                                                    <el-button size="mini">确认提交</el-button>
                                                </p>
                                            </el-form>
                                        </el-popover>
                                    </div>
                                </div>
                                <div class="bindInfo">
                                    <el-form ref="form" label-width="80px" size="mini">
                                        <el-form-item label="检测代码:">
                                            <el-input :disabled="true"></el-input>
                                        </el-form-item>
                                        <el-form-item label="编号:">
                                            <el-input :disabled="true"></el-input>
                                        </el-form-item>
                                        <el-form-item label="开始位置:">
                                            <el-input></el-input>
                                        </el-form-item>
                                        <el-form-item label="结束位置:">
                                            <el-input></el-input>
                                        </el-form-item>
                                        <el-form-item label="角色名:">
                                            <el-input></el-input>
                                        </el-form-item>
                                        <p style="text-align:right;">
                                            <el-button size="small">编辑</el-button>
                                        </p>
                                    </el-form>
                                </div>
                                <div class="vdieoNum">
                                    <span>1</span>
                                </div>
                                <div class="closeVideo el-icon-close"></div>
                                <div class="refreshVideo el-icon-refresh"></div>
                            </div>
                        </el-col>
                    </el-row>
                    <template #title>
                        <span class="el-icon-plus addTagBtn">添加绑定</span>
                    </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
import DataUtils from "../utils/dateUtils";
// import Lesson from "../components/Lesson.vue";

export default {
    name: "coursetable",
    components: {
        // Lesson,
    },
    data() {
        return {
            query: {
                bureau_id: null,
                start_time: "2020-01-01",
                end_time: DataUtils.getDateStr(1, "YY-MM-DD"),
                page: 1,
                limit: 10,
                action_type: "",
            },
            creditList: [],
            actionTypeList: [
                { name: "消费", val: "withdraw" },
                { name: "充值", val: "deposit" },
            ],
            bureauList: [],
            theDate: "",
            dateScope: "",
            pageTotal: 10,
            DataUtils: DataUtils,
            dialogVisible: true,


                    options: [
                    {
                        value: '选项1',
                        label: '黄金糕',
                    },
                    {
                        value: '选项2',
                        label: '双皮奶',
                    },
                    {
                        value: '选项3',
                        label: '蚵仔煎',
                    },
                    {
                        value: '选项4',
                        label: '龙须面',
                    },
                    {
                        value: '选项5',
                        label: '北京烤鸭',
                    },
                    ],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            this.tableData();
            this.bureauData();
        },
        selectBureau() {
            this.tableData();
        },
        selectType() {
            this.tableData();
        },
        tableData() {
            fetchData(this.query, "/bureau/v1.2/listCreditRec", "GET").then(
                (res) => {
                    this.creditList = res.data.list;
                    this.pageTotal = res.data.count;
                }
            );
        },
        bureauData() {
            fetchData(
                { page: 1, limit: 999999 },
                "/bureau/v1/listBureau",
                "GET"
            ).then((res) => {
                console.log(res);
                this.bureauList = res.list;
            });
        },
        // 分页导航
        handlePageChange(val) {
            // this.$set(this.query, "pageIndex", val);
            this.query.page = val;
            this.getData();
        },
        changeDate() {
            this.query.start_time = DataUtils.formatDate(
                this.dateScope[0].getTime() / 1000,
                "YY-MM-DD"
            );
            this.query.end_time = DataUtils.formatDate(
                this.dateScope[1].getTime() / 1000,
                "YY-MM-DD"
            );
            this.tableData();
        },
        // clickFind() {
        // },
    },
};
</script>

<style lang="scss" scoped>
.dialogBox {
    max-height: 80vh;
    overflow-y: auto;
}
.cardPickBox {
    ::v-deep .el-card__body {
        padding: 0;
        .videoNum {
            position: relative;
            float: left;
            background: red;
            color: #fff;
            padding: 30px;
            font-size: 20px;
            .pointer {
                right: -20px;
                top: 32px;
                width: 0;
                position: absolute;
                height: 0;
                border-color: transparent;
                border-left-color: red;
                border-width: 10px 10px 10px 10px;
                border-style: solid;
            }
        }
        .groupName {
            text-align: center;
            // line-height: 80px;
            line-height: 55px;
            .text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 15px;
            }
            .bindPointer {
                width: 100%;
                line-height: 25px;
            }
        }
    }
}
.smallvideoBox {
    position: relative;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 1rem;
    .videoInfo {
        .videoName {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 90%;
                float: left;
            }
            i {
                float: right;
            }
        }
        .videoTag {
            // border: 1px solid;
            padding: 7px 0;
            span {
                border: 1px solid #3c841c;
                background-color: #3c8;
                border-radius: 4px;
                color: #fff;
                padding: 0 5px;
                font-size: 12px;
                display: inline-block;
                margin-right: 7px;
                margin-bottom: 1px;
                i {
                    color: red;
                    margin-left: 5px;
                }
            }
            .addTagBtn {
                cursor: pointer;
                padding: 2px 11px; 
            }
        }
    }
    .bindInfo {
        border-top: 1px solid #ddd;
        border-radius: 12px;
        padding: 12px;
        ::v-deep .el-form-item--mini.el-form-item{
            margin-bottom: 8px;
        }
    }
    .vdieoNum {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border: transparent;
        border-width: 18px;
        border-top-color: red;
        border-left-color: red;
        border-style: solid;
        span {
            position: absolute;
            top: -18px;
            left: -10px;
            color: #fff;
            font-size: 18px;
        }
    }
    .closeVideo, .refreshVideo {
        cursor: pointer;
        position: absolute;
        right: 0;
        color: #fff;
        background: red;
        border-radius: 50%;
        font-size: 15px;
        padding: 4px;
    }
    .closeVideo {
        top: 0px;
    }
    .refreshVideo {
        top: 30px;
    }
}
::v-deep .el-form-item--mini {
    margin-bottom: 0px;
}
</style>
