<template>
    <div class="main">
        <div class="container">
            <div class="title">裂变端设置</div>
            <hr />
            <el-form
                ref="form"
                :model="form"
                label-width="160px"
                style="max-width: 50vw"
            >
                <el-form-item label="轮播图设置">
                    <div class="banner-item">
                        <el-card class="box-card" style="display: inline-block" v-for="item in banner" :key="item">
                            <div class="img-box">
                                <el-form-item
                                    label="轮播图片"
                                    style="
                                        max-width: 40vw;
                                        display: inline-block;
                                    "
                                >
                                    <el-upload
                                        class="avatar-uploader"
                                        action="https://jsonplaceholder.typicode.com/posts/"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload"
                                    >
                                        <img
                                            v-if="imageUrl"
                                            :src="imageUrl"
                                            class="avatar"
                                        />
                                        <i
                                            v-else
                                            class="
                                                el-icon-plus
                                                avatar-uploader-icon
                                            "
                                        ></i>
                                    </el-upload>
                                </el-form-item>
                                <el-form-item
                                    label="跳转路径"
                                    style="
                                        max-width: 40vw;
                                        display: inline-block;
                                    "
                                >
                                    <el-input placeholder="请输入内容" v-model="input1"></el-input>
                                </el-form-item>
								<el-button class="del-btn" type="danger" icon="el-icon-delete" circle  @click="delBanner" size="small"></el-button>
                            </div>
                        </el-card>
						<el-button type="primary" size="small" @click="addBanner">新增</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <div class="btn-box">
                <el-button type="primary">保存设置</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    setup() {},
    data() {
        return {
            dialogImageUrl: "",
            dialogVisible: false,
			banner: 3
        };
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
		addBanner() {
			this.banner++;
		},
		delBanner() {
			this.banner--;
		}
    },
};
</script>
<style lang="scss" scoped>
.title {
    font-size: 20px;
    color: #666;
}
hr {
    margin: 1rem 0;
}
.banner-item {
	.img-box {
		position: relative;
		::v-deep .el-upload--text {
			height: 100px !important;
		}
		.del-btn {
			position: absolute;
			top: 0;
			right: 0;
			// padding: 5px;
			// background-color: #F56C6C;
			// color: #fff;
			// border-radius: 50%;
			// cursor: pointer;
		}
	}
}

</style>     
