<template>
  <div class="main">
    <div class="container">
      <div class="handle-box">
        <el-button icon="el-icon-plus" type="primary" @click="openDialog('add')"
          >添加活动类型
        </el-button>
      </div>
      <not-found
        v-if="typeList.length === 0"
        text="暂时还没有类型啦"
      ></not-found>
      <el-row gutter="30" class="content">
        <el-col
          :span="6"
          v-for="(item, index) in typeList"
          :key="index"
          class="text item"
        >
          <el-card class="box-card">
            <div class="img-box">
              <img :src="item.type_rule_image_url" alt="" />
            </div>
            <div class="title-box">
              <p class="title">
                {{ item.type_name }}
              </p>
              <div class="btn-box">
                <el-button
                  type="success"
                  plain
                  size="mini"
                  @click="openDialog('edit', index)"
                  >编辑
                </el-button>
                <el-popconfirm
                  title="确定删除该活动类型吗？"
                  @confirm="delType(item.type_id)"
                >
                  <template #reference>
                    <el-button type="danger" plain size="mini">删除</el-button>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.limit"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <el-dialog
      :title="dialogTitle"
      v-model="dialogBox"
      width="40%"
      custom-class="dialog-box"
    >
      <div class="dialog-box">
        <el-form
          class="form-box"
          ref="form"
          :model="form"
          label-width="170px"
          :hide-required-asterisk="true"
          :status-icon="true"
        >
          <el-form-item label="活动类型名">
            <el-input v-model="form.type_name" autosize></el-input>
          </el-form-item>
          <el-form-item label="活动类型编号">
            <el-input v-model="form.type_pos" autosize></el-input>
          </el-form-item>
          <el-form-item label="类型规则图片">
            <el-upload
              class="avatar-uploader"
              action="/resource/v1/uploadFile"
              :headers="headersObj"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <el-image
                v-if="form.type_rule_image_id"
                :src="form.type_rule_image_url"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="类型文本规则">
            <el-input type="textarea" v-model="form.type_rule"></el-input>
          </el-form-item>

          <el-form-item label="类型文字图片">
            <el-upload
              class="avatar-uploader"
              action="/resource/v1/uploadFile"
              :headers="headersObj"
              :show-file-list="false"
              :on-success="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
            >
              <el-image
                v-if="form.type_intro_image_id"
                :src="form.type_intro_image_url"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="类型文字介绍">
            <el-input type="textarea" v-model="form.type_intro"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <!-- <el-input type="textarea" v-model="form.category_id"></el-input> -->
            <el-radio v-model="form.category_id" label="1">活动</el-radio>
            <el-radio v-model="form.category_id" label="2">ppt</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogBox = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import NotFound from "../../components/NotFound.vue";
import { fetchData } from "../../api/api";

export default {
  setup() {
    let types = [
      {
        label: "所有模板方案",
        type_id: 0,
      },
    ];
    let activeName = "all";

    return {
      types,
      activeName,
    };
  },
  components: {
    NotFound,
  },
  data() {
    return {
      query: {
        page: 1,
        limit: 12,
      },
      pageTotal: 1,
      typeList: [],
      dialogBox: false,
      handleType: "add",
      dialogTitle: "添加模板",
      visitUrl: "",
      form: {
        type_id: 0,
        type_name: "",
        type_pos: 0,
        type_rule_image_url: "",
        type_rule_image_id: 0,
        type_rule: "",
        type_intro_image_url: "",
        type_intro_image_id: 0,
        category_id: 0,
      },
      headersObj: {
        token: JSON.parse(localStorage.getItem("adminInfo")).token,
      },
    };
  },
  created() {
    this.getTypeList();
    // let info = {
    //     id : 10,
    //     title: "活动标题",
    //     type: "group_booking",
    //     cover: [{
    //         id: 44,
    //         url: 'https://www.bizhishe.com/d/file/2021-01-28/b6d8189c82ffa2c89bab39f574650b0c.jpg'
    //     }],
    //     deadline: "2021-3-1",
    //     introduce: "活动介绍",
    // 	abstract: "活动摘要",
    // 	custom_form: "自定义表单",
    // 	price: 20,
    // 	group_booking_price: "拼团价(拼团类型时必传)",
    // 	group_booking_num: "可拼团数(拼团类型时必传)",
    // 	group_booking_people_number: "成团人数(拼团类型时必传)",
    // 	is_pass_by_overtime: "是否超时未满自动成团(拼团类型时必传)",
    // 	is_pay: "是否开启支付(报名类型时必传)",
    // 	price_spike: "秒杀价(秒杀类型时必传)",
    // 	seckilling_start_time: "秒杀开始时间(秒杀类型时必传)",
    // 	seckilling_end_time: "秒杀结束时间(秒杀类型时必传)",
    // 	activity_specific: "活动价(分销类型时必传)",
    // 	first_distribution_proportion: "一级分销比例(分销类型时必传)",
    // 	second_distribution_proportion: "二级分销比例(分销类型时必传)"
    // };
    // for (let i = 0; i < 8; i++) {
    // 	this.templateList.push(info);
    // }
  },
  methods: {
    
    getTypeList() {
      fetchData(
        { page: this.query.page, limit: this.query.limit },
        "/act/v1/listActTypes",
        "GET"
      ).then((res) => {
        this.typeList = res.data.list;
        this.pageTotal = res.data.count;
      });
    },
    openDialog(type, index) {
      this.dialogBox = true;
      this.handleType = type;

      switch (type) {
        case "add":
          this.dialogTitle = "添加模板";
          this.visitUrl = "/act/v1/createActType";
          this.form = {
            type_id: 0,
            type_name: "",
            type_pos: 0,
            // type_image_id: 0,
            // type_rule_image_id: "",
            type_rule: "",
            type_intro: "",
            category_id: 0,
          };
          break;
        case "edit":
          // this.form.type_id = this.typeList[index].type_id;
          console.log(this.typeList[index]);
          this.form = this.typeList[index];
          this.dialogTitle = "修改模板";
          this.visitUrl = "/act/v1/editActType";
          break;
      }
    },
    saveEdit() {
      let data = this.form;
      if (this.form.type_image_id === 0) delete data.type_image_id;
      fetchData(this.form, this.visitUrl, "POST").then(() => {
        this.$message.success("操作成功");
        this.dialogBox = false;
        this.getTypeList();
      });
    },
    handlePageChange(val) {
      this.query.page = val;
      this.getTypeList();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    delType(id) {
      fetchData({ type_id: id }, "/act/v1/deleteActType", "GET").then(() => {
        this.$message.success("删除成功");
        this.getTypeList();
      });
    },
    handleAvatarSuccess(res) {
      this.form.type_rule_image_id = res.data.id;
      this.form.type_rule_image_url = res.data.relativeUrl;
      console.log(this.form);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccess2(res) {
      this.form.type_intro_image_id = res.data.id;
      this.form.type_intro_image_url = res.data.relativeUrl;
      console.log(this.form);
    },
    beforeAvatarUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
}

.item {
  margin-bottom: 1.5rem;

  .box-card {
    .img-box {
      overflow: hidden;
      height: 11.5rem;
    }

    .title-box {
      position: relative;

      .title {
        font-size: 20px;
        margin: 1rem 0;
      }

      .btn-box {
        position: absolute;
        opacity: 0;
        right: 0;
        top: 0;
        transition: opacity 0.4s;
      }
    }
  }

  &:hover {
    & .box-card .title-box .btn-box {
      opacity: 1;
    }
  }
}

.dialog-box {
  .form-box {
    width: 600px;
  }

  overflow-y: auto;
  max-height: 60vh;
}

.introduce-box {
  position: relative;

  .introduce-item {
    padding-top: 5px;
    margin-bottom: 1rem;
  }

  .del-btn,
  .up-btn,
  .down-btn {
    position: absolute;
    cursor: pointer;
    top: -8px;
    right: -8px;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    padding: 3px;
    background-color: #f56c6c;
  }

  .up-btn {
    right: 92%;
    background-color: #409eff;
  }

  .down-btn {
    right: 82%;
    background-color: #409eff;
  }
}

.pagination {
  margin-top: 1rem;
}

.content {
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .item {
    margin-bottom: 1rem;
  }
}
</style>
