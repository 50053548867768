<template>
    <div class="main">
        <el-row class="obj-box" :gutter="20">
            <el-col :span="12" v-for="(item, index) in menuList" :key="index">
                <menu-list
					:id="item.id"
					:parentid="item.parentid"
					:sign="item.sign"
					:name="item.name"
                    :icon="item.icon"
					:childList="item.child"
					:checkedSignList="checkedSignList"
					:checked="item.isChecked"
					:parentChecked="true"
					:indexStr="index+''"
                    :isShowSub="item.isShowSub"
					@checkMenu="checkMenu"
					@clickMenu="childClickMenu"
				></menu-list>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { fetchData } from "../../api/api";
import MenuList from "./MenuList.vue";

export default {
    name: "RoleiMenu",
    props: ["roleId"],
    components: {
        MenuList,
    },
    checked: {
        isChecked() {
            return (id) => {
                return this.checkedMenu.indexOf(id) !== -1 ? "checked" : "";
            };
        },
    },
    data() {
        return {
            radioType: "all",
            type: [
                { label: "全部", value: "all" },
                { label: "未拥有", value: "neverHad" },
                { label: "已拥有", value: "had" },
            ],
            checkedMenu: [],
            menuList: [
                {
                    id: 1,
                    name: "系统首页",
                    parentid: 0,
                    isChecked: false,
                    icon: "el-icon-s-home",
                    path: "/",
                    sign: "A",
                    child: [
                        {
                            id: 1,
                            name: "系统首页",
                            parentid: 0,
                            isChecked: true,
                            icon: "el-icon-s-home",
                            path: "/",
                            sign: "",
                            child: [
                                {
                                    id: 1,
                                    name: "系统首页",
                                    parentid: 0,
                                    isChecked: true,
                                    icon: "el-icon-s-home",
                                    path: "/",
                                    sign: "",
                                    child: [],
                                },
                            ],
                        },
                        {
                            id: 1,
                            name: "系统首页",
                            parentid: 0,
                            isChecked: true,
                            icon: "el-icon-s-home",
                            path: "/",
                            sign: "A",
                            child: [
                                {
                                    id: 1,
                                    name: "系统首页",
                                    parentid: 0,
                                    isChecked: true,
                                    icon: "el-icon-s-home",
                                    path: "/",
                                    sign: "",
                                    child: [
                                        {
                                            id: 1,
                                            name: "系统首页",
                                            parentid: 0,
                                            isChecked: true,
                                            icon: "el-icon-s-home",
                                            path: "/",
                                            sign: "",
                                            child: [
                                                {
                                                    id: 1,
                                                    name: "系统首页",
                                                    parentid: 0,
                                                    isChecked: true,
                                                    icon: "el-icon-s-home",
                                                    path: "/",
                                                    sign: "",
                                                    child: [
                                                        {
                                                            id: 1,
                                                            name: "系统首页",
                                                            parentid: 0,
                                                            isChecked: true,
                                                            icon: "el-icon-s-home",
                                                            path: "/",
                                                            sign: "",
                                                            child: [
                                                                {
                                                                    id: 1,
                                                                    name: "系统首页",
                                                                    parentid: 0,
                                                                    isChecked: true,
                                                                    icon: "el-icon-s-home",
                                                                    path: "/",
                                                                    sign: "",
                                                                    child: [],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 1,
                    name: "系统首页",
                    parentid: 0,
                    isChecked: true,
                    icon: "el-icon-s-home",
                    path: "/",
                    sign: "",
                    child: [],
                },
                {
                    id: 1,
                    name: "系统首页",
                    parentid: 0,
                    isChecked: true,
                    icon: "el-icon-s-home",
                    path: "/",
                    sign: "",
                    child: [],
                },
            ],
			checkedSignList: [],
            childSubList: []
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.getTree(0, ()=> {
                this.menuList = this.childSubList;
                this.childSubList = [];
            });
        },
        getTree(parentid, fun) {
            fetchData({ parent_id: parentid, role_id: this.roleId }, "/admin/v1/listRoleItems", "GET").then(
                (res) => {
                    let arr = [];
                    res.data.forEach((item) => {
                        item.hasChildren = true;
                        item.isShowSub = false;
                        item.parent_id = parentid;
                        item.name = item.menu_name;
                        item.isChecked = item.is_hidden === 0;
                        arr.push(item);
                    });
                    this.childSubList = arr;
                    fun();
                }
            );
        },
		checkMenu(indexStr) {
			let arr = indexStr.split("-"),
				obj = {}
			for (let i = 0; i < arr.length; i++) {
				obj = i === 0 ? this.menuList[arr[i]] : obj.child[arr[i]];
				if (i === arr.length-1) {
                    let url = obj.isChecked ? "/admin/v1/addRoleItem" : "/admin/v1/deleteRoleItem",
                        text = obj.isChecked ? "解绑" : "绑定到角色";

                    if(confirm('确定要'+ text +'吗?')) {
                        fetchData({ item_id: obj.menu_id, role_id: this.roleId }, url, "GET").then(
                            () => {
                                this.$message.success(text + "成功");
                                obj.isChecked = !obj.isChecked;
                            }
                        );
                    }
				}
			}
		},
        childClickMenu(indexStr) {
            let arr = indexStr.split("-"),
				obj = {}
			for (let i = 0; i < arr.length; i++) {
				obj = i === 0 ? this.menuList[arr[i]] : obj.child[arr[i]];
				if (i === arr.length-1) {
					obj.isShowSub = !obj.isShowSub;
                    this.getTree(obj.menu_id, () => {
                        this.setChild(indexStr);
                    })
				}
			}
        },
        setChild(indexStr) {
            let arr = indexStr.split("-"),
				obj = {}
			for (let i = 0; i < arr.length; i++) {
				obj = i === 0 ? this.menuList[arr[i]] : obj.child[arr[i]];
				if (i === arr.length-1) {
                    obj.child = this.childSubList;
                    this.childSubList = [];
				}
			}
        }
    },
};

</script>
<style scoped lang="scss">
.main {
    background-color: #fff;
    .head {
        padding: 10px 20px;
        background-color: #f1f1f1;
        border-radius: 5px;
        .el-button {
            float: right;
        }
        .myOperate {
            background: #fff;
            border-radius: 5px;
            margin-top: 9px;
            padding: 0px 5px;
            p {
                margin-top: 5px;
            }
        }
    }
    .obj-box {
        max-height: 350px;
        overflow: auto;
        .checked {
            .up {
                transform: rotate(0deg);
            }
        }
    }
}
*::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}
/*正常情况下滑块的样式*/
*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在该类指向的控件上时滑块的样式*/
*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在滑块上时滑块的样式*/
*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*正常时候的主干部分*/
*::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0);
    background-color: white;
}

/*鼠标悬浮在滚动条上的主干部分*/
*::-webkit-scrollbar-track:hover {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.01);
}
</style>