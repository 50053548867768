<template>
    <div id="emojis-id">
        <div class="emojis-box-class" @click="state = state === false">
            <span>😀</span>
            <span>表情</span>
        </div>
        <div class="emojis-main-class" v-if="state">
            <ul>
                <li
                    v-for="(item, index) in faceList"
                    :key="index"
                    @click="getBrow(index)"
                >
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
const appData = require("../../assets/json/emojis.json");
export default {
    name: "EmoJis",
    data() {
        return {
            //表情列表
            faceList: [],
            getBrowString: "",
            state: false
        }
    },
    created() {
        this.loadEmojis();
    },
    methods: {
        //加载表情，存放到表情列表中
        loadEmojis() {
            for (let i in appData) {
                this.faceList.push(appData[i].char);
            }
        },
        // 获取用户点击之后的标签 ，存放到输入框内
        getBrow(index) {
            console.log(index)
            for (let i in this.faceList) {
                if (index === i*1) {
                    console.log(i)
                    this.getBrowString = this.faceList[index];
                    // this.content += this.getBrowString;
                    console.log(this.getBrowString);
                    this.$emit('appendEmoJis', this.getBrowString);
                }
            }
            // this.emojiShow = false;
        },
    }
}
</script>

<style lang="scss" scoped>
li {
    list-style: none;
}

.emojis-id {
    width: 80%;
    user-select: none;
    display: flex;
}

.emojis-box-class {
    border: 1px solid #c4c4c4;
    width: 36px;
    //height: 36px;
    border-radius: 3px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    font-size: 20px;
    margin-left: 0px;
    background: #FFFFFF;
    span:nth-child(2){
        font-size: 10px;
    }
}

.emojis-box-class:hover {
    border: 1px solid #3cb2f1;
}

.emojis-main-class {
    margin-left: 30px;
    background: #FFFFFF;
    width: 300px;
    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 36px;
            height: 36px;
            box-sizing: border-box;
            cursor: pointer;
            text-align: center;
            line-height: 36px;
        }

        li:hover {
            border: 1px solid #3cb2f1;
            box-sizing: border-box;
            -moz-box-sizing: border-box; /* Firefox */
            -webkit-box-sizing: border-box; /* Safari */
            border-radius: 2px;
        }
    }
}

</style>
