<template>
    <div>
        {{ msg }}
        <tinymce-editor ref="editor"
                        v-model="msg"
                        :disabled="disabled"
                        :value="content_text"
                        @onClick="onClick" @input="changeInput">
        </tinymce-editor>
        <button @click="clear">清空内容</button>
        <button @click="disabled = true">禁用</button>
    </div>
</template>

<script>
import TinymceEditor from './tinymce-editor'
export default {
    props:["content_text"],
    components: {
        TinymceEditor
    },
    data () {
        return {
            msg: '',
            disabled: false
        }
    },
    methods: {
        // 鼠标单击的事件
        onClick (e, editor) {
            console.log('Element clicked')
            console.log(e)
            console.log(editor)
        },
        // 清空内容
        clear () {
            console.log(this.content_text)
            this.$refs.editor.clear()
        },
        changeInput(val){
            console.log(val)
            this.$emit('input',val)
        }
    }
}
</script>
