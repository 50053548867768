<template>
    <div>
        <div class="container">
            <div class="handle-box">
                <el-button icon="el-icon-plus" type="primary" @click="groupAdd"
                    >添加知识点分组</el-button
                >
                <el-input
                    v-model="query.name"
                    placeholder="分组名"
                    class="handle-input mr10 right"
                    @change="handleSearch"
                ></el-input>
            </div>
            <not-found v-if="groupData.length === 0" text="暂时没有分组啦"></not-found>

            <el-row :gutter="20">
                <el-col :span="6" v-for="(item, index) in groupData" :key="index">
                    <el-card shadow="always" style="margin-bottom: 40px;">
                        <div class="bureau-box">
                            <el-row :gutter="10" class="topPickBox" :class="unfoldBureau.indexOf(item.group_id) !== -1 ? 'checked' : ''"
                                @click="checkGroup(item.group_id)"
                            >
                                <el-col :span="24" class="content">
                                    <p class="bureau-name">{{ item.group_name }}</p>
                                </el-col>
                            </el-row>
                            <div class="conPickBox">
                                <!-- 标签 -->
                                <div class="pickBox">
                                    <p class="theTag">
                                        <span>知识点</span>
                                    </p>
                                    <div class="content">
                                        <admin-label category="bureau" :categoryId="item.group_id" style="font-size:12px;"></admin-label>
                                    </div>
                                </div>
                                <!-- 操作 -->
                                <div class="pickBox">
                                    <p class="theTag">
                                        <span>操作组</span>
                                    </p>
                                    <div class="content">
                                        <el-button-group>
                                            <el-button
                                                size="mini"
                                                type="primary"
                                                icon="el-icon-edit"
                                                @click="handleEdit(item)"
                                                plain
                                                >编辑</el-button
                                            >
                                            <el-button
                                                size="mini"
                                                type="danger"
                                                icon="el-icon-delete"
                                                @click="handleDelete(item.group_id)"
                                                plain
                                                >删除</el-button
                                            >
                                        </el-button-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="修改知识点分组" v-model="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="分组名">
                    <el-input v-model="form.group_name"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
        <!-- 添加弹出框 -->
        <el-dialog title="添加知识点分组" v-model="addVisible" width="30%">
            <el-form ref="form" :model="addData" label-width="70px">
                <el-form-item label="分组名">
                    <el-input v-model="addData.group_name"></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
import AdminLabel from "../components/resource/point/AdminLabel.vue";
import NotFound from "../components/NotFound.vue";
export default {
    name: "basetable",
    computed: {
        groupsLessonPlan() {
            return (num, all) => {
                return Math.floor((num/all)*100);
            }
        }
    },
    data() {
        return {
            query: {
                group_name: '',
            },
            selectTags: [],
            // reference: false,
            addData: {
                group_name: ''
            },
            form: {
                group_id: 0,
                group_name: ''
            },
            editVisible: false,
            addVisible: false,


            groupData: [],
            multipleSelection: [],
            delList: [],

            selectHelper: false,
            selectSubordinate: false,
            pageTotal: 0,

            idx: -1,
            id: -1,
            imageUrl: "",
            imageId: 0,
            theHelpers: [],
            theHelper: "",
            theSubBureau: "",
            theSubordinates: [],
            theSubordinate: "",
            theBureau: 0,
            theGoodsType: "by_lesson_fee",
            helperList: [],
            bureauList: [],
            helpersInbureau: [],
            subsInBureauList: [],
            helperTable: false,
            selectedHelper: [],
            headersObj: {
                token: JSON.parse(localStorage.getItem("adminInfo")).token,
            },
            buyGoodsData: {},
            buyGoodsBox: false,
            goodsData: [],
            affirmOrder: false,
            renewGoodsVal: false,
            renewGoodsData: {},
            orderId: 0,
            orderData: {},
            bureauUserData: {},
            renewGoodid: "",
            goodsInbureau: [],
            groupsInbureau: [],
            goodsPage: {
                bureau_id: "",
                page: 1,
                limit: 5,
                count: 0,
            },
            topUpMoney: 0,
            goodsTypeList: [
                {
                    name: "计课时费套餐",
                    value: "by_lesson_fee",
                },
                // {
                //     name: "计时套餐",
                //     value: "by_hours",
                // },
                // {
                //     name: "包年套餐",
                //     value: "by_member_count",
                // },
            ],
            bureauDetails: false,
            detailsData: {},
            bureauTags:[],
            unfoldBureau:[]
        };
                    // bureauDetails: false,
            // detailsData: {}
    },
    created() {
        this.getData();
    },
    components: {
        AdminLabel,
        NotFound
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            this.query.tag_list = this.selectTags.toString();
            // this.query.tag_list = '';
            fetchData(this.query, "/edu/v1.2/listGroups", "GET").then(
                (res) => {
                    this.groupData = res.data;
                }
            );
        },
        // 机构详情
        handleDetails(row) {
            console.log(row);
            this.bureauDetails = true;
            this.detailsData = row;
        },
        // 触发搜索按钮
        handleSearch() {
            this.getData();
        },
        // 删除操作
        handleDelete(id) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            })
                .then(() => {
                    fetchData(
                        { group_id: id },
                        "/edu/v1.2/deleteGroup",
                        "GET"
                    ).then(() => {
                        this.$message.success("删除成功");
                        this.getData();
                    });
                })
                .catch(() => {});
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 添加操作
        groupAdd() {
            this.addVisible = true;
        },
        // 编辑操作
        handleEdit(obj) {
            this.form = obj;
            this.editVisible = true;
        },
        // 保存编辑
        saveEdit() {
            fetchData(this.form, "/edu/v1.2/editGroup", "POST").then(() => {
                this.$message.success(`修改成功`);
                this.editVisible = false;
            });
        },
        // 保存添加
        saveAdd() {
            fetchData(this.addData, "/edu/v1.2/createGroup", "POST").then(
                () => {
                    this.addVisible = false;
                    this.addData = {};
                    this.getData();
                    this.$message.success("添加成功");
                }
            );
        },
        saveBinding() {
            fetchData(
                { helper_id: this.theHelper, bureau_id: this.theBureau },
                "/admin/v1/helperBindingToBureau",
                "POST"
            ).then(() => {
                this.$message.success(`绑定成功`);
                this.selectHelper = false;
                this.getData();
            });
        },
        //绑定下级操作
        saveBindingtheSub() {
            fetchData(
                {
                    sub_bureau_id: this.theSubordinate,
                    bureau_id: this.theBureau,
                },
                "/admin/v1/bindSubBureau",
                "POST"
            ).then(() => {
                this.$message.success(`绑定成功`);
                this.selectSubordinate = false;
                this.getData();
            });
        },
        // 分页导航
        handlePageChange(val) {
            // this.$set(this.query, "page", val);
            this.query.page = val;
            this.getData();
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.imageId = res.data.id;
        },
        editAvatarSuccess(res, file) {
            this.form.avatar_url = URL.createObjectURL(file.raw);
            this.form.avatar_id = res.data.id;
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error("上传头像图片只能是 JPG 格式!");
            // }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            // return isJPG && isLt2M;
            return isLt2M;
        },
        beforeBureau() {
            if (this.theBureau == 0) {
                this.$message({
                    type: "warning",
                    message: "请选择机构",
                });
                return false;
            }
            return true;
        },
        bindingHelper(id) {
            this.theBureau = id === 0 ? 0 : id;
            if (this.beforeBureau() === true) {
                this.selectHelper = true;
            }
        },
        // 绑定下级
        bindingSubordinate(id) {
            this.theBureau = id === 0 ? 0 : id;
            if (this.beforeBureau() === true) {
                this.selectSubordinate = true;
            }
        },
        subBureauChange(val) {
            if (this.theBureau === val) {
                this.$message({
                    type: "warning",
                    message: "不能绑定自己",
                });
                this.theSubordinate = "";
                return false;
            }
        },
        handleCurrentChange(row) {
            try {
                this.theBureau = row.id;
            } catch (error) {
                console.log(error);
            }
        },
        handleBureauChange(row) {
            try {
                this.renewGoodid = row.id;
            } catch (error) {
                console.log(error);
            }
        },
        showGroupsInbureau(id) {
            fetchData(
                { bureau_id: id },
                "/admin/v1/listBureauInfo",
                "GET"
            ).then((res) => {
                this.groupsInbureau = res.data;
            });
        },
        showHelpersInbureau(id) {
            fetchData(
                { limit: 999999, bureau_id: id },
                "/bureau/v1/getHelperByBureauId",
                "GET"
            ).then((res) => {
                this.helpersInbureau = res.list;
            });
        },
        showSubBureau(id) {
            fetchData({ bureau_id: id }, "/bureau/v1.2/listSubs", "GET").then(
                (res) => {
                    this.subsInBureauList = res.data.list;
                }
            );
        },
        showGoodsInbureau(id = "") {
            if (id !== "") this.theBureau = id;
            fetchData(
                {
                    page: this.goodsPage.page,
                    limit: this.goodsPage.limit,
                    bureau_id: this.theBureau,
                },
                "/admin/v1/listBureauGoods",
                "GET"
            ).then((res) => {
                this.goodsPage.count = res.data.count;
                this.goodsInbureau = res.data.list;
            });
        },
        buyGoods(id, goodsType) {
            this.theBureau = id;
            this.theGoodsType = goodsType;
            fetchData(
                { limit: 999999, page: 1, goods_category: goodsType },
                "/edu/v1/listGoods",
                "GET"
            ).then((res) => {
                this.goodsData = res.data.list;
            });
            this.buyGoodsBox = true;
        },
        handlePageChangeByGoods(val) {
            this.goodsPage.page = val;
            this.showGoodsInbureau();
        },
        // 购买套餐
        buyGoodsSave() {
            this.buyGoodsData.bureau_id = this.theBureau;
            fetchData(
                this.buyGoodsData,
                "/admin/v1/bureauBuyGoods",
                "GET"
            ).then((res) => {
                this.buyGoodsBox = this.bureauDetails = false;
                this.affirmOrder = true;
                this.orderId = res.data.order_id;
                this.orderData.goodsname = this.buyGoodsData.name;
                this.orderData.member_count = this.buyGoodsData.member_count;
                this.orderData.price = this.buyGoodsData.price;
                this.orderData.days = this.buyGoodsData.days;
                this.orderData.hours = this.buyGoodsData.hours;
                this.orderData.lesson_fee = this.buyGoodsData.lesson_fee;
                this.getData();
            });
        },
        // 续费套餐
        renewGoods(id) {
            this.renewGoodsVal = true;
            this.renewGoodsData.resource_id = id;
        },
        renewGoodsSave() {
            this.renewGoodsData.bureau_id = this.theBureau;
            fetchData(
                this.renewGoodsData,
                "/admin/v1/renewBureauGoods",
                "GET"
            ).then(() => {
                this.renewGoodsVal = false;
                this.renewGoodsData = {};
                this.$message.success(`续费成功`);
                this.showGoodsInbureau();
            });
        },
        // 确认订单
        affirmOrderSave() {
            fetchData(
                { order_id: this.orderId },
                "/admin/v1/applyOrder",
                "GET"
            ).then(() => {
                this.affirmOrder = false;
                this.$message.success(`确认成功`);
            });
        },
        changeGoods(val) {
            this.goodsData.forEach((element) => {
                element.goods_id = element.id;
                element.lesson_fee = 100;
                if (element.id == val) this.buyGoodsData = element;
            });
        },
        changeGoodsType() {
            // this.theGoodsType = val;
            this.buyGoodsData = {};
            this.buyGoods(this.theBureau, this.theGoodsType);
        },
        // 机构充值
        topUp(id, type='balance') {
            let text = {balance:'充值', balance_security_deposit:'缴纳保证金'};
            let money = prompt("请输入"+ text[type] +"金额");
            if (!money) {
                this.$message.error("请输入金额");
                return false;
            }
            fetchData(
                { bureau_id: id, money: money, category: type },
                "/admin/v1/addCredit",
                "GET"
            ).then((res) => {
                if (confirm("确实"+ text[type] + money + "元吗？")) {
                    fetchData(
                        { order_id: res.data },
                        "/admin/v1/applyCreditOrder",
                        "GET"
                    ).then(() => {
                        this.$message.success( text[type] +`成功`);
                        this.bureauDetails = false;
                        this.getData();
                    });
                }
            });
        },
        goTo(url, params) {
            this.$router.push({ path: "/", name: url, params: params });
        },
        // 点击机构时
        checkGroup(id) {
            let index = this.unfoldBureau.indexOf(id);
            if (-1 === index) {
                this.unfoldBureau.push(id);
            } else {
                this.unfoldBureau.splice(index, 1);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.bureau-box{
    $time: 0.5s;
    .topPickBox {
        box-shadow: 0px 0px 0px 0px #ccc;
        margin-bottom: 0px;
        cursor: pointer;
        border-radius: 6px;
        transition: box-shadow $time, margin-bottom $time;
        .bureau-avatar{
            width: 65%;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;
            img{
                width: 100%;
                border-radius: 50%;
                vertical-align:middle;
                aspect-ratio: 1/1;
                display:inline-block;
            }
            &::before {
                content:'';
                width:0;
                height:100%;
                display:inline-block;
                position:relative;
                vertical-align:middle;
            }
        }
    }
    .conPickBox{
        max-height: 0px;
        opacity: 0;
        transition: max-height $time, opacity $time;
        .pickBox {
            .theTag {
                $color: #ecf5ff;
                border-bottom: 1px solid #b3d8ff;
                span {
                    font-size: 12px;
                    display: inline-block;
                    background-color: $color;
                    color: #409EFF;
                    position: relative;
                    height: 18px;
                    left: 18px;
                    &::before {
                        content : '';
                        display: inline-block;
                        position: absolute;
                        left: -18px;
                        border: 9px solid transparent;
                        border-right-color: $color;
                        border-bottom-color: $color;
                    }
                    &::after {
                        content : '';
                        display: inline-block;
                        position: absolute;
                        border: 9px solid transparent;
                        border-left-color: $color;
                        border-bottom-color: $color;
                    }
                }
            }
            .content {
                text-align: right;
            }
        }
    }
    .checked {
        box-shadow: 0px 4px 5px 0px #ccc;
        margin-bottom: 20px;
        &+.conPickBox {
            max-height: 230px;
            opacity: 1;
        }
    }
    .content {
        overflow: hidden;
        p {
            i {
                margin-right: 8px;
            }
            padding-left: 18px;
            margin-bottom: 3%;
            overflow: hidden;
            text-overflow:ellipsis; //溢出用省略号显示
            white-space:nowrap; //溢出不换行
        }
        .bureau-name {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 3%;
        }
    }

}


.bureauDetails {
    .title {
        margin-bottom: 1rem;
        background: #ccc;
        color: #fff;
        padding: 10px;
    }
    .label {
        line-height: 3rem;
    }
    // .text {

    // }
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.left {
    float: left;
}
.right {
    float: right;
}
.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
::v-deep .el-upload--text {
    width: 178px;
}
</style>
