<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-button icon="el-icon-plus" type="primary" @click="addRoleOpen"
					>添加角色</el-button
				>
			</div>
			<not-found v-if="roleData.length === 0" text="暂时还没有角色啦"></not-found>
			<el-row :gutter="20">
				<el-col :span="6" v-for="(item, index) in roleData" :key="index">
					<el-card class="theRole">
						<div class="name">
							<div class="roleBox">{{ item.name }}</div>
							<div class="btnBox">
								<el-button size="mini" type="primary" @click="editRoleOpen(item)">修改</el-button>
								<el-popconfirm title="这是一段内容确定删除吗？" @confirm="delRole(item.id)">
									<template #reference>
										<el-button size="mini" type="danger">删除</el-button>
									</template>
								</el-popconfirm>
							</div>
						</div>
						<div class="text">
							{{ item.intro }}
						</div>

						<el-popover placement="bottom" :offset="30" :width="1200" trigger="click">
							<template #reference>
								<div class="role-path">查看权限</div>
							</template>
							<role-permission :roleId="item.id"></role-permission>
						</el-popover>
						<el-popover placement="bottom" :offset="30" :width="800	" trigger="click">
							<template #reference>
								<div class="role-path">查看路径</div>
							</template>
							<role-menu :roleId="item.id"></role-menu>
						</el-popover>
					</el-card>
				</el-col>
			</el-row>
			<div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
		</div>
		<!-- 弹出框 -->
        <el-dialog title="定义您的数据" v-model="dialog" width="30%">
            <el-form ref="form" :model="theRoleData" label-width="70px">
                <el-form-item label="角色名">
                    <el-input v-model="theRoleData.name"></el-input>
                </el-form-item>
                <el-form-item label="介绍">
                    <el-input
                        v-model="theRoleData.intro"
                        type="textarea"
                        placeholder="请输入角色简介"
                        rows="3"
                    ></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="operateData"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
	</div>
</template>
<script>

import NotFound from "../../components/NotFound.vue";
import { fetchData } from "../../api/api";
import RolePermission from "../../components/permission/Rolepermission.vue";
import RoleMenu from "../../components/menu/RoleMenu.vue";

export default {
	name: "rolelist",
	setup() {
		
	},
	created() {
        this.getData();
    },
	computed: {
		operateData() {
			return ()=>{
				this.operate == "add" ? this.roleAdd() : this.roleEdit();
			}
		}
	},
	components: {
		NotFound, RolePermission, RoleMenu
	},
	data() {
		return {
			query: {
				page: 1,
				limit: 10,
			},
			pageTotal: 0,
			roleData:[],
			dialog: false,
			operate: "add", //add  edit
			theRoleData: {
				id: 0,
				name: '',
				intro: '',
			}
		}
	},
	methods: {
		getData() {
			fetchData(
				{ page: this.query.page, limit: this.query.limit},
				"/admin/v1/listRoles",
				"GET"
			).then((res) => {
				this.pageTotal = res.data.count;
				this.roleData = res.data.list;
				console.log(res);
			});
		},
		theRoleDataInit () {
			this.theRoleData = {
				id: 0,
				name: '',
				intro: '',
			};
		},
		addRoleOpen() {
			this.theRoleDataInit();
			this.dialog = true;
			this.operate = "add";
		},
		roleAdd() {
			fetchData(
				{ name: this.theRoleData.name, intro: this.theRoleData.intro},
				"/admin/v1/addRole",
				"POST"
			).then(() => {
				this.dialog = false;
				this.theRoleDataInit();
				this.$message.success("添加成功");
				this.getData();
			});
		},
		editRoleOpen(item) {
			this.theRoleData.id = item.id;
			this.theRoleData.name = item.name;
			this.theRoleData.intro = item.intro;
			this.dialog = true;
			this.operate = "edit";
		},
		roleEdit() {
			fetchData(
				this.theRoleData,
				"/admin/v1/editRole",
				"POST"
			).then(() => {
				this.dialog = false;
				this.theRoleDataInit();
				this.$message.success("修改成功");
				this.getData();
			});
		},
		delRole(id) {
			fetchData(
				{id: id},
				"/admin/v1/deleteRole",
				"POST"
			).then(() => {
				this.$message.success("删除成功");
				this.getData();
			});
		},
		handlePageChange(val) {
			this.query.page = val;
			this.getData();
		}
	}
}
</script>
<style scoped lang="scss">
.theRole {
	margin-bottom: 1.5rem;
	.name{
		line-height: 2rem;
		font-weight: bold;
		div {
			display: inline-block;
			&.roleBox {
				max-width: 50%;
			}
			&.btnBox {
				text-align: right;
				float: right;
				opacity: 0;
				transition: opacity 0.4s;
			}
		}
	}
	.text{
		border-radius: 7px;
		background-color: #f3f3f3;    
		min-height: 60px;
		padding: 8px 17px;
		font-size: 14px;
	}
	.role-path {
		padding: 1px 5px;
		display: inline-block;
		font-size: 12px;
		color: #fff;
		background: #E6A23C;
		cursor: pointer;
		margin-top: 5px;
		border-radius: 5px;
		translate: padding 0.4s;
		margin-right: 10px;
	}
	&:hover {
		& .name .btnBox {
			opacity: 1;
		}
	}
}
</style>
