<template>
    <div>
        <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="模板类型">
                <el-select v-model="form.type" placeholder="请选择模板类型">
                    <el-option value="video" label="视频"></el-option>
                    <el-option value="image" label="图片"></el-option>
                    <el-option value="text" label="文本"></el-option>
                    <el-option value="question" label="题目"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="模板js加载路径">
                <el-input v-model="form.javascript_url"></el-input>
            </el-form-item>
            <el-form-item label="模板内容">
                <el-input type="textarea" v-model="form.content"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button v-if="butState === false" type="primary" @click="onSubmit">立即创建</el-button>
               <el-button v-if="butState === 'edit'" type="primary" @click="onEdit">编辑</el-button>
<!--                <el-button v-if="butState === true" type="primary" @click="templateDetailBut">完善模板详情</el-button>-->
                <el-button @click="closeBut">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {fetchData} from "../../api/api";

export default {
    props: {rowData: {type: Object}},
    data() {
        return {
            butState: false,
            form: {
                type: '',
                javascript_url: '',
                content: '',
            },
            id: 0
        }
    },
    created() {
        if (this.rowData !== null) {
            this.butState = 'edit';
            let rowData = this.rowData
            let info = {type: rowData.type, javascript_url: rowData.javascript_url, content: rowData.content}
            this.id = rowData.id;
            this.form = info;
        } else {
            this.form = {
                type: '',
                javascript_url: '',
                content: '',
            };
        }
    },
    watch: {},
    methods: {
        onSubmit() {
            fetchData(this.form, "/admin/v1/addLessonTem", "POST").then(
                (res) => {
                    if (res.code === 200) {
                        this.butState = true;
                        let content = this.form.content;
                        this.$emit('state', false);
                        this.$emit('getData', {val: 'templateDetailState', content: content,template_id:res.data});
                    }
                }
            );
            console.log('submit!');
        },
        onEdit() {
            this.form.id = this.id;
            fetchData(this.form, "/admin/v1/editLessonTem", "POST").then(
                (res) => {
                    if (res.code === 200) {
                        console.log(res)
                        this.$message.success("修改成功");
                        this.$emit('state', false);
                        this.$emit('getData', 'getData');
                    }
                }
            );
        },
        closeBut() {
            console.log(this.$parent)//.saveResourceState = false;
            this.$emit('state', false);
        },
        templateDetailBut() {
            this.$emit('templateDetail', '787877');
        }
    }
}
</script>

<style scoped>

</style>
