<template>
    <div>
        <div class="container">
            <div class="handle-box">
                <h2 style="color: #666666">提现</h2>
            </div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="提现到余额" name="first">
                    <el-table
                        :data="fundsList"
                        border
                        class="table"
                        ref="multipleTable"
                        header-cell-class-name="table-header"
                    >
                        <!-- <el-table-column
                            type="selection"
                            width="55"
                            align="center"
                        ></el-table-column> -->
                        <el-table-column
                            prop="bureau_name"
                            label="机构名"
                        ></el-table-column>
                        <el-table-column
                            prop="act_name"
                            label="机构名"
                        ></el-table-column>
                        <el-table-column
                            prop="fund"
                            label="资金(元)"
                        ></el-table-column>
                        <el-table-column
                            prop="service_fund"
                            label="服务费(元)"
                        ></el-table-column>
                        <el-table-column
                            prop="final_fund"
                            label="最终资金(元)"
                        ></el-table-column>
                        <el-table-column prop="status" label="提现状态">
                            <template #default="scope">
                                <el-tag type="warning" v-if="scope.row.status == 1"
                                >待生成
                                </el-tag
                                >
                                <el-tag type="warning" v-else-if="scope.row.status == 2"
                                >待审核
                                </el-tag
                                >
                                <el-tag type="success" v-else-if="scope.row.status == 3"
                                >审核通过
                                </el-tag
                                >
                                <el-tag type="danger" v-else>已拒绝</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="申请时间"
                        >
                            <template #default="scope">
                                {{ DataUtils.formatDate(scope.row.applied_time) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="250" align="center">
                            <template #default="scope">
                                <reference></reference>
                                <el-popover
                                    placement="bottom"
                                    title="操作组"
                                    width="200"
                                    trigger="click"
                                >
                                    <el-button-group>
                                        <el-button
                                            type="success"
                                            icon="el-icon-circle-check"
                                            @click="handleSuccess(scope.row)"
                                        >同意
                                        </el-button
                                        >
                                        <el-button type="danger" @click="handleNothing(scope.row)"
                                        >拒绝<i
                                            class="
                                            el-icon-circle-close
                                        "
                                        ></i
                                        ></el-button>
                                    </el-button-group>
                                    <template v-slot:reference>
                                        <el-button
                                            type="text"
                                            icon="el-icon-edit"
                                            :disabled="scope.row.status !== 2"
                                        >审核
                                        </el-button
                                        >
                                    </template>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pagination">
                        <el-pagination
                            background
                            layout="total, prev, pager, next"
                            :current-page="fundsPage"
                            :page-size="limit"
                            :total="fundsCount"
                            @current-change="handlePageChange"
                        ></el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="提现到银行卡" name="second">
                    <el-table
                        :data="recsList"
                        border
                        class="table"
                        ref="multipleTable"
                        header-cell-class-name="table-header"
                    >
                        <!-- <el-table-column
                            type="selection"
                            width="55"
                            align="center"
                        ></el-table-column> -->
                        <el-table-column
                            prop="bureau_name"
                            label="机构名"
                        ></el-table-column>
                        <el-table-column
                            prop="id_card"
                            label="身份证"
                        ></el-table-column>
                        <el-table-column
                            prop="phone"
                            label="手机号"
                        ></el-table-column>
                        <el-table-column
                            prop="bank_account"
                            label="银行卡"
                        ></el-table-column>
                        <el-table-column
                            prop="credit"
                            label="提现金额(元)"
                        ></el-table-column>
                        <el-table-column prop="status" label="提现状态">
                            <template #default="scope">
                                <el-tag type="warning" v-if="scope.row.status == 2"
                                >待审核
                                </el-tag
                                >
                                <el-tag type="success" v-else-if="scope.row.status == 3 || scope.row.status == 4"
                                >审核通过
                                </el-tag
                                >
                                <el-tag type="danger" v-else>审核失败</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="申请时间"
                        >
                            <template #default="scope">
                                {{ DataUtils.formatDate(scope.row.applied_time) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="250" align="center">
                            <template #default="scope">
                                <reference></reference>
                                <el-popover
                                    placement="bottom"
                                    title="操作组"
                                    width="200"
                                    trigger="click"
                                >
                                    <el-button-group>
                                        <el-button
                                            type="success"
                                            icon="el-icon-circle-check"
                                            @click="handleBankSuccess(scope.row)"
                                        >同意
                                        </el-button
                                        >
                                        <el-button type="danger" @click="handleRepulse(scope.row)"
                                        >拒绝<i
                                            class="
                                            el-icon-circle-close
                                        "
                                        ></i
                                        ></el-button>
                                    </el-button-group>
                                    <template v-slot:reference>
                                        <el-button
                                            type="text"
                                            icon="el-icon-edit"
                                            :disabled="scope.row.status !== 2"
                                        >审核
                                        </el-button
                                        >
                                    </template>
                                </el-popover>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pagination">
                        <el-pagination
                            background
                            layout="total, prev, pager, next"
                            :current-page="recsPage"
                            :page-size="limit"
                            :total="recsCount"
                            @current-change="handleBankPageChange"
                        ></el-pagination>
                    </div>
                </el-tab-pane>
            </el-tabs>

        </div>

        <el-dialog
            title="拒绝原因"
            v-model="dialogState"
            width="30%"
            :before-close="handleClose">
            <div>
                <el-input type="textarea" v-model="reason" rows="5"></el-input>
            </div>
            <el-button type="primary" @click="handleBankNothing" style="display: flex;margin-left: auto;margin-top:10px">确 定</el-button>
        </el-dialog>
    </div>
</template>

<script>
import {fetchData} from "../../api/api";
import DataUtils from "../../utils/dateUtils";

export default {
    name: "coursetable",
    components: {},
    data() {
        return {
            fundsPage: 1,
            limit: 10,
            fundsCount: 0,
            fundsList: [],
            activeName: 'first',
            DataUtils: DataUtils,
            recsPage: 1,
            recsList: [],
            recsCount: 0,
            reason: '',
            dialogState:false,
            rec_id:0,
        };
    },
    created() {
        this.getListActFunds();
        this.getListCreditRecs();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getListActFunds() {
            fetchData({page: this.fundsPage, limit: this.limit}, "/admin/v1/listActFunds", "GET").then((res) => {
                console.log(res);
                this.fundsList = res.data.list;
                this.fundsCount = res.data.count || 0;
            });
        },
        //同意
        handleSuccess(item) {
            let params = {
                fund_id: item.fund_id,
                status: 1,
                service_fund: item.service_fund,
                fund: item.final_fund,
            }
            fetchData(params, "/admin/v1/editFund", "POST").then(() => {
                this.getListActFunds();
            });
        },
        //拒绝
        handleNothing(item) {
            let params = {
                fund_id: item.fund_id,
                status: 0,
                service_fund: item.service_fund,
                fund: item.final_fund,
            }
            fetchData(params, "/admin/v1/editFund", "GET").then(() => {
                this.getListActFunds();
            });
        },
        // 分页导航
        handlePageChange(val) {
            this.fundsPage = val;
            this.getListActFunds();
        },
        //提现到银行卡
        getListCreditRecs() {
            fetchData({page: this.recsPage, limit: this.limit}, "/admin/v1/listCreditRecs", "GET").then((res) => {
                this.recsList = res.data.list;
                this.recsCount = res.data.count;
            });
        },
        //同意
        handleBankSuccess(item) {
            let params = {
                rec_id: item.id,
                status: 1
            }
            fetchData(params, "/admin/v1/reviewCreditRecs", "POST").then((res) => {
                console.log(res)
                    fetchData({rec_id: item.id}, "/admin/v1/confirmCreditRecs", "POST").then(() => {

                        this.getListCreditRecs();
                    })

            });
        },
        //拒绝
        handleBankNothing() {
            if(this.reason === ''){
                this.$message.error('拒绝原因请说明');
                return false;
            }
            let params = {
                rec_id: this.rec_id,
                status: 0,
                reason: this.reason
            }
            fetchData(params, "/admin/v1/reviewCreditRecs", "GET").then(() => {
                this.getListCreditRecs();
                this.dialogState = false;
            });
        },
        // 分页导航
        handleBankPageChange(val) {
            this.recsPage = val;
            this.getListCreditRecs();
        },
        handleRepulse(item) {
            console.log(item)
            this.dialogState = true;
            this.rec_id = item.id;
        }
    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}

.avatar {
    width: 120px;
    height: 120px;
    display: block;
}

::v-deep .el-upload--text {
    width: 120px;
    height: 120px;
}

::v-deep .el-drawer {
    overflow: scroll;
}
</style>
