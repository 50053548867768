<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 折扣列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-button icon="el-icon-plus" type="primary" @click="goodsAdd">添加折扣</el-button>
                    </el-col>
                    <el-col :span="8">
                        <span class="demonstration">按机构查找</span>
                        <el-select
                            v-model="bureau_id"
                            placeholder="请选择机构"
                            filterable
                            @change="selectBureau">
                            <el-option
                                v-for="item in bureauList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>
            </div>
            <el-table
                :data="discountList"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
            >
                <el-table-column
                    prop="id"
                    label="ID"
                    width="55"
                    align="center"
                ></el-table-column>
                <el-table-column prop="bureau_name" label="机构名"></el-table-column>
                <el-table-column
                    prop="discount"
                    label="折扣大小"
                ></el-table-column>
                <el-table-column
                    prop="start_time"
                    label="开始时间"
                ></el-table-column>
                <el-table-column
                    prop="end_time"
                    label="结束时间"
                ></el-table-column>
                <el-table-column label="操作" width="280" align="center">
                    <template #default="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.$index, scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            class="red"
                            @click="handleDelete(scope.$index, scope.row.id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="修改折扣" v-model="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="120px">
                <el-form-item label="优惠时间区间">
					<el-date-picker
                            v-model="form_daterange_time"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="YYYY 年 MM 月 DD 日"
                            :unlink-panels="true"
                        >
                    </el-date-picker>
                </el-form-item>
				<el-form-item label="选择机构">
					<el-select
                            v-model="bureau_id"
                            placeholder="请选择机构"
                            filterable
                            @change="selectBureau"
                        >
                            <el-option
                                v-for="item in bureauList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="优惠大小">
                    <!-- <el-input v-model="form.discount" style="width:200px;" min="0" max="100" type="number"></el-input> &nbsp; 折 -->
                    <el-select v-model="form.discount" placeholder="请选择">
                        <el-option
                        v-for="item in presetDiscount"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
        <!-- 添加弹出框 -->
        <el-dialog title="添加折扣" v-model="addVisible" width="30%">
            <el-form ref="form" :model="addData" label-width="120px">
                <el-form-item label="优惠时间区间">
					<!-- <el-date-picker
                            v-model="daterange_time"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="YYYY 年 MM 月 DD 日"
                            @change="changeDate"
                        >
                    </el-date-picker> -->
                    <el-date-picker
                        v-model="daterange_time"
                        type="date"
                        placeholder="选择日期"
                        :unlink-panels="true"
                        @change="changeDate">
                    </el-date-picker> 至
                    <span style="margin-left:10px">{{addData.end_time}}</span>
                </el-form-item>
				<el-form-item label="选择机构">
					<el-select
                            v-model="bureau_id"
                            placeholder="请选择机构"
                            filterable
                            @change="selectBureau"
                        >
                            <el-option
                                v-for="item in bureauList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="优惠大小">
                    <!-- <el-input v-model="addData.discount" style="width:200px;" min="0" max="100" type="number"></el-input> &nbsp; 折 -->
                    <el-select v-model="addData.discount" placeholder="请选择">
                        <el-option
                        v-for="item in presetDiscount"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
import DataUtils from "../utils/dateUtils";
export default {
    name: "goods",
    data() {
        return {
            query: {
                page: 1,
                limit: 10,
            },
            addData: {
                start_time: '', //开始优惠时间
                end_time: '', //结束优惠时间
                discount: '', //优惠大小 0-1的小数 0.9 表示 9折优惠
            },
            bureau_id: '',
            daterange_time: [], ////时间区间
            form_daterange_time: [], // 编辑时间区间
			bureauList: [], // 机构列表
            discountList: [], // 折扣列表
            form: {
                rec_id: '', //记录id
                start_time: '', //开始优惠时间
                end_time: '', //结束优惠时间
                discount: '', //优惠大小 0-1的小数 0.9 表示 9折优惠
            },
            presetDiscount: [
                {value:'10',label:'一折'},
                {value:'20',label:'二折'},
                {value:'30',label:'三折'},
                {value:'40',label:'四折'},
                {value:'50',label:'五折'},
                {value:'60',label:'六折'},
                {value:'70',label:'七折'},
                {value:'80',label:'八折'},
                {value:'90',label:'九折'},
            ],



            bindData: {},
            goodsData: [],
            courseData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            addVisible: false,
            bindVisible: false,
            pageTotal: 0,
            idx: -1,
            id: -1,
            imageUrl: "",
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            fetchData(
                { page: 1, limit: 999999 },
                "/bureau/v1/listBureau",
                "GET"
            ).then((res) => {
                this.bureauList = res.data.list;
                if (this.bureauList.length === 0) {
                    // this.$message.warning('暂无机构');
                    return false;
                }
                var id = this.$route.params.bureau_id;
                this.bureau_id = id ? parseInt(id) : res.list[0].id;
                this.tableData();
            });
        },
        tableData() {
            this.query.bureau_id = this.bureau_id;
            fetchData(
                this.query,
                "/admin/v1/listYearlyDiscount",
                "GET"
            ).then((res) => {
                this.discountList = res.data.list;
                this.pageTotal = res.data.count;
            });
        },
        selectBureau() {
            this.tableData();
        },
        // 触发搜索按钮
        handleSearch() {
            // this.$set(this.query, "pageIndex", 1);
            this.query.name = "";
            this.getData();
        },
        // 删除操作
        handleDelete(index, id) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            })
                .then(() => {
                    fetchData(
                        { rec_id: id },
                        "/admin/v1/deleteYearlyDiscount",
                        "GET"
                    ).then((res) => {
                        console.log(res);
                        this.$message.success("删除成功");
                        this.tableData();
                    });
                })
                .catch(() => {});
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 添加操作
        goodsAdd() {
            this.addVisible = true;
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form.discount = row.discount * 100 + '';
            this.form.rec_id = row.id;

            this.form_daterange_time = [
                new Date(row.start_time),
                new Date(row.end_time),
            ];
            this.editVisible = true;
        },
        // 保存编辑
        saveEdit() {
            let daterangeTime = this.form_daterange_time;
            this.form.start_time = DataUtils.formatDate(
                daterangeTime[0].getTime() / 1000,
                "YY-MM-DD"
            );
            this.form.end_time = DataUtils.formatDate(
                daterangeTime[1].getTime() / 1000,
                "YY-MM-DD"
            );
            this.form.discount = '0.' + this.form.discount.substr(0, 2);
            fetchData(this.form, "/admin/v1/editYearlyDiscount", "POST").then(() => {
                this.$message.success(`修改第 ${this.idx + 1} 行成功`);
                this.editVisible = false;
                this.tableData();
            });
        },
        // 保存添加
        saveAdd() {
            // let daterangeTime = this.daterange_time;
            // this.addData.start_time = DataUtils.formatDate(
            //     daterangeTime[0].getTime() / 1000,
            //     "YY-MM-DD"
            // );
            // this.addData.end_time = DataUtils.formatDate(
            //     daterangeTime[1].getTime() / 1000,
            //     "YY-MM-DD"
            // );
            this.addData.discount = '0.' + this.addData.discount.substr(0, 2);
            this.addData.bureau_id = this.bureau_id;
            fetchData(this.addData, "/admin/v1/createYearlyDiscount", "POST").then(
                () => {
                    this.$message.success(`添加成功`);
                    this.addVisible = false;
                    this.tableData();
                    this.addData = {};
                    this.daterange_time = [];
                }
            );
        },
        changeDate(val){
            this.addData.start_time = DataUtils.formatDate(
                val.getTime() / 1000,
                "YY-MM-DD"
            );
            var date = new Date(this.addData.start_time);
            this.addData.end_time = DataUtils.formatDate(
                date.setFullYear(date.getFullYear() + 1) / 1000,
                "YY-MM-DD"
            );
        },
        // 分页导航
        handlePageChange(val) {
            this.query.page = val;
            this.tableData();
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
                // this.$message.error("上传头像图片只能是 JPG 格式!");
            // }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            // return isJPG && isLt2M;
            return isLt2M;
        },
        goodsBindingCourse(id) {
            this.bindVisible = true;
            this.bindData.goods_id = id;
        },
        // 提交绑定
        saveBind() {
            console.log(this.bindData);
            fetchData(
                this.bindData,
                "/admin/v1/goodsBindingToCourse",
                "POST"
            ).then((res) => {
                console.log(res);
                this.$message.success(`绑定成功`);
                this.bindVisible = false;
            });
            // this.$set(this.tableData, this.idx, this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.left {
    float: left;
}
.right {
    float: right;
}
.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
.avatar {
    width: 120px;
    height: 120px;
    display: block;
}
::v-deep .el-upload--text {
    width: 120px;
    height: 120px;
}
.el-select .el-input {
    width: 130px;
}
.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
.demonstration{
    margin-right: 1rem;
}
</style>
