<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-button icon="el-icon-plus" type="primary" @click="addObjectOpen"
					>添加操作对象</el-button
				>
			</div>
			<not-found v-if="ObjectData.length === 0" text="暂时还没有操作对象啦"></not-found>
			<el-row :gutter="20">
				<el-col :span="6" v-for="(item, index) in ObjectData" :key="index">
					<el-card class="theObject">
						<div class="name">
							<div class="ObjectBox">{{ item.name }}</div>
							<div class="btnBox">
								<el-button size="mini" type="primary" @click="editObjectOpen(item)">修改</el-button>
								<el-popconfirm title="这是一段内容确定删除吗？" @confirm="delObject(item.id)">
									<template #reference>
										<el-button size="mini" type="danger">删除</el-button>
									</template>
								</el-popconfirm>
							</div>
						</div>
						<div class="text">
							{{ item.intro }}
						</div>
					</el-card>
				</el-col>
			</el-row>
			<div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
		</div>
		<!-- 弹出框 -->
        <el-dialog title="定义您的数据" v-model="dialog" width="30%">
            <el-form ref="form" :model="theObjectData" label-width="100px">
                <el-form-item label="操作对象名">
                    <el-input v-model="theObjectData.name"
					placeholder="请输入操作对象名"></el-input>
                </el-form-item>
                <el-form-item label="介绍">
                    <el-input
                        v-model="theObjectData.intro"
                        placeholder="请输入操作对象介绍"
						type="textarea"
                        rows="3"
                    ></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="operateData"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
	</div>
</template>
<script>

import NotFound from "../../components/NotFound.vue";
import { fetchData } from "../../api/api";

export default {
	name: "Objectlist",
	setup() {
		
	},
	created() {
        this.getData();
    },
	computed: {
		operateData() {
			return ()=>{
				this.operate == "add" ? this.ObjectAdd() : this.ObjectEdit();
			}
		}
	},
	components: {
		NotFound
	},
	data() {
		return {
			query: {
				page: 1,
				limit: 12,
			},
			pageTotal: 0,
			ObjectData:[],
			dialog: false,
			operate: "add", //add  edit
			theObjectData: {
				id: 0,
				name: '',
				intro: '',
			}
		}
	},
	methods: {
		getData() {
			fetchData(
				{ page: this.query.page, limit: this.query.limit},
				"/admin/v1/listObjects",
				"GET"
			).then((res) => {
				this.pageTotal = res.data.count;
				this.ObjectData = res.data.list;
				console.log(res);
			});
		},
		theObjectDataInit () {
			this.theObjectData = {
				id: 0,
				name: '',
				intro: '',
			};
		},
		addObjectOpen() {
			this.theObjectDataInit();
			this.dialog = true;
			this.operate = "add";
		},
		ObjectAdd() {
			fetchData(
				{ name: this.theObjectData.name, intro: this.theObjectData.intro},
				"/admin/v1/addObject",
				"POST"
			).then(() => {
				this.dialog = false;
				this.theObjectDataInit();
				this.$message.success("添加成功");
				this.getData();
			});
		},
		editObjectOpen(item) {
			this.theObjectData.id = item.id;
			this.theObjectData.name = item.name;
			this.theObjectData.intro = item.intro;
			this.dialog = true;
			this.operate = "edit";
		},
		ObjectEdit() {
			fetchData(
				this.theObjectData,
				"/admin/v1/editObject",
				"POST"
			).then(() => {
				this.dialog = false;
				this.theObjectDataInit();
				this.$message.success("修改成功");
				this.getData();
			});
		},
		delObject(id) {
			fetchData(
				{id: id},
				"/admin/v1/deleteObject",
				"POST"
			).then(() => {
				this.$message.success("删除成功");
				this.getData();
			});
		},
		handlePageChange(val) {
			this.query.page = val;
			this.getData();
		}
	}
}
</script>
<style scoped lang="scss">
.theObject {
	margin-bottom: 1.5rem;
	.name{
		line-height: 2rem;
		font-weight: bold;
		div {
			display: inline-block;
			&.ObjectBox {
				max-width: 50%;
			}
			&.btnBox {
				text-align: right;
				float: right;
				opacity: 0;
				transition: opacity 0.4s;
			}
		}
	}
	.text{
		border-radius: 7px;
		background-color: #f3f3f3;    
		min-height: 60px;
		padding: 8px 17px;
		font-size: 14px;
	}
	&:hover {
		& .name .btnBox {
			opacity: 1;
		}
	}
}
</style>
