<template>
	<div class="main">
		<div class="head">
			<el-radio-group v-model="radioType" size="mini" @change="radioTheType">
				<el-radio-button :label="item.value" v-for="item in type" :key="item">{{item.label}}</el-radio-button>
			</el-radio-group>
			<el-popconfirm title="确定执行你的操作么？" @confirm="saveBind">
				<template #reference>
					<el-button size="mini" type="primary">保存</el-button>
				</template>
			</el-popconfirm>
			<div class="myOperate">
				<p>
					{{ bindText }}
				</p>
				<p>
					{{ unBindText }}
				</p>
			</div>
		</div>
		<el-row class="obj-box" :gutter="20">
			<el-col :span="6" v-for="(item, index) in objectList" :key="index">
				<Paths 
					:objId="item.objId"
					:objName="item.objName" 
					:objIntro="item.objIntro"
					:pathlists="item.paths"
					:showType="radioType"
					:rolePaths="rolePaths"
					:unBindList="unBindList"
					@getBindList="getBindList"
					@getUnBindList="getUnBindList"
				></Paths>
			</el-col>
		</el-row>
	</div>
</template>
<script>

import { fetchData } from "../../api/api";
import Paths from "./Paths.vue";

export default {
	name: 'RolePermission',
	props: ["roleId"],
	components: {
		Paths
	},
	data () {
		return {
			objectList: [
				// {
				// 	objId: 1,
				// 	objName: '',
				// 	objIntro: '',
				// 	paths: [
				// 		{id: 1, path: '', intro: '', checked: false}
				// 	],
				// }
			],
			allPaths: [],
			allPathsIndexToId: {},
			rolePaths: [],
			radioType: 'all',
			type: [
				{label: '全部', value: 'all'},
				{label: '未拥有', value: 'neverHad'},
				{label: '已拥有', value: 'had'}
			],
			bindList: [],
			unBindList: [],
			bindText: '',
			unBindText: ''
		}
	},
	created() {
		this.getData();
	},
	methods:  {
		getData() {
			this.getRolesPolicy();
		},
		getPathList() {
			fetchData(
				{page: 1, limit: 999999},
				"/admin/v1/listPaths",
				"GET"
			).then((res) => {
				this.allPaths = res.data.list;
				this.getAllPathIndexToId();
				res.data.list.forEach(item => {
					this.objectList.forEach(element => {
						if (item.object_id === element.objId) {
							element.paths.push({
								id: item.id,
								path: item.path,
								intro: item.intro,
								checked: this.rolePaths.indexOf(item.id) !== -1
							});
						}
					});	
				});
			});
		},
		getAllObject() {
			fetchData(
				{page: 1, limit: 999999},
				"/admin/v1/listObjects",
				"GET"
			).then((res) => {
				res.data.list.forEach(item => {
					this.objectList.push({
						objId: item.id,
						objName: item.name,
						objIntro: item.intro,
						paths: []
					});
				});
				this.getPathList();
			});
		},
		getRolesPolicy() {
			fetchData(
				{role_id: this.roleId},
				"/admin/v1/listPolicy",
				"GET"
			).then((res) => {
				res.data.forEach(item => {
					this.rolePaths.push(item.path_id);
				});
				if (this.objectList.length === 0) {
					this.getAllObject();
				}
			});
		},
		radioTheType() {
		},
		getBindList(val, objId) {
			val.forEach(item => {
				let key = this.unBindList.indexOf(item);
				if (key === -1) {
					let key1 = this.bindList.indexOf(item);
					if (key1 === -1) {
						this.bindList.push(item);
					}
				} else {
					this.unBindList.splice(key, 1);
				}
				this.editPathType(objId, item, true);
			});
			this.getPathText();
		},
		getUnBindList(val, objId) {
			val.forEach(item => {
				let key = this.bindList.indexOf(item);
				if (key === -1) {
					let key1 = this.unBindList.indexOf(item);
					if (key1 === -1) {
						this.unBindList.push(item);
					}
				} else {
					this.bindList.splice(key, 1);
				}
				this.editPathType(objId, item, false);
			});
			this.getPathText();
		},
		editPathType(objId, pathid, type) {
			this.objectList.forEach((item)=>{
				if (item.objId === objId) {
					item.paths.forEach(element => {
						if (element.id === pathid) {
							element.checked = type;
						}
					});
				}
			});
		},
		saveBind() {
			if (this.bindList.length === 0 && this.unBindList.length === 0) {
				this.$message.warning("暂无操作");
			}
			this.bindList.forEach((pathid, index) => {
				fetchData(
					{role_id: this.roleId, path_id: pathid},
					"/admin/v1/addPolicy",
					"GET"
				).then(() => {
					if (index+1 === this.bindList.length) {
						if (this.unBindList.length === 0) {
							this.$message.success("操作成功");
							this.dataInit();
						}
						this.unBindList.forEach((pathId, key) => {
							fetchData(
								{role_id: this.roleId, path_id: pathId},
								"/admin/v1/deletePolicy",
								"GET"
							).then(() => {
								if (key+1 === this.unBindList.length) {
									this.$message.success("操作成功");
									this.dataInit();
								}
							});
						});
					}
				});
			});
			if (this.bindList.length === 0) {
				this.unBindList.forEach((pathId, key) => {
					fetchData(
						{role_id: this.roleId, path_id: pathId},
						"/admin/v1/deletePolicy",
						"GET"
					).then(() => {
						if (key+1 === this.unBindList.length) {
							this.$message.success("操作成功");
							this.dataInit();
						}
					});
				});
			}
		},
		dataInit() {
			this.unBindList = [];
			this.bindList = [];
			this.rolePaths = [];
			this.bindText = "";
			this.unBindText = "";
			this.getRolesPolicy();
		},
		getAllPathIndexToId() {
			this.allPaths.forEach((item, index) => {
				this.allPathsIndexToId[item.id] = index;
			});
		},
		getPathText() {
			let types = ["bind", "unBind"];
			types.forEach(type => {
				let arr = type === 'bind' ? this.bindList : this.unBindList,
					text = "";
				arr.forEach((item, index) => {
					if (index === 0) { 
						text += type === "bind" ? "绑定：" : "解绑：";
					}
					text += (this.allPaths[this.allPathsIndexToId[item]].path + (index!== arr.length-1 ? ", " : ""));
				});
				type === "bind" ? this.bindText = text : this.unBindText = text;
			});
		}
	}
}
</script>
<style scoped lang="scss">
.main {
	background-color: #fff;
	.head {
		padding: 10px 20px;
		background-color: #f1f1f1;
		border-radius: 5px;
		.el-button {
			float: right;
		}
		.myOperate {
			background: #fff;
			border-radius: 5px;
			margin-top: 9px;
			padding: 0px 5px;
			p {
				margin-top: 5px;
			}
		}
	}
	.obj-box {
		max-height: 350px;
		overflow: auto;
	}
}
*::-webkit-scrollbar {
width: 5px;
height: 10px;
}
/*正常情况下滑块的样式*/
*::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	-webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在该类指向的控件上时滑块的样式*/
*:hover::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	-webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在滑块上时滑块的样式*/
*::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*正常时候的主干部分*/
*::-webkit-scrollbar-track {
	border-radius: 10px;
	-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0);
	background-color: white;
}

/*鼠标悬浮在滚动条上的主干部分*/
*::-webkit-scrollbar-track:hover {
	-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.4);
	background-color: rgba(0, 0, 0, 0.01);
}
</style>