<template>
    <div>
        <el-drawer
            title="模板字段列表"
            v-model="drawer"
            :direction="direction"
            :before-close="handleClose"
            size="75%"
            :lock-scroll="false"
            style="overflow:scroll;">
            <div class="container" style="padding-bottom: 100px; max-height: 74%; overflow: auto;">
                <div class="handle-box" style="margin-bottom: 20px">
                    <el-button
                        type="primary"
                        icon="el-icon-plus"
                        class="handle-del mr10"
                        @click="addFiledBut"
                    >添加字段
                    </el-button>
                </div>
                <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="55" align="center"></el-table-column>
                    <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
                    <el-table-column prop="title" label="标题"></el-table-column>
                    <el-table-column prop="class_name" label="类名"></el-table-column>
                    <el-table-column prop="attr" label="标签属性"></el-table-column>
                    <el-table-column prop="add_type" label="输入类型"></el-table-column>
                    <el-table-column label="操作" width="180" align="center">
                        <template #default="scope">
                            <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.$index, scope.row)"
                            >编辑
                            </el-button>
                            <el-button
                                type="text"
                                icon="el-icon-delete"
                                style="color:red"
                                @click="handleDelete(scope.$index, scope.row)"
                            >删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-drawer>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑字段" v-model="editVisible" width="30%">
            <div class="detail-class">
                <el-form ref="form" :model="formData" label-width="80px">
                    <el-form-item label="标题">
                        <el-input v-model="formData.title"></el-input>
                    </el-form-item>
                    <el-form-item label="类名">
                        <el-input v-model="formData.class_name"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="formData.value"></el-input>
                    </el-form-item>
                    <el-form-item label="标签属性">
                        <el-select v-model="formData.attr" placeholder="请选择标签属性">
                            <el-option v-for="(item,index) in attrData" :key="index" :value="item.value"
                                       :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="输入类型">
                        <el-select v-model="formData.add_type" placeholder="请选择输入类型">
                            <el-option v-for="(item,index) in addTypeData" :key="index" :value="item.value"
                                       :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">编辑</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="添加字段" v-model="addVisible" width="30%">
            <div class="detail-class">
                <el-form ref="form" :model="addFormData" label-width="80px">
                    <el-form-item label="标题">
                        <el-input v-model="addFormData.title"></el-input>
                    </el-form-item>
                    <el-form-item label="类名">
                        <el-input v-model="addFormData.class_name"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="addFormData.value"></el-input>
                    </el-form-item>
                    <el-form-item label="标签属性">
                        <el-select v-model="addFormData.attr" placeholder="请选择标签属性">
                            <el-option v-for="(item,index) in attrData" :key="index" :value="item.value"
                                       :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="输入类型">
                        <el-select v-model="addFormData.add_type" placeholder="请选择输入类型">
                            <el-option v-for="(item,index) in addTypeData" :key="index" :value="item.value"
                                       :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addFiledButton">创建字段</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {fetchData} from "../../api/api";

export default {
    props: ['template_id'],
    data() {
        return {
            drawer: false,
            direction: 'btt',

            query: {
                address: "",
                name: "",
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            addVisible: false,
            pageTotal: 0,
            form: {},
            formData: {},
            addFormData: {
                attr: '',
                add_type: '',
                title: '',
                class_name: '',
                value: '',
            },
            idx: -1,
            id: -1,
            attrData: [
                {label: 'innter', value: 'innter'},
                {label: 'src', value: 'src'},
                {label: 'value', value: 'value'},
                {label: 'poster', value: 'poster'},
                {label: 'song', value: 'song'},
            ],
            addTypeData: [
                {label: 'input', value: 'input'},
                {label: 'upload', value: 'upload'},
                {label: 'textarea', value: 'textarea'}
            ],
            templateId: this.template_id
        };
    },
    created() {
        if (this.templateId !== undefined) {
            this.drawer = true;
        }
        this.getData();
    },
    methods: {
        handleClose(done) {
            done();
        },
        // 获取 easy-mock 的模拟数据
        getData() {
            fetchData({
                template_id: this.templateId
            }, "/admin/v1/listLessonTemField", "get").then(
                (res) => {
                    if (res.code === 200) {
                        console.log(res)
                        this.tableData = res.data;
                    }
                }
            );
        },
        // 触发搜索按钮
        handleSearch() {
            this.$set(this.query, "pageIndex", 1);
            this.getData();
        },
        // 删除操作
        handleDelete(index, row) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning"
            })
                .then(() => {
                    fetchData({
                        id: row.id
                    }, "/admin/v1/delLessonTemField", "get").then(
                        (res) => {
                            if (res.code === 200) {
                                this.$message.success("删除成功");
                                this.tableData.splice(index, 1);
                            }
                        }
                    );

                })
                .catch(() => {
                });
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 编辑操作
        handleEdit(index, row) {
            console.log(row)
            this.idx = index;
            this.formData = {
                id: row.id,
                title: row.title,
                attr: row.attr,
                add_type: row.add_type,
                class_name: row.class_name,
                template_id: row.template_id,
                value: row.value,
            };

            this.editVisible = true;
        },
        // 保存编辑
        saveEdit() {
            fetchData(this.formData, "/admin/v1/editLessonTemField", "POST").then(
                (res) => {
                    if (res.code === 200) {
                        console.log(res)
                        // this.tableData = res.data;
                        this.getData();
                        this.editVisible = false;
                        this.$message.success(`修改第 ${this.idx + 1} 行成功`);
                    }
                }
            );
        },
        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, "pageIndex", val);
            this.getData();
        },
        //添加字段按钮
        addFiledBut() {
            this.addVisible = true;
        },
        addFiledButton() {
            this.addFormData.template_id = this.template_id;
            fetchData(this.addFormData, "/admin/v1/addLessonTemField", "POST").then(
                (res) => {
                    if (res.code === 200) {
                        this.addVisible = false;
                        this.getData();
                    }
                }
            );
        }
    }
};
</script>

<style scoped>
::v-deep.el-drawer__body {
    overflow-y: auto !important;
}

.container {
    /*border: none;*/
}

.page-footer-box-class {
    display: inline-block;
    float: right;
    /*position: fixed;*/
    /*bottom: 0;*/
    /*width: 100%;*/
    /*background: #e53c3c;*/
}
</style>
