<template>
    <div>
        <div class="container">
            <div class="handle-box">
                <el-button
                    icon="el-icon-plus"
                    type="primary"
                    @click="addSubMenu(0)"
                    >添加菜单</el-button
                >
            </div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                row-key="menu_id"
                border
                lazy
                :load="load"
                :tree-props="{
                    children: 'children',
                    hasChildren: 'hasChildren',
                }"
                :default-expand-all="false"
            >
                <el-table-column prop="icon" label="icon标志">
                </el-table-column>
                <el-table-column prop="vue_route" label="vue路由">
                </el-table-column>
                <el-table-column prop="menu_id" label="id">
                </el-table-column>
                <el-table-column prop="menu_name" label="菜单名">
                </el-table-column>
                <el-table-column prop="sign" label="菜单标志">
                </el-table-column>
                <el-table-column prop="pos" label="排序">
                </el-table-column>
                <el-table-column label="操作" width="280" align="center">
                    <template #default="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.row)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-plus"
                            @click="addSubMenu(scope.row.menu_id)"
                            >添加子菜单</el-button
                        >
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            class="red"
                            @click="delSubMenu(scope.row.menu_id, scope.row.parent_id)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 弹出框 -->
        <el-dialog title="菜单信息" v-model="dataVisible" width="30%">
            <el-form ref="form" v-model="menuData" label-width="70px">
                <el-form-item label="菜单名">
                    <el-input v-model="menuData.name"></el-input>
                </el-form-item>
                <el-form-item label="菜单标识">
                    <el-input v-model="menuData.sign"></el-input>
                </el-form-item>
                <el-form-item label="菜单路由">
                    <el-input v-model="menuData.vue_route"></el-input>
                </el-form-item>
                <el-form-item label="图标">
                    <el-input v-model="menuData.icon"></el-input>
                </el-form-item>
                <el-form-item label="父id菜单的id">
                    <el-input v-model="menuData.parent_id"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="menuData.pos"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveData"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
export default {
    name: "menu",
    data() {
        return {
            menuData: {
                name: "",
                parent_id: 0,
                sign: "",
                vue_route: "",
                icon: "",
                pos: 0
            },
            theMenuId: 0,
            dataVisible: false,

            tableData: [],
            maps: new Map(),
            menusetUrl: ''
        };
    },
    created() {
        this.getData();
    },
    methods: {
        // 保存数据
        saveData() {
            fetchData(this.menuData, this.menusetUrl, "POST").then(
                () => {
                    this.$message.success(`操作成功`);
                    this.dataVisible = false;
                    this.getData();


                    const { tree, treeNode, resolve } = this.maps.get(this.menuData.parent_id);
                    this.$set(
                        this.$refs.multipleTable.store.states.lazyTreeNodeMap,
                        this.menuData.parent_id,
                        []
                    );
                    if (tree) {
                        this.load(tree, treeNode, resolve);
                    }

                    (this.theMenuId = 0),
                    (this.menuData = {
                        name: "",
                        parent_id: 0,
                        sign: "",
                        vue_route: "",
                        icon: "",
                        pos: 0
                    });
                    console.log(this.tableData);
                }
            );
        },
        addSubMenu(id) {
            this.menuData.name = '';
            this.menuData.sign = '';
            this.menuData.vue_route = '';
            this.menuData.icon = '';
            this.menuData.pos = 0;

            this.menuData.parent_id = id;
            this.dataVisible = true;
            this.menusetUrl = "/admin/v1/addMenuItem";
        },
        handleEdit(data) {
            this.menusetUrl = "/admin/v1/editMenuItem";

            this.menuData.name = data.menu_name;
            this.menuData.parent_id = data.parent_id;
            this.menuData.sign = data.sign;
            this.menuData.vue_route = data.vue_route;
            this.menuData.icon = data.icon;
            this.menuData.menu_id = data.menu_id;
            this.menuData.pos = data.pos;
            this.dataVisible = true;
        },
        getData() {
            fetchData({ parent_id: 0 }, "/admin/v1/listMenuItems", "GET").then(
                (res) => {
                    this.tableData = res.data;
                    this.tableData.forEach((item) => {
                        item.hasChildren = true;
                        item.parent_id = 0;
                    });
                }
            );
        },
        load(tree, treeNode, resolve) {
            this.maps.set(tree.menu_id, { tree, treeNode, resolve });
            fetchData(
                { parent_id: tree.menu_id },
                "/admin/v1/listMenuItems",
                "GET"
            ).then((res) => {


                const nodes = res.data.map(item => {
                    return {
                    ...item,
                    // 该数据有三级结构
                    hasChildren: true
                    }
                })
                resolve(nodes)


                // res.data.forEach((item) => {
                //     item.hasChildren = true;
                //     item.parent_id = tree.menu_id;
                // });
                // resolve(res.data);
            });
        },
        refreshLoadTree(parentId, vm) {
            // 根据父级id取出对应节点数据
            const { tree, treeNode, resolve } = this.maps.get(parentId);
            vm.$set(
                this.$refs.multipleTable.store.states.lazyTreeNodeMap,
                parentId,
                []
            );
            if (tree) {
                this.load(tree, treeNode, resolve);
            }
        },
        delSubMenu(id, parent_id) {
            fetchData({ menu_id: id }, "/admin/v1/deleteMenuItem", "GET").then(
                () => {
                    this.$message.success(`删除成功`);

                    const { tree, treeNode, resolve } = this.maps.get(parent_id);
                    this.$set(
                        this.$refs.multipleTable.store.states.lazyTreeNodeMap,
                        parent_id,
                        []
                    );
                    if (tree) {
                        this.load(tree, treeNode, resolve);
                    }


                    // this.getData();
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.left {
    float: left;
}
.right {
    float: right;
}
.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}
.avatar {
    width: 120px;
    height: 120px;
    display: block;
}
::v-deep .el-upload--text {
    width: 120px;
    height: 120px;
}
</style>
