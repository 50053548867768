<template>
    <div class="main">
        <Tag
            :points="points"
            :isBind="true"
            @dangerTag="unBind"
            @addpoints="morepointsOpen = true"
        ></Tag>
        <el-dialog title="知识点列表" width="30%" v-model="morepointsOpen">
            <label-dialog @getCheckedpoints="getCheckedpoints" :category="category" :categoryId="categoryId"></label-dialog>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="morepointsOpen = false"
                        >取 消</el-button>
                    <el-button
                        type="primary"
                        @click="bindpoints"
                        >确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import LabelDialog from "./LabelDialog.vue";
import Tag from "./Tag.vue";
import { fetchData } from "../../../api/api";

export default {
    name: "AdminLabel",
    components: {
        Tag,
        LabelDialog,
    },
    computed: {},
    props: ["category", "categoryId"],
    emits: [],
    data() {
        return {
            // category: 'bureau',
            // categoryId: 1,
            morepointsOpen: false,
            checkedpoints: [],
            points: [],
        };
    },
    created() {
        this.getPoints();
    },
    watch: {
        categoryId() {
            this.getPoints();
        }
    },
    methods: {
        getPoints() {
            fetchData(
                { group_id: this.categoryId }, 
                "/edu/v1.2/listGroupPoints",
                "GET"
            ).then((res) => {
                this.points = res.data;
            });
        },
        unBind(id) {
            fetchData({group_id: this.categoryId, point_id: id}, "/edu/v1.2/unbindPoint", "POST").then(() => {
                this.getPoints();
                this.$message.success("解绑成功");
            });
        },
        getCheckedpoints(points) {
            this.checkedpoints = points;
        },
        bindpoints() {
            if (this.checkedpoints.length === 0) {
                this.$message.error("请选择要绑定的知识点");
                return false;
            }
            this.checkedpoints.forEach((tag, index) => {
                fetchData({group_id: this.categoryId, point_id: tag}, "/edu/v1.2/bindPoint", "POST").then(() => {
                    if (this.checkedpoints.length === (index+1)) {
                        this.getPoints();
                        this.morepointsOpen = false;
                        this.$message.success("绑定成功");
                    }
                });
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.main {
    text-align: left;
}
</style>