<template>
  <transition-group
    class="drag-sort-box"
    name="sort"
    tag="div"
    @dragover="dragover($event)"
  >
    <div
      :class="[itemClass, 'drag-sort-item']"
      v-for="(item, index) in images"
      :key="item"
      :draggable="true"
      @dragstart="dragstart(item, index)"
      @dragenter="dragenter(item, $event)"
      @dragend="dragend(item, $event)"
      @dragover="dragover($event)"
    >
      <i class="el-icon-error" @click="handleDeleteImg(index, item)"></i>
      <p class="moxsind">{{ index + 1 }}</p>
      <!-- <img
        :src="item"
        :class="index == dargIndex ? 'active' : ''"
        @click="handleBigImg(item)"
      /> -->
      <el-image
        :class="index == dargIndex ? 'active' : ''"
        style="width: 400px;"
        :src="item"
        :preview-src-list="srcList"
        @click="handleBigImg(item)"
      >
      </el-image>
    </div>
    <!-- <div class="img-big-class">
      <img :src="imgUrl" alt="" />
    </div> -->
  </transition-group>
</template>

<script>
import { ref } from "vue";
export default {
  name: "DragSort",
  props: {
    imageList: Array, // 传入图片数据
    sortType: {
      // 排序方式
      type: String,
      default: "insert",
    },
    itemClass: String, //自定义类名
  },
  data() {
    return {
      imgUrl: "",
      srcList: [],
    };
  },
  watch: {
    imageList(newValue) {
      console.log(newValue);
      this.images = newValue;
    },
  },
  setup(prop, ctx) {
    let images = ref([...prop.imageList]);
    // images.value = images.value.map((v, i) => v = v + '?index=' + i);//不重复key
    let dargIndex = ref(-1);

    let oldData = null;
    let newData = null;

    function dragstart(value, index) {
      oldData = value;
      dargIndex.value = index;
    }
    function dragenter(value, e) {
      newData = value;
      e.preventDefault();
    }

    function dragover(e) {
      e.preventDefault();
    }

    function dragend() {
      if (oldData !== newData) {
        let oldIndex = images.value.indexOf(oldData);
        let newIndex = images.value.indexOf(newData);

        if (prop.sortType == "insert") {
          let newItems = [...images.value];
          newItems.splice(oldIndex, 1);
          newItems.splice(newIndex, 0, oldData);
          images.value = [...newItems];
        } else {
          [images.value[oldIndex], images.value[newIndex]] = [
            images.value[newIndex],
            images.value[oldIndex],
          ];
        }

        ctx.emit("change", images.value);
      }
      dargIndex.value = -1;
    }

    return {
      images,
      dargIndex,

      dragover,
      dragstart,
      dragenter,
      dragend,
    };
  },
  methods: {
    handleDeleteImg(index, item) {
      this.$emit("delIntroduceItem", { index, url: item });
    },
    handleBigImg(url) {
      //   this.imgUrl = url;
      console.log(url);
      this.srcList[0] = url;
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 1;
}
.drag-sort-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.drag-sort-box .drag-sort-item {
  width: 400px;
  height: 400px;
  margin: 2px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
  background: #ccc;
  position: relative;
}

.drag-sort-box .drag-sort-item img {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  position: relative;
}
.drag-sort-box .drag-sort-item .active {
  border-radius: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
}
.moxsind {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dfd6d6;
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  font-size: 50px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
}

.el-icon-error {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 0px;
  color: red;
  font-size: 26px;
}
.img-big-class {
  position: fixed;
  top: 20%;
  left: 20%;
  width: 60%;
  margin: 0 auto;
}

::v-deep .el-image-viewer__canvas img {
  width: auto !important;
}

::v-deep .el-image__inner{
  width: 180% !important;
  margin-left: -6rem;
}
</style>

