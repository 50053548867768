<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-button icon="el-icon-plus" type="primary" @click="addPathOpen"
					>添加路径</el-button
				>
				<el-select v-model="thePathData.object_id" placeholder="请选择操作对象" style="margin-left:20px;"
					@change="changeObj">
					<el-option label="查看全部" :value="0"></el-option>
					<el-option
					v-for="item in ObjectData"
					:key="item.id"
					:label="item.name"
					:value="item.id"
					>
					</el-option>
				</el-select>
			</div>
			<not-found v-if="PathData.length === 0" text="暂时还没有路径啦"></not-found>
			<el-row :gutter="20">
				<el-col :span="6" v-for="(item, index) in PathData" :key="index">
					<el-card class="thePath">
						<div class="name">
							<div class="PathBox">{{ item.path }}</div>
							<div class="btnBox">
								<el-button size="mini" type="primary" @click="editPathOpen(item)">修改</el-button>
								<el-popconfirm title="这是一段内容确定删除吗？" @confirm="delPath(item.id)">
									<template #reference>
										<el-button size="mini" type="danger">删除</el-button>
									</template>
								</el-popconfirm>
							</div>
						</div>
						<div class="text">
							{{ item.intro }}
						</div>
						<div class="pathTag">{{ item.object_name }}</div>
					</el-card>
				</el-col>
			</el-row>
			<div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.page"
                    :page-size="query.limit"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
		</div>
		<!-- 弹出框 -->
        <el-dialog title="定义您的数据" v-model="dialog" width="30%">
            <el-form ref="form" :model="thePathData" label-width="90px">
				<el-form-item label="操作对象名">
					<el-select v-model="thePathData.object_id" placeholder="请选择">
						<el-option
						v-for="item in ObjectData"
						:key="item.id"
						:label="item.name"
						:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="路径名">
                    <el-input v-model="thePathData.path"></el-input>
                </el-form-item>
                <el-form-item label="介绍">
                    <el-input
                        v-model="thePathData.intro"
                        type="textarea"
                        placeholder="请输入路径简介"
                        rows="3"
                    ></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialog = false">取 消</el-button>
                    <el-button type="primary" @click="operateData"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
	</div>
</template>
<script>

import NotFound from "../../components/NotFound.vue";
import { fetchData } from "../../api/api";

export default {
	name: "Pathlist",
	setup() {
		
	},
	created() {
        this.getData();
    },
	computed: {
		operateData() {
			return ()=>{
				this.operate == "add" ? this.PathAdd() : this.PathEdit();
			}
		}
	},
	components: {
		NotFound
	},
	data() {
		return {
			query: {
				page: 1,
				limit: 12,
			},
			pageTotal: 0,
			PathData:[],
			ObjectData:[],
			dialog: false,
			operate: "add", //add  edit
			theObjId: "",
			thePathData: {
				id: 0,
				path: '',
				intro: '',
				object_id: 0
			}
		}
	},
	methods: {
		getData() {
			fetchData(
				{ page: this.query.page, limit: this.query.limit, object_id: this.thePathData.object_id},
				"/admin/v1/listPaths",
				"GET"
			).then((res) => {
				this.pageTotal = res.data.count;
				this.PathData = res.data.list;
			});
			fetchData(
				{ page: 1, limit: 999999},
				"/admin/v1/listObjects",
				"GET"
			).then((res) => {
				this.ObjectData = res.data.list;
			});
		},
		thePathDataInit () {
			this.thePathData = {
				id: 0,
				path: '',
				intro: '',
			};
		},
		addPathOpen() {
			this.thePathDataInit();
			this.dialog = true;
			this.operate = "add";
		},
		PathAdd() {
			fetchData(
				{ path: this.thePathData.path, intro: this.thePathData.intro, action: "read", object_id: this.thePathData.object_id, method: "POST"},
				"/admin/v1/addPath",
				"POST"
			).then(() => {
				this.dialog = false;
				this.thePathDataInit();
				this.$message.success("添加成功");
				this.getData();
			});
		},
		editPathOpen(item) {
			this.thePathData.id = item.id;
			this.thePathData.path = item.path;
			this.thePathData.intro = item.intro;
			this.thePathData.object_id = item.object_id;
			this.dialog = true;
			this.operate = "edit";
		},
		PathEdit() {
			fetchData(
				this.thePathData,
				"/admin/v1/editPath",
				"POST"
			).then(() => {
				this.dialog = false;
				this.thePathDataInit();
				this.$message.success("修改成功");
				this.getData();
			});
		},
		delPath(id) {
			fetchData(
				{id: id},
				"/admin/v1/deletePath",
				"POST"
			).then(() => {
				this.$message.success("删除成功");
				this.getData();
			});
		},
		handlePageChange(val) {
			this.query.page = val;
			this.getData();
		},
		changeObj() {
			fetchData(
				{ page: this.query.page, limit: this.query.limit, object_id: this.thePathData.object_id},
				"/admin/v1/listPaths",
				"GET"
			).then((res) => {
				this.pageTotal = res.data.count;
				this.PathData = res.data.list;
			});
		}
	}
}
</script>
<style scoped lang="scss">
.thePath {
	margin-bottom: 1.5rem;
	position: relative;
	.name{
		line-height: 2rem;
		font-weight: bold;
		div {
			display: inline-block;
			&.PathBox {
				max-width: 50%;
			}
			&.btnBox {
				text-align: right;
				float: right;
				opacity: 0;
				transition: opacity 0.4s;
			}
		}
	}
	.text{
		border-radius: 7px;
		background-color: #f3f3f3;    
		min-height: 60px;
		padding: 8px 17px;
		font-size: 14px;
	}
	.pathTag {
		position: absolute;
		display: inline-block;
		top: 3px;
		right: 20px;
		border: 1px solid #f5dab1;
		background-color: #fdf6ec;
		color: #e6a23c;
		border-radius: 5px;
		font-size: 12px;
		padding: 1px 9px;
	}
	&:hover {
		& .name .btnBox {
			opacity: 1;
		}
	}
}
</style>
