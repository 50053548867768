<template>
    <div>
        <div class="container">
            <div class="handle-box">
                <el-button icon="el-icon-plus" type="primary" @click="bureauAdd">添加经销人</el-button>
                <!-- <el-select v-model="selectTags" multiple placeholder="选择标签搜索" class="right" @change="handleSearch">
                    <el-option
                    v-for="item in bureauTags"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-input
                    v-model="query.name"
                    placeholder="经销人名"
                    class="handle-input mr10 right"
                    @change="handleSearch"
                ></el-input> -->
            </div>
            <not-found v-if="bureauData.length === 0" text="经销人找不到啦"></not-found>

            <el-row :gutter="20">
                <el-col :span="6" v-for="(item, index) in bureauData" :key="index">
                    <el-card shadow="always" style="margin-bottom: 40px;">
                        <div class="bureau-box">
                            <el-row :gutter="10" class="topPickBox"
                                :class="unfoldBureau.indexOf(item.id) !== -1 ? 'checked' : ''"
                                @click="checkBureau(item.id)">
                                <el-col :span="9">
                                    <div class="bureau-avatar">
                                        <img :src="item.avatar_url" alt="">
                                    </div>
                                </el-col>
                                <el-col :span="15" class="content">
                                    <p class="bureau-name">{{ item.name }}</p>
                                    <p :title="item.phone"><i class="el-icon el-icon-phone"></i>{{ item.phone }}</p>
                                    <p :title="item.create_time"><i class="el-icon el-icon-date"></i>{{ item.create_time }}
                                    </p>
                                </el-col>
                            </el-row>
                            <div class="conPickBox">
                                <!-- 标签 -->
                                <!-- <div class="pickBox">
                                    <p class="theTag">
                                        <span>标签</span>
                                    </p>
                                    <div class="content">
                                        <admin-label category="bureau" :categoryId="item.id" style="font-size:12px;"></admin-label>
                                    </div>
                                </div> -->
                                <!-- 附加信息 -->
                                <div class="pickBox">
                                    <p class="theTag">
                                        <span>附加信息</span>
                                    </p>
                                    <div class="content">
                                        <el-row :gutter="10" style="text-align: center;">
                                            <el-col :span="8">
                                                <reference></reference>
                                                <el-popover placement="bottom" title="标题" width="200" trigger="click"
                                                    @show="showSubBureau(item.id)">
                                                    <el-button icon="el-icon-s-custom" size="mini" round plain
                                                        @click="bindingSubordinate(item.id)"
                                                        style="margin-bottom: 10px;">绑定下级机构</el-button>
                                                    <el-table :data="subsInBureauList">
                                                        <el-table-column property="bureau_name" label="姓名"></el-table-column>
                                                        <el-table-column property="type" label="类型">
                                                            <template #default="scope">
                                                                <span v-if="scope.row.type === 1">机构</span>
                                                                <span v-if="scope.row.type === 2">园所</span>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                    <template v-slot:reference>
                                                        <el-button size="mini">下级机构</el-button>
                                                    </template>
                                                </el-popover>
                                            </el-col>
                                        </el-row>


                                    </div>
                                </div>
                                <!-- 操作 -->
                                <div class="pickBox">
                                    <p class="theTag">
                                        <span>操作组</span>
                                    </p>
                                    <div class="content">
                                        <el-button-group>
                                            <el-button size="mini" type="primary" icon="el-icon-edit"
                                                @click="handleEdit(index + 1, item)" plain>编辑</el-button>
                                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                                @click="handleDelete(index + 1, item.id)" plain>删除</el-button>
                                        </el-button-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="query.page"
                    :page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="修改经销人" v-model="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="头像">
                    <el-upload class="avatar-uploader" action="/resource/v1/uploadFile" :headers="headersObj"
                        :show-file-list="false" :on-success="editAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="form.avatar_url" :src="form.avatar_url" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="经销人名">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="form.passwd"></el-input>
                </el-form-item>
                <!-- <el-form-item label="介绍">
                    <el-input
                        v-model="form.intro"
                        type="textarea"
                        placeholder="请输入经销人简介"
                        rows="3"
                    ></el-input>
                </el-form-item> -->
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 添加弹出框 -->
        <el-dialog title="添加经销人" v-model="addVisible" width="30%">
            <el-form ref="form" :model="addData" label-width="70px">
                <el-form-item label="头像">
                    <el-upload class="avatar-uploader" action="/resource/v1/uploadFile" :headers="headersObj"
                        accept=".png,.jpg,.gif,.jpeg,.PNG,.JPG,.GIF,.JPEG" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="经销人名">
                    <el-input v-model="addData.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="addData.phone" type="number"></el-input>
                </el-form-item>
                <!-- <el-form-item label="地址">
                    <el-input v-model="addData.location"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="介绍">
                    <el-input
                        type="textarea"
                        placeholder="请输入经销人简介"
                        rows="3"
                        v-model="addData.intro"
                    ></el-input>
                </el-form-item> -->
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAdd">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 绑定下级 -->
        <el-dialog title="选择下级机构" v-model="selectSubordinate" width="30%">
            <el-form ref="form" :model="bindData" label-width="70px">
                <el-form-item label="下级机构">
                    <el-select v-model="bindData.sub_bureau_id" filterable placeholder="请选择机构" @change="subBureauChange">
                        <el-option v-for="item in bureauList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="绑定奖励">
                    <el-input v-model="bindData.binding_reward"></el-input>
                </el-form-item>
                <el-form-item label="有效天数">
                    <el-input v-model="bindData.days"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-radio v-model="bindData.type" :label="1">机构</el-radio>
                    <el-radio v-model="bindData.type" :label="2">园所</el-radio>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="selectSubordinate = false">取 消</el-button>
                    <el-button type="primary" @click="saveBindingtheSub">绑 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
// import AdminLabel from "../components/resource/adminTag/AdminLabel.vue";
import NotFound from "../components/NotFound.vue";
export default {
    name: "basetable",
    computed: {
        groupsLessonPlan() {
            return (num, all) => {
                return Math.floor((num / all) * 100);
            }
        }
    },
    data() {
        return {
            query: {
                page: 1,
                limit: 12,
                name: '',
            },
            selectTags: [],
            // reference: false,
            addData: {},
            bureauData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            addVisible: false,
            selectHelper: false,
            selectSubordinate: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
            imageUrl: "",
            imageId: 0,
            theHelpers: [],
            theHelper: "",
            theSubBureau: "",
            theSubordinates: [],
            theSubordinate: "",
            theDealer: 0,
            theGoodsType: "by_lesson_fee",
            helperList: [],
            bureauList: [],
            helpersInbureau: [],
            subsInBureauList: [],
            helperTable: false,
            selectedHelper: [],
            headersObj: {
                token: JSON.parse(localStorage.getItem("adminInfo")).token,
            },
            buyGoodsData: {},
            buyGoodsBox: false,
            goodsData: [],
            affirmOrder: false,
            renewGoodsVal: false,
            renewGoodsData: {},
            orderId: 0,
            orderData: {},
            bureauUserData: {},
            renewGoodid: "",
            goodsInbureau: [],
            groupsInbureau: [],
            goodsPage: {
                bureau_id: "",
                page: 1,
                limit: 5,
                count: 0,
            },
            topUpMoney: 0,
            goodsTypeList: [
                {
                    name: "计课时费套餐",
                    value: "by_lesson_fee",
                },
                // {
                //     name: "计时套餐",
                //     value: "by_hours",
                // },
                // {
                //     name: "包年套餐",
                //     value: "by_member_count",
                // },
            ],
            bureauDetails: false,
            detailsData: {},
            bureauTags: [],
            unfoldBureau: [],
            bindData: {
                binding_reward: 1000,
                days: 365,
                type: 1
            }
        };
        // bureauDetails: false,
        // detailsData: {}
    },
    created() {
        this.getData();
    },
    components: {
        // AdminLabel,
        NotFound
    },
    methods: {
        // 获取 easy-mock 的模拟数据
        getData() {
            this.query.tag_list = this.selectTags.toString();
            // this.query.tag_list = '';
            fetchData(this.query, "/distribution/v1/listUsers", "GET").then(
                (res) => {
                    this.bureauData = res.data.list;
                    this.pageTotal = res.data.count || 0;
                }
            );
            fetchData({ page: 1, limit: 999999 }, "/bureau/v1/listHelper", "GET").then(
                (res) => {
                    this.helperList = res.data.list;
                }
            );
            fetchData({ page: 1, limit: 999999 }, "/bureau/v1/listBureau", "GET").then(
                (res) => {
                    console.log(res);
                    this.bureauList = res.data.list;
                }
            );
            // fetchData({ page:1, limit: 999999, category: 'bureau' }, "/bureau/v1.2/listTags", "GET").then(
            //     (res) => {
            //         this.bureauTags = res.data.list;
            //     }
            // );
        },
        // 经销人详情
        handleDetails(row) {
            console.log(row);
            this.bureauDetails = true;
            this.detailsData = row;
        },
        // 触发搜索按钮
        handleSearch() {
            this.getData();
        },
        // 删除操作
        handleDelete(index, id) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning",
            })
                .then(() => {
                    fetchData(
                        { distributor_user_id: id },
                        "/distribution/v1/deleteUser",
                        "GET"
                    ).then(() => {
                        this.$message.success("删除成功");
                        this.getData();
                        // this.bureauData.splice(index, 1);
                    });
                })
                .catch(() => { });
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAllSelection() {
            const length = this.multipleSelection.length;
            let str = "";
            this.delList = this.delList.concat(this.multipleSelection);
            for (let i = 0; i < length; i++) {
                str += this.multipleSelection[i].name + " ";
            }
            this.$message.error(`删除了${str}`);
            this.multipleSelection = [];
        },
        // 添加操作
        bureauAdd() {
            this.addVisible = true;
        },
        // 编辑操作
        handleEdit(index, row) {
            this.idx = index;
            this.form = row;
            this.editVisible = true;
        },
        // 保存编辑
        saveEdit() {
            // this.$set(this.bureauData, this.idx, this.form);
            // this.form.avatar_id = this.imageId;
            fetchData(this.form, "/distribution/v1/editUser", "POST").then(() => {
                this.$message.success(`修改第 ${this.idx + 1} 行成功`);
                this.editVisible = false;
            });
        },
        // 保存添加
        saveAdd() {
            this.addData.avatar_id = this.imageId;
            fetchData(this.addData, "/distribution/v1/addUser", "POST").then(
                () => {
                    this.addVisible = false;
                    this.addData = {};
                    this.getData();
                }
            );
        },
        //绑定下级操作
        saveBindingtheSub() {
            fetchData(
                {
                    sub_bureau_id: this.bindData.sub_bureau_id,
                    distributor_id: this.theDealer,
                    binding_reward: this.bindData.binding_reward,
                    days: this.bindData.days,
                    type: this.bindData.type
                },
                "/distribution/v1/bindSubBureau",
                "POST"
            ).then(() => {
                this.$message.success(`绑定成功`);
                this.bindData = {
                    binding_reward: 1000,
                    days: 365
                };
                this.selectSubordinate = false;
                this.getData();
            });
        },
        // 分页导航
        handlePageChange(val) {
            // this.$set(this.query, "page", val);
            this.query.page = val;
            this.getData();
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.imageId = res.data.id;
        },
        editAvatarSuccess(res, file) {
            this.form.avatar_url = URL.createObjectURL(file.raw);
            this.form.avatar_id = res.data.id;
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error("上传头像图片只能是 JPG 格式!");
            // }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            // return isJPG && isLt2M;
            return isLt2M;
        },
        beforeBureau() {
            if (this.theDealer == 0) {
                this.$message({
                    type: "warning",
                    message: "请选择经销人",
                });
                return false;
            }
            return true;
        },
        bindingHelper(id) {
            this.theDealer = id === 0 ? 0 : id;
            if (this.beforeBureau() === true) {
                this.selectHelper = true;
            }
        },
        // 绑定下级
        bindingSubordinate(id) {
            this.theDealer = id === 0 ? 0 : id;
            if (this.beforeBureau() === true) {
                this.selectSubordinate = true;
            }
        },
        subBureauChange() {
            // if (this.theDealer === val) {
            //     this.$message({
            //         type: "warning",
            //         message: "不能绑定自己",
            //     });
            //     this.theSubordinate = "";
            //     return false;
            // }
        },
        handleCurrentChange(row) {
            try {
                this.theDealer = row.id;
            } catch (error) {
                console.log(error);
            }
        },
        handleBureauChange(row) {
            try {
                this.renewGoodid = row.id;
            } catch (error) {
                console.log(error);
            }
        },
        showGroupsInbureau(id) {
            fetchData(
                { bureau_id: id },
                "/admin/v1/listBureauInfo",
                "GET"
            ).then((res) => {
                this.groupsInbureau = res.data;
            });
        },
        showHelpersInbureau(id) {
            fetchData(
                { limit: 999999, bureau_id: id },
                "/bureau/v1/getHelperByBureauId",
                "GET"
            ).then((res) => {
                this.helpersInbureau = res.list;
            });
        },
        showSubBureau(id) {
            fetchData({ distributor_id: id }, "/distribution/v1/listSubAccount", "GET").then(
                (res) => {
                    this.subsInBureauList = res.data;
                }
            );
        },
        showGoodsInbureau(id = "") {
            if (id !== "") this.theDealer = id;
            fetchData(
                {
                    page: this.goodsPage.page,
                    limit: this.goodsPage.limit,
                    bureau_id: this.theDealer,
                },
                "/admin/v1/listBureauGoods",
                "GET"
            ).then((res) => {
                this.goodsPage.count = res.data.count;
                this.goodsInbureau = res.data.list;
            });
        },
        buyGoods(id, goodsType) {
            this.theDealer = id;
            this.theGoodsType = goodsType;
            fetchData(
                { limit: 999999, page: 1, goods_category: goodsType },
                "/edu/v1/listGoods",
                "GET"
            ).then((res) => {
                this.goodsData = res.data.list;
            });
            this.buyGoodsBox = true;
        },
        handlePageChangeByGoods(val) {
            this.goodsPage.page = val;
            this.showGoodsInbureau();
        },
        // 购买套餐
        buyGoodsSave() {
            this.buyGoodsData.bureau_id = this.theDealer;
            fetchData(
                this.buyGoodsData,
                "/admin/v1/bureauBuyGoods",
                "GET"
            ).then((res) => {
                this.buyGoodsBox = this.bureauDetails = false;
                this.affirmOrder = true;
                this.orderId = res.data.order_id;
                this.orderData.goodsname = this.buyGoodsData.name;
                this.orderData.member_count = this.buyGoodsData.member_count;
                this.orderData.price = this.buyGoodsData.price;
                this.orderData.days = this.buyGoodsData.days;
                this.orderData.hours = this.buyGoodsData.hours;
                this.orderData.lesson_fee = this.buyGoodsData.lesson_fee;
                this.getData();
            });
        },
        // 续费套餐
        renewGoods(id) {
            this.renewGoodsVal = true;
            this.renewGoodsData.resource_id = id;
        },
        renewGoodsSave() {
            this.renewGoodsData.bureau_id = this.theDealer;
            fetchData(
                this.renewGoodsData,
                "/admin/v1/renewBureauGoods",
                "GET"
            ).then(() => {
                this.renewGoodsVal = false;
                this.renewGoodsData = {};
                this.$message.success(`续费成功`);
                this.showGoodsInbureau();
            });
        },
        // 确认订单
        affirmOrderSave() {
            fetchData(
                { order_id: this.orderId },
                "/admin/v1/applyOrder",
                "GET"
            ).then(() => {
                this.affirmOrder = false;
                this.$message.success(`确认成功`);
            });
        },
        changeGoods(val) {
            this.goodsData.forEach((element) => {
                element.goods_id = element.id;
                element.lesson_fee = 100;
                if (element.id == val) this.buyGoodsData = element;
            });
        },
        changeGoodsType() {
            // this.theGoodsType = val;
            this.buyGoodsData = {};
            this.buyGoods(this.theDealer, this.theGoodsType);
        },
        // 经销人充值
        topUp(id, type = 'balance') {
            let text = { balance: '充值', balance_security_deposit: '缴纳保证金' };
            let money = prompt("请输入" + text[type] + "金额");
            if (!money) {
                this.$message.error("请输入金额");
                return false;
            }
            fetchData(
                { bureau_id: id, money: money, category: type },
                "/admin/v1/addCredit",
                "GET"
            ).then((res) => {
                if (confirm("确实" + text[type] + money + "元吗？")) {
                    fetchData(
                        { order_id: res.data },
                        "/admin/v1/applyCreditOrder",
                        "GET"
                    ).then(() => {
                        this.$message.success(text[type] + `成功`);
                        this.bureauDetails = false;
                        this.getData();
                    });
                }
            });
        },
        goTo(url, params) {
            this.$router.push({ path: "/", name: url, params: params });
        },
        // 点击经销人时
        checkBureau(id) {
            let index = this.unfoldBureau.indexOf(id);
            if (-1 === index) {
                this.unfoldBureau.push(id);
            } else {
                this.unfoldBureau.splice(index, 1);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.bureau-box {
    $time: 0.5s;

    .topPickBox {
        box-shadow: 0px 0px 0px 0px #ccc;
        margin-bottom: 0px;
        cursor: pointer;
        border-radius: 6px;
        transition: box-shadow $time, margin-bottom $time;

        .bureau-avatar {
            width: 65%;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;

            img {
                width: 100%;
                border-radius: 50%;
                vertical-align: middle;
                aspect-ratio: 1/1;
                display: inline-block;
            }

            &::before {
                content: '';
                width: 0;
                height: 100%;
                display: inline-block;
                position: relative;
                vertical-align: middle;
            }
        }
    }

    .conPickBox {
        max-height: 0px;
        opacity: 0;
        transition: max-height $time, opacity $time;

        .pickBox {
            .theTag {
                $color: #ecf5ff;
                border-bottom: 1px solid #b3d8ff;

                span {
                    font-size: 12px;
                    display: inline-block;
                    background-color: $color;
                    color: #409EFF;
                    position: relative;
                    height: 18px;
                    left: 18px;

                    &::before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        left: -18px;
                        border: 9px solid transparent;
                        border-right-color: $color;
                        border-bottom-color: $color;
                    }

                    &::after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        border: 9px solid transparent;
                        border-left-color: $color;
                        border-bottom-color: $color;
                    }
                }
            }

            .content {
                text-align: right;
            }
        }
    }

    .checked {
        box-shadow: 0px 4px 5px 0px #ccc;
        margin-bottom: 20px;

        &+.conPickBox {
            max-height: 230px;
            opacity: 1;
        }
    }

    .content {
        overflow: hidden;

        p {
            i {
                margin-right: 8px;
            }

            padding-left: 18px;
            margin-bottom: 3%;
            overflow: hidden;
            text-overflow:ellipsis; //溢出用省略号显示
            white-space:nowrap; //溢出不换行
        }

        .bureau-name {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 3%;
        }
    }

}


.bureauDetails {
    .title {
        margin-bottom: 1rem;
        background: #ccc;
        color: #fff;
        padding: 10px;
    }

    .label {
        line-height: 3rem;
    }

    // .text {

    // }
}

.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

::v-deep .el-upload--text {
    width: 178px;
}
</style>
