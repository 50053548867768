<template>
    <div>
        <!-- 编辑弹出框 -->
        <el-dialog title="创建标签" v-model="$parent.createTagState" width="40%" custom-class="dialog-box">
            <el-form ref="form" :model="form" label-width="80px" style="width: 80%;margin: 0 auto">
                <el-form-item label="活动名称">
                    <el-input v-model="form.name" placeholder="请输入活动名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">立即创建</el-button>
                    <el-button @click="$parent.createTagState = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import {fetchData} from "../../api/api";

export default {
    props: ['actData'],
    name: "activityTag",
    data() {
        return {
            dialogBox: true,
            form: {
                name: ''
            }
        }
    },
    created() {
        console.log(this.actData)
    },
    methods: {
        onSubmit() {
            if (this.form.name === '') {
                this.$message('活动名称必传');
                return false;
            }

            fetchData({
                act_id: this.actData.act_id,
                act_tag_name: this.form.name
            }, "/act/v1/createTag", "POST").then(
                (res) => {

                    this.$emit('returnTag', {
                        act_id: this.actData.act_id,
                        tagData: {tag_id: res.data, tag_name: this.form.name}
                    })
                    this.$parent.createTagState = false;
                }
            );
        }
    }
}
</script>

<style scoped>

</style>
