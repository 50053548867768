<template>
    <div>
        <div class="container">
            <div class="handle-box">
                <el-button icon="el-icon-plus" type="primary" @click="dataDialog = true; operate='add'"
                    >添加标签</el-button
                >
            </div>

            <div class="tagContent" v-for="item in categorys" :key="item">
                <p class="topBox">
                    <span>{{ item.name }}</span>
                </p>
                <p v-if="item.tagList.length === 0" class="notHave">暂无标签</p>
                <el-row class="tagConBox" :gutter="10">
                    <el-col
                        :span="4"
                        v-for="(tag, index) in item.tagList"
                        :key="index"
                    >
                        <div class="tagCon">
                            <div class="con">
                                {{ tag.name }}
                                <span v-if="tag.type === 1" class="tag"
                                    >默认</span
                                >
                            </div>
                            <div class="option">
                                <p @click="clickEdit(tag)">修改</p>
								<el-popconfirm title="确定删除该标签吗？" @confirm="delTag(tag.id)">
									<template #reference>
										<p>删除</p>
									</template>
								</el-popconfirm>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
		<!-- 定义数据 -->
        <el-dialog title="定义数据" v-model="dataDialog" width="30%">
            <el-form ref="form" label-width="150px">
                <el-form-item label="标签名">
                    <el-input
                        type="text"
                        v-model="name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="标签分类" v-if="operate=='add'">
                    <el-select v-model="category" placeholder="请选择">
						<el-option
						v-for="(item,index) in categorys"
						:key="index"
						:label="item.name"
						:value="index"
						>
						</el-option>
					</el-select>
                </el-form-item>
				<el-form-item label="是否为默认标签">
                    <el-radio-group v-model="type">
						<el-radio :label="0">否</el-radio>
						<el-radio :label="1">是</el-radio>
					</el-radio-group>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dataDialog = false">取 消</el-button>
                    <el-button type="primary" @click="saveTag"
                        >确 定</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { fetchData } from "../api/api";

export default {
    name: "tagManage",
    data() {
        return {
            categorys: {
                bureau: {
                    name: "机构",
                    tagList: [],
                },
                goods: {
                    name: "套餐",
                    tagList: [],
                },
                course: {
                    name: "课程",
                    tagList: [],
                },
            },
			dataDialog: false,
			theTagId: 0,
			category: "",
			name: "",
			type: 0,
			operate: 'add'
        };
    },
    created() {
        this.getInfo();
    },
    methods: {
        getInfo() {
			this.dataDialog = false;
            for (const key in this.categorys) {
                if (Object.hasOwnProperty.call(this.categorys, key)) {
                    fetchData(
                        { page: 1, limit: 999999, category: key },
                        "/bureau/v1.2/listTags",
                        "GET"
                    ).then((res) => {
                        this.categorys[key].tagList = res.data.list;
                    });
                }
            }
        },
		clickEdit(tag){
			this.dataDialog = true;
			this.theTagId = tag.id;
			this.operate='edit';
			this.name = tag.name;
			this.type = tag.type;
		},
        editTag() {
            fetchData(
                { id: this.theTagId, name: this.name, type: this.type },
                "/bureau/v1.2/editTag",
                "GET"
            ).then(() => {
				this.$message.success("修改成功");
				this.getInfo();
            });
        },
        delTag(id) {
            fetchData(
                { id: id },
                "/bureau/v1.2/deleteTag",
                "GET"
            ).then(() => {
				this.$message.success("删除成功");
				this.getInfo();
            });
        },
		addTag() {
			fetchData(
                { type: this.type, name: this.name, category: this.category },
                "/bureau/v1.2/addTag",
                "GET"
            ).then(() => {
				this.$message.success("添加成功");
				this.getInfo();
            });
		},
		saveTag() {
			switch (this.operate) {
				case 'add':
					this.addTag();
					break;
				case 'edit':
					this.editTag();
					break;
				default:
					this.$message.warning("无效操作");
					break;
			}
		}
    },
};
</script>
<style lang="scss" scoped>
.tagContent {
    padding: 15px 0px;
    .topBox {
        border-bottom: 1px solid #ddd;
        margin-bottom: 15px;
        span {
            display: inline-block;
            border: 1px solid #ddd;
            padding: 6px 26px;
            font-weight: bold;
            color: #666;
            font-size: 15px;
            border-radius: 8px 8px 0px 0px;
        }
    }
    .notHave {
        text-align: center;
    }
    .tagConBox {
        .tagCon {
            border: 1px solid #ddd;
            border-radius: 5px;
            text-align: center;
            padding: 1px;
            .con {
                position: relative;
                height: 60px;
                line-height: 60px;
                font-size: 20px;
                width: 100%;
                float: left;
                color: #909399;
                border-color: #d3d4d6;
                background-color: #f4f4f5;
                transition: width 0.5s;
                .tag {
                    border: 1px solid #fbc4c4;
                    color: #f56c6c;
                    background-color: #fef0f0;
                    border-radius: 3px;
                    font-size: 12px;
                    position: absolute;
                    line-height: 12px;
                    padding: 1px 3px;
                    top: 2px;
                    right: 2px;
                }
            }
            .option {
                float: right;
                width: 0%;
                overflow: hidden;
                transition: width 0.5s;
                p {
                    cursor: pointer;
                    font-size: 12px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 5px;
                    color: #fff;
                    &:first-child {
                        background-color: #409eff;
                    }
                    &:last-child {
                        background-color: #f56c6c;
                    }
                }
            }
            &:after {
                content: "";
                display: block;
                clear: both;
            }
            &:hover {
                .con {
                    width: 70%;
                }
                .option {
                    width: 30%;
                }
            }
        }
    }
}
</style>