<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 模板资源
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    class="handle-del mr10"
                    @click="saveResourceState = true ;temFieldState = false; saveTextTitle = '添加';timer = new Date().getTime(); rowData = null"
                >添加模板
                </el-button>
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
                <el-table-column label="模板类型">
                    <template #default="scope">{{ scope.row.type_text }}</template>
                </el-table-column>
                <el-table-column prop="javascript_url" label="js加载地址"></el-table-column>
                <el-table-column label="浏览" align="center">
                    <template #default="scope">
                        <el-tag type="success" @click="browseTemBut(scope.row.content)">浏览模板
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column prop="create_time" label="注册时间"></el-table-column>
                <el-table-column label="操作" width="300" align="center">
                    <template #default="scope">
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            @click="handleEdit(scope.$index, scope.row)"
                        >编辑
                        </el-button>
                        <el-button
                            type="text"
                            icon="el-icon-edit"
                            style="color: green"
                            @click="templateDetailEdit(scope.row)"
                        >字段详情
                        </el-button>
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            class="red"
                            @click="handleDelete(scope.row.id,scope.$index)"
                        >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.pageIndex"
                    :page-size="query.pageSize"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 添加模板-->
        <el-dialog :title="saveTextTitle" v-model="saveResourceState" width="30%">
            <SaveResource @templateDetail="templateDetail" @state="state" :rowData="rowData"
                          :key="timer" @getData="getData"></SaveResource>
        </el-dialog>

        <!-- 模板详情-->
        <el-dialog title="添加模板字段" v-model="templateDetailState" width="70%">
            <TemplateDetail :addContent="addContent" :key="timer"></TemplateDetail>
        </el-dialog>

        <!-- 浏览弹窗-->
        <el-dialog
            title="模板内容浏览"
            width="70%"
            v-model="browseState">
            <div v-html="content"></div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="browseState = false">确定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="用户名">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="form.address"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog>

        <TemFieldList v-if="temFieldState" :template_id="template_id" :key="timer"></TemFieldList>
    </div>
</template>

<script>
import SaveResource from "../../components/resource/SaveResource";
import TemplateDetail from "../../components/resource/TemplateDetail";
import TemFieldList from "./TemFieldList";
import {fetchData} from "../../api/api";


export default {
    name: "basetable",
    components: {
        SaveResource, TemplateDetail,TemFieldList
    },
    data() {
        return {
            temType: {video: '视频', image: '图片', text: '文本', question: '题目'},
            saveResourceState: false,
            templateDetailState: false,
            browseState: false,
            query: {
                address: "",
                name: "",
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
            content: '',
            addContent: '',
            saveTextTitle: '添加',
            rowData: null,
            timer: '',
            temFieldState:false,
            template_id:0
        }
    },
    created() {
        this.getData();
    },
    methods: {
        state(val) {
            this.saveResourceState = val;
        },
        templateDetail(message) {
            console.log(message);
            this.templateDetailState = true;
        },
        browseTemBut(content) {
            this.content = content;
            this.browseState = true;
        },
        // 获取 easy-mock 的模拟数据
        getData(message = null) {
            fetchData({page: this.query.pageIndex, limit: this.query.pageSize}, "/admin/v1/listLessonTem", "get").then(
                (res) => {
                    if (res.code === 200) {
                        const _this = this;
                        _this.pageTotal = res.data.count;
                        res.data.list.forEach(item => {
                            let type = item.type;
                            item.type_text = _this.temType[type];
                        })
                        this.tableData = res.data.list;
                        if (message != null && message.val === 'templateDetailState') {
                            this.saveResourceState = false;
                            this.timer = new Date().getTime();
                            // this.addContent = {content: message.content, template_id: message.template_id};
                            // this.templateDetailState = true;

                        }
                    }
                }
            );
        },
        // 删除操作
        handleDelete(id, index) {
            // 二次确认删除
            this.$confirm("确定要删除吗？", "提示", {
                type: "warning"
            })
                .then(() => {
                    this.$message.success("删除成功");
                    fetchData({id: id}, "/admin/v1/delLessonTem", "get").then(
                        (res) => {
                            if (res.code === 200) {
                                this.tableData.splice(index, 1);
                            }
                        }
                    );
                })
                .catch(() => {
                });
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 编辑操作
        handleEdit(index, row) {
            this.timer = new Date().getTime()
            this.idx = index;
            this.rowData = row;
            console.log(row);
            this.saveResourceState = true;
            this.saveTextTitle = '编辑';
        },
        // 保存编辑
        saveEdit() {
            this.editVisible = false;
            this.$message.success(`修改第 ${this.idx + 1} 行成功`);
            this.$set(this.tableData, this.idx, this.form);
        },
        // 分页导航
        handlePageChange(val) {
            this.query.pageIndex = val;
            this.getData();
        },
        //字段详情
        templateDetailEdit(row){
            this.timer = new Date().getTime()
            this.template_id = row.id;
            this.temFieldState = true;
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>
