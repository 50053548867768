<template>
    <div class="main">
        <div class="container">
            <div class="handle-box">
                <el-button icon="el-icon-plus" type="primary" @click="openDialog('add')"
                >创建vip
                </el-button
                >
            </div>
            <div class="main-class">
                <div class="item" v-for="(item, index) in list"
                     :key="index">
                    <div class="cont">
                        <div style="height: 100px">
                            <img style="height: 100px"
                                 :src="item.image_url"
                                 alt="">
                        </div>
                        <div class="label" style="margin-top: 10px;border-top:none">
                            <div>vip名</div>
                            <div>{{ item.vip_name }}</div>
                        </div>
                        <div class="label">
                            <div>多少天</div>
                            <div>{{ item.vip_period }}天</div>
                        </div>
                        <div class="label">
                            <div>原价</div>
                            <div>￥{{ item.origin_price }}</div>
                        </div>
                        <div class="label">
                            <div>最终价格</div>
                            <div style="color: red">￥{{ item.final_price }}</div>
                        </div>
                        <div class="label">
                            <div>是否展示</div>
                            <div v-if="item.can_show">展示</div>
                            <div v-else>隐藏</div>
                        </div>
                        <div class="save-box">
                            <el-button size="mini" type="success" class="but" @click="openActDrawer(item)">绑定活动类型
                            </el-button>
                            <el-button size="mini" type="success" class="but" @click="openDrawer(item)">优惠规则</el-button>
                            <el-button size="mini" type="success" class="but" @click="openDialog(item)">编辑</el-button>
                        </div>
                        <div v-if="item.can_show === 0" class="cover-class"></div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="alertState"
            width="30%"
            :before-close="handleClose">
            <div>
                <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
                    <el-form-item label="vip名">
                        <el-input v-model="formLabelAlign.vip_name" style="width: 50%"></el-input>
                    </el-form-item>
                    <el-form-item label="时长(天)">
                        <el-input v-model="formLabelAlign.vip_period" style="width: 20%"></el-input>
                        天
                    </el-form-item>
                    <el-form-item label="原价">
                        <el-input v-model="formLabelAlign.origin_price" style="width: 20%"></el-input>
                        元
                    </el-form-item>
                    <el-form-item label="最终价格">
                        <el-input v-model="formLabelAlign.final_price" style="width: 20%"></el-input>
                        元
                    </el-form-item>
                    <el-form-item label="vip图片">
                        <el-upload
                            class="avatar-uploader"
                            action="/resource/v1/uploadFile"
                            :headers="headersObj"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                        >
                            <el-image
                                style="height: 100%"
                                v-if="image_url"
                                :src="image_url"
                            ></el-image>
                            <i v-else class="el-icon-plus avatar-uploader-icon" style="margin-top: 5rem"></i>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="详情信息">
                        <div class="detail-msg">
                            <div class="item-class">
                                <div>会员类型</div>
                            </div>
                            <div class="item-class">
                                <div>获客成本</div>
                            </div>
                            <div class="item-class">
                                <div>人数</div>
                            </div>
                        </div>
                        <div class="detail-msg" v-for="(item,index) in extraData" :key="index">
                            <div class="item-class">
                                <el-input v-model="item.type"></el-input>
                            </div>
                            <div class="item-class">
                                <el-input v-model="item.cost"></el-input>
                            </div>
                            <div class="item-class">
                                <el-input v-model="item.people_counting"></el-input>
                            </div>
                        </div>
                        <div class="save-but-class">
                            <div @click="handleAdd">+</div>
                            <div @click="handleMinus">-</div>
                        </div>
                    </el-form-item>

                    <el-form-item label="vip介绍">
                        <el-input type="textarea" v-model="formLabelAlign.vip_intro"></el-input>
                    </el-form-item>
                    <el-form-item label="权重" style="width: 50%">
                        <el-input v-model="formLabelAlign.vip_pos"></el-input>
                    </el-form-item>
                    <el-form-item label="是否展示">
                        <el-radio-group v-model="formLabelAlign.can_show">
                            <el-radio label="1">展示</el-radio>
                            <el-radio label="0">隐藏</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">{{ butText }}</el-button>
                        <el-button @click="cancel">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

        <el-drawer
            title="vip优惠规则"
            v-model="drawerState"
            :direction="direction"
            :before-close="handleClose">
            <div class="rule-box">
                <el-button icon="el-icon-plus" type="primary" @click="addRule('add')">
                    创建优惠规则
                </el-button>
                <div class="card-class" v-for="(item,index) in ruleList" :key="index"
                     @mouseleave="mouseLeave(index,item)">
                    <div v-if="item.state"
                         style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: pink;z-index:2;opacity: 0"
                         @mouseover="mouseOver(index,item)"></div>
                    <el-form :label-position="labelPosition" label-width="100px" :model="formLabelAlign">
                        <el-form-item label="规则名">
                            <el-input v-model="item.rules_name" :disabled="item.state"
                                      style="width: 80%;"></el-input>
                        </el-form-item>
                        <el-form-item label="续费次数">
                            <el-input v-model="item.renew_times" :disabled="item.state"
                                      style="width: 30%;"></el-input>
                            次数
                        </el-form-item>
                        <el-form-item label="原价">
                            <el-input v-model="item.origin_price" :disabled="item.state" style="width: 30%"></el-input>
                            元
                        </el-form-item>
                        <el-form-item label="最终价格">
                            <el-input v-model="item.final_price" :disabled="item.state" style="width: 30%"></el-input>
                            元
                        </el-form-item>
                        <el-form-item label="新用户可用">
                            <el-radio-group v-model="item.is_new" :disabled="item.state">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                    <el-button v-if="item.state === false" class="saveRuleBUt" type="success"
                               @click="onRuleSubmit(item)">保存
                    </el-button>
                </div>
            </div>
        </el-drawer>

        <el-dialog
            v-model="bindState"
            width="30%"
            :before-close="handleClose">
            <div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="绑定资源列表" name="first">
                        <div>
                            <!--                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>-->
                            <div style="margin: 15px 0;"></div>
                            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="(item,index) in actArrList" :label="item.type_name" :key="index">
                                    {{ item.type_name }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-dialog>
    </div>

</template>
<script>


import {fetchData} from "../../api/api";

export default {
    setup() {
    },
    data() {
        return {
            headersObj: {token: JSON.parse(localStorage.getItem('adminInfo')).token},
            image_url: '',
            vip_image_id: 0,
            checkedCities: [],
            checkAll: false,
            isIndeterminate: false,
            activeName: 'first',
            saveState: true,
            alertState: false,
            labelPosition: 'left',
            formLabelAlign: {
                vip_name: '',
                vip_period: '',
                origin_price: '',
                final_price: '',
                vip_intro: '',
                vip_pos: '',
                can_show: '1',
            },
            list: [],
            vip_id: 0,
            butText: '立即创建',
            drawerState: false,
            ruleList: [
                {
                    id: 0,
                    rules_name: '',
                    renew_times: '',
                    origin_price: '',
                    final_price: '',
                    is_new: '1',
                    state: false
                }
            ],
            bindState: false,
            actArrList: [],
            typeArr: [],
            resData: [],
            extraData: [
                {
                    type: '',
                    cost: '',
                    people_counting: ''
                }
            ],
        }
    },
    created() {
        this.getList();
    },
    mounted() {
    },
    methods: {
        handleAdd() {
            let pushObj = {
                type: '',
                cost: '',
                people_counting: ''
            };
            this.extraData = [...this.extraData, pushObj];
        },
        handleMinus() {
            this.extraData.pop()
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;


            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        handleAvatarSuccess(res, file) {
            this.vip_image_id = res.data.id;
            this.image_url = URL.createObjectURL(file.raw);
        },
        handleCheckedCitiesChange(value) {
            // console.log(value)
            // console.log(this.typeArr)
            let value2 = Array.from(this.typeArr);
            // 首先用ES6的set给数组去重：
            const set1 = new Set(value);
            const set2 = new Set(value2);
            // 然后分别取出set1中存在，set2中不存在 和 set2中存在，set1中不存在的值
            const newArr1 = [...set1].filter((item) => {
                return !set2.has(item);
            });

            const newArr2 = [...set2].filter(item => {
                return !set1.has(item);
            });


            const newArr = [...newArr1, ...newArr2];
            // console.log(newArr);
            let indexArr = [];
            newArr.forEach(element => {
                let type_id = this.actArrList.find((item) => item.type_name === element).type_id;
                indexArr.push(type_id);
            })
            console.log(newArr, indexArr);
            console.log(this.resData.find((item) => item.type_id === indexArr[0]));

            if (this.resData.find((item) => item.type_id === indexArr[0]) != undefined) {
                console.log('shanchu')
                let deleteData = this.resData.find((item) => item.type_id === indexArr[0]);
                let params = [
                    {mall_vip_resource_id: deleteData.id},
                    "/mall/v1/deleteVipResource", "GET"
                ];
                fetchData(...params).then(
                    () => {
                        console.log(deleteData.type_name)
                        this.typeArr.splice(this.typeArr.findIndex(item => item === deleteData.type_name), 1)
                        this.resData.splice(this.resData.findIndex(item => item.id === deleteData.id), 1);
                        console.log(this.typeArr, this.resData)
                        this.$message(`删除绑定活动类型【${deleteData.type_name}】`);
                    }
                )
            } else {
                console.log('tianjia')
                let params = [
                    {vip_id: this.vip_id, act_type_id: indexArr[0]},
                    "/mall/v1/createVipResource", "GET"
                ]
                fetchData(...params).then(
                    (res) => {
                        this.typeArr = [...this.typeArr, ...newArr];
                        let id = res.data;
                        // 添加的信息
                        let addActItem = this.actArrList.find((item) => item.type_id === indexArr[0])
                        let addPush = {
                            id: id,
                            type_id: addActItem.type_id,
                            type_name: addActItem.type_name
                        }
                        this.resData = Array.from([...this.resData, addPush]);
                        this.$message(`绑定活动类型【${addActItem.type_name}】成功`);
                    }
                )
            }
        },
        openActDrawer(item) {
            this.vip_id = item.vip_id;
            this.bindState = true;

            let params = [
                {page: 1, limit: 99999},
                "/act/v1/listActTypes", "GET"
            ]
            fetchData(...params).then(
                (res) => {
                    console.log(res.data.list);
                    this.actArrList = res.data.list;

                    let params = [
                        {vip_id: this.vip_id},
                        "/mall/v1/listVipResource", "GET"
                    ];
                    fetchData(...params).then(
                        (res) => {
                            this.resData = Array.from(res.data);
                            let checkedCities = [];
                            res.data.forEach(element => {
                                checkedCities.push(element.type_name)
                            })
                            this.typeArr = checkedCities;
                            this.checkedCities = checkedCities;
                            console.log(this.checkedCities)
                        }
                    )
                    // let namePush = [];
                    // res.data.list.forEach(item => {
                    //     item.state = true;
                    //     namePush.push(item.type_name)
                    // })
                    // // this.checkedCities = namePush;
                    // this.actArrList = namePush;
                    // this.actList = res.data.list;
                    // let params = [
                    //     {vip_id: this.vip_id},
                    //     "/mall/v1/listVipResource", "GET"
                    // ];
                    // fetchData(...params).then(
                    //     (res) => {
                    //         let checkedCities = [];
                    //         res.data.forEach(element =>{
                    //             checkedCities.push(element.type_name)
                    //         })
                    //         this.checkedCities = checkedCities;
                    //         console.log(res)
                    //     }
                    // )
                }
            );
        },
        //添加规则
        addRule() {
            const data = {
                id: 0,
                renew_times: '',
                origin_price: '',
                final_price: '',
                is_new: '1',
                state: false,
            }
            this.ruleList = [data, ...this.ruleList]
        },
        cancel() {
            this.formLabelAlign = {
                vip_name: '',
                vip_period: '',
                origin_price: '',
                final_price: '',
                vip_intro: '',
                vip_pos: '',
                can_show: '1',
            }
        },
        openDialog(item = {}) {
            console.log(item)
            if (item !== 'add') {
                this.vip_id = item.vip_id;
                this.butText = '编辑';
                // delete item.vip_id;
                this.formLabelAlign = {
                    vip_name: item.vip_name,
                    vip_period: item.vip_period,
                    origin_price: item.origin_price,
                    final_price: item.final_price,
                    vip_intro: item.vip_intro,
                    vip_pos: item.vip_pos,
                    can_show: `${item.can_show}`,
                };
                this.image_url = item.image_url;
                this.vip_image_id = item.image_id;
                this.extraData = item.extra_info ? JSON.parse(item.extra_info) :[
                    {
                        type: '',
                        cost: '',
                        people_counting: ''
                    }
                ];
            } else {
                this.butText = '立即创建';
                this.vip_id = 0;
                this.formLabelAlign = {
                    vip_name: '',
                    vip_period: '',
                    origin_price: '',
                    final_price: '',
                    vip_intro: '',
                    vip_pos: '',
                    can_show: `1`,
                };
                this.image_url = '';
                this.vip_image_id = 0;
                this.extraData = [
                    {
                        type: '',
                        cost: '',
                        people_counting: ''
                    }
                ];
            }
            this.alertState = this.alertState === false;
        },
        onSubmit() {
            if (this.vip_id === 0) {
                let params = [
                    {...this.formLabelAlign, vip_image_id: this.vip_image_id, extra_info: JSON.stringify(this.extraData)},
                    "/mall/v1/createVip", "POST"
                ]
                fetchData(...params).then(
                    () => {
                        this.getList();
                        this.alertState = false;
                    }
                );
            } else {
                let vip_id = this.vip_id;
                let params = [
                    {...this.formLabelAlign, vip_image_id: this.vip_image_id, extra_info: JSON.stringify(this.extraData), vip_id},
                    "/mall/v1/editVip", "POST"
                ]
                fetchData(...params).then(
                    () => {
                        this.getList();
                        this.alertState = false;
                    }
                );
            }
        },
        getList() {
            let params = [
                {},
                "/mall/v1/listVips", "GET"
            ]
            fetchData(...params).then(
                (res) => {
                    console.log(res)
                    this.list = res.data;
                }
            );

        },
        onRuleSubmit(item) {
            console.log(this.vip_id, item)

            if (item.id === 0) {
                let params = [
                    {
                        vip_id: this.vip_id,
                        is_new: item.is_new,
                        renew_times: item.renew_times,
                        origin_price: item.origin_price,
                        final_price: item.final_price,
                        rules_name: item.rules_name
                    },
                    '/mall/v1/createVipRules',
                    'POST'
                ];
                fetchData(...params).then(
                    () => {
                        item.vip_id = this.vip_id;
                        this.getRuleList(item);

                        // this.ruleList = res.data;
                    }
                );
            } else {
                let params = [
                    {
                        vip_rules_id: item.id,
                        is_new: item.is_new,
                        renew_times: item.renew_times,
                        origin_price: item.origin_price,
                        final_price: item.final_price,
                        rules_name: item.rules_name
                    },
                    '/mall/v1/editVipRules',
                    'POST'
                ];
                fetchData(...params).then(
                    (res) => {
                        this.getRuleList(item);
                        console.log(res)
                        // this.ruleList = res.data;
                    }
                );
                //
            }
        },
        // 打开弹窗
        openDrawer(item) {
            this.drawerState = true;
            this.vip_id = item.vip_id;
            console.log(item)
            this.getRuleList(item);

        },
        getRuleList(item) {
            let params = [
                {
                    vip_id: item.vip_id,
                },
                '/mall/v1/listVipRules',
                'GET'
            ];
            fetchData(...params).then(
                (res) => {
                    console.log(res)
                    res.data.forEach(element => {
                        element.state = true;
                        element.is_new = `${element.is_new}`
                    })
                    this.ruleList = res.data;
                    console.log(this.ruleList);
                }
            );
        },
        //改变样式
        mouseOver(index, item) {
            if (item.id != 0) {
                this.ruleList[index].state = false;
            }
        },
        mouseLeave(index, item) {
            if (item.id != 0) {
                this.ruleList[index].state = true;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.main-class {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .item {
        flex-basis: 20%;
        padding-right: 20px;
        margin-bottom: 16px;

        .cont {
            padding: 10px;
            background: #f6f6fb;
            border-radius: 10px;
            line-height: 30px;
            font-size: 14px;
            position: relative;

            .label {
                //margin: 0 10px;
                padding: 0 10px;
                background: #FFFFFF;
                display: flex;
                justify-content: space-between;
            }

            .label:not(:last-child) {
                border-top: 1px solid #bcbec2;
            }
        }

        .save-box {
            display: flex;
            justify-content: end;
            padding: 10px 0 0 0;
            position: relative;
            z-index: 10;
        }
    }
}

.cover-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
}

.rule-box {
    margin: 0 20px;

    .card-class {
        padding: 20px;
        background: #f6f6fb;
        border-radius: 10px;
        margin-top: 20px;
        display: flex;
        align-items: end;
        position: relative;

        .saveRuleBUt {
            align-self: end;
            margin-left: auto;
        }
    }

    ::v-deep .el-form-item {
        margin-bottom: 12px !important;
    }

    ::v-deep .el-radio__label {
        color: #606266 !important;
    }

    ::v-deep .is-checked {
        .el-radio__inner {
            border-color: #409EFF !important;
            background: #2d8cf0 !important;
        }
    }

    ::v-deep .el-form-item__content {
        position: relative;
        z-index: 1;
    }

    ::v-deep .el-input__inner {
        border: none;
    }

    ::v-deep .el-input.is-disabled .el-input__inner {
        color: #333333 !important;
    }
}

.detail-msg {
    display: flex;
    text-align: center;
    margin-bottom: 10px;

    .item-class {
        flex: 1;
        margin-right: 10px;
    }
}

.save-but-class {
    display: flex;

    div {
        flex: 1;
        background: #2d8cf0;
        border-radius: 10px;
        font-size: 20px;
        color: #FFFFFF;
        margin: 0 20px;
        text-align: center;
    }
}

</style>
