<template>
  <div>
    <div class="but-class" style="padding: 10px 0; display: flex">
      <el-upload
        class="avatar-uploader2"
        action="/resource/v1/uploadFile"
        :headers="headersObj"
        :show-file-list="false"
        :on-success="actBodySuccess"
        :before-upload="beforeAvatarUpload"
        multiple
        :limit="100"
      >
        <el-button type="primary" style="padding:0 16px" icon="el-icon-share">添加图片</el-button>
      </el-upload>
      <el-button
        type="primary"
        style="margin-left:20px;padding:0 16px"
        icon="el-icon-share"
        @click="addTextContent"
        >添加文本</el-button
      >
    </div>
    <transition-group
      class="drag-sort-box"
      name="sort"
      tag="div"
      @dragover="dragover($event)"
    >
      <div
        :class="[itemClass, 'drag-sort-item']"
        v-for="(item, index) in images"
        :key="item"
        :draggable="true"
        @dragstart="dragstart(item, index)"
        @dragenter="dragenter(item, $event)"
        @dragend="dragend(item, $event)"
        @dragover="dragover($event)"
      >
        <i class="el-icon-error" @click="handleDeleteImg(index, item)"></i>
        <!--<p class="moxsind">{{ index + 1 }}</p> -->
        <!-- <img
        :src="item"
        :class="index == dargIndex ? 'active' : ''"
        @click="handleBigImg(item)"
      /> -->
        <span v-if="item.body_type === 'image'">
          <el-image
            :class="index == dargIndex ? 'active' : ''"
            style="width: 300px"
            :src="item.body_image_url"
            :preview-src-list="srcList"
            @click="handleBigImg(item)"
          >
          </el-image>
        </span>
        <span v-else>
          <el-input type="textarea" v-model="item.body_text"></el-input>
        </span>
      </div>
      <!-- <div class="img-big-class">
      <img :src="imgUrl" alt="" />
    </div> -->
    </transition-group>
  </div>
</template>

<script>
import { ref } from "vue";
import { fetchData } from "../../api/api";
export default {
  name: "DragSort",
  props: {
    imageList: Array, // 传入图片数据
    sortType: {
      // 排序方式
      type: String,
      default: "insert",
    },
    itemClass: String, //自定义类名
  },
  data() {
    return {
      imgUrl: "",
      srcList: [],
      headersObj: {
        token: JSON.parse(localStorage.getItem("adminInfo")).token,
      },
      actBodyObj: {
        id: 0,
        act_id: 0,
        body_type: "",
        body_text: "",
        body_image_url: "",
        body_image_id: 0,
        body_pos: 0,
      },
    };
  },
  watch: {
    imageList(newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
      //   this.images = newValue;
    },
  },
  mounted() {
    console.log(this.imageList);
    // let images = ref([...prop.imageList]);
  },
  setup(prop, ctx) {
    let images = ref([...prop.imageList]);
    // images.value = images.value.map((v, i) => v = v + '?index=' + i);//不重复key
    let dargIndex = ref(-1);

    let oldData = null;
    let newData = null;

    function dragstart(value, index) {
      oldData = value;
      dargIndex.value = index;
    }
    function dragenter(value, e) {
      newData = value;
      e.preventDefault();
    }

    function dragover(e) {
      e.preventDefault();
    }

    function dragend() {
      if (oldData !== newData) {
        let oldIndex = images.value.indexOf(oldData);
        let newIndex = images.value.indexOf(newData);

        if (prop.sortType == "insert") {
          let newItems = [...images.value];
          newItems.splice(oldIndex, 1);
          newItems.splice(newIndex, 0, oldData);
          images.value = [...newItems];
        } else {
          [images.value[oldIndex], images.value[newIndex]] = [
            images.value[newIndex],
            images.value[oldIndex],
          ];
        }

        console.log(images.value);
        ctx.emit("change", images.value);
      }
      dargIndex.value = -1;
    }

    return {
      images,
      dargIndex,

      dragover,
      dragstart,
      dragenter,
      dragend,
    };
  },
  methods: {
    addTextContent() {
      let item = {
        act_id: this.theActId,
        body_image_id: 0,
        body_image_url: "",
        body_pos: 0,
        body_text: "",
        body_type: "text",
        id: 0,
      };
      this.images.unshift(item);
    },
    handleDeleteImg(index, item) {
      console.log();
      this.$emit("delIntroduceItem", { index, data: item });
    },
    handleBigImg(url) {
      //   this.imgUrl = url;
      console.log(url);
      this.srcList[0] = url;
    },
    addTextList(item) {
      console.log(11111);
      console.log(item);
      this.images.unshift(item);
      console.log(this.images);
      // this.images = item;
    },
    deleteTextClick(index) {
      this.images.splice(index, 1);
    },
    bindSortImg(theActId) {
      // fetchData();
      this.images.forEach((val, index) => {
        if (val.id !== 0) {
          let data = {
            body_id: val.id,
            body_pos: index + 1,
            body_text: val.body_text,
            body_type: val.body_text,
          };
          fetchData(data, "/act/v1/editBody", "GET").then(() => {});
        } else {
          let data = {
            act_id: theActId,
            body_type: val.body_type,
            body_text: val.body_text,
            body_pos: index + 1,
            body_image_id:val.body_image_id
          };
          fetchData(data, "/act/v1/createBody", "GET").then((res) => {
            console.log(res.data);
            // val.id = res.data;
            this.images[index] = {
              act_id: theActId,
              body_image_id: val.body_image_id,
              body_image_url: val.body_image_url,
              body_pos: val.body_pos,
              body_text: val.body_text,
              body_type: val.body_type,
              id: res.data,
            };
          });
        }
        if(index +1 === this.images.length){
          this.$message.success('修改成功');
        }
      });
      setTimeout(() => {
        console.log(this.images);
      }, 3000);
    },
    actBodySuccess(data) {
      console.log(data);
      this.actBodyObj.body_image_url = data.data.relativeUrl;
      this.actBodyObj.body_image_id = data.data.id;
      let item = {
        act_id: this.theActId,
        body_image_id: data.data.id,
        body_image_url: data.data.relativeUrl,
        body_pos: 0,
        body_text: "",
        body_type: "image",
        id: 0,
      };
      this.images.unshift(item);
      this.loadingAlert.close();
    },
    beforeAvatarUpload(file) {
      console.log(123);
      if (file.type.indexOf("video") > -1) {
        alert("不可以上传视频封面");
        return;
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      this.loadingAlert = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return isLt2M;
    },
    updateImgList(list){
      this.images = list;
    }
  },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 1;
}
.drag-sort-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.drag-sort-box .drag-sort-item {
  width: 300px;
  height: 200px;
  margin: 2px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
  background: #ccc;
  position: relative;
}

.drag-sort-box .drag-sort-item img {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  position: relative;
}
.drag-sort-box .drag-sort-item .active {
  border-radius: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
}
.moxsind {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dfd6d6;
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  font-size: 50px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
}

.el-icon-error {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 0px;
  color: red;
  font-size: 26px;
}
.img-big-class {
  position: fixed;
  top: 20%;
  left: 20%;
  width: 60%;
  margin: 0 auto;
}

::v-deep .el-image-viewer__canvas img {
  width: auto !important;
}

::v-deep .el-image__inner {
  width: 180% !important;
  margin-left: -6rem;
}

::v-deep .el-textarea__inner {
  height: 200px;
}
</style>

