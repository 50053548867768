<template>
	<div class="floaderBox">
		<p @click="$emit('spread',theKey)" :class="selectedClass" :style="{paddingLeft:(eveal*5)+'px'}">
			<i v-if="data.open" class="folder el-icon el-icon-folder-opened"></i>
			<i v-else class="folder el-icon el-icon-folder"></i>
			<span class="text" :title="data.text">{{ data.text }}</span>
			<i v-if="data.open" class="arrow el-icon el-icon-arrow-down"></i>
			<i v-else class="arrow el-icon el-icon-arrow-right"></i>
			<!-- <i class="arrow el-icon el-icon-loading"></i> -->
		</p>
		<div class="childListBox" v-if="data.open">
			<floader-list
				v-for="(item, index) in data.child"
				:key="index"
				:theKey="theKey +'-'+ index"
				:selectedFloaderKey="selectedFloaderKey"
				:data="item"
				@spread="getChildKey"
			></floader-list>
		</div>
	</div>
</template>

<script>
export default {
    name: "floader-list",
    components: {},
	computed: {
		eveal() {
			return this.theKey.length;
		},
		selectedClass() {
			return this.selectedFloaderKey==this.theKey ? "selectedClass" : "";
		}
	},
    props: ["data","theKey","selectedFloaderKey"],
	emits: ["spread"],
    data() {
        return {
            isShow: undefined,
        };
    },
	created() {
	},
    methods: {
		getChildKey(childKey) {
			this.$emit('spread', childKey);
		}
	},
};
</script>

<style lang="scss" scoped>
	.floaderBox{
		cursor: pointer;
		p {
			padding: 3px 6px;
			font-size: 18px;
			border: 1px solid #fff;
			border-radius: 12px;
			min-width: 150px;
			.folder{}
			.arrow{}
			.text{
				margin: 0 10px;
				max-width: 63%;
				display: inline-block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			&:hover{
				// border: 1px solid;
				background-color: #66b1ffeb;
				color: #fff;
			}
		}
		.selectedClass {
			color: #fff;
			background-color: #409effed;
			border-color: #3a8ee6;
		}
		.childListBox {
			// padding-left: 15px;
		}
	}
//   baudrate = "57600";
</style>