<template>
  <div class="main">
    <div class="container">
      <div class="handle-box">
        <el-button icon="el-icon-plus" type="primary" @click="openDialog('add')"
          >添加教案模板
        </el-button>
      </div>
      <!-- <not-found v-if="bureauData.length === 0" text="机构找不到啦"></not-found> -->
      <not-found
        v-if="templateList.length === 0"
        text="暂时还没有模板啦,快去添加吧！"
      ></not-found>
      <div class="main-class">
        <div
          class="box-name-class"
          v-for="(item, index) in templateList"
          :key="index"
        >
          <div class="border-name-class">
            <div class="title-class"><span></span>默认封面</div>
            <div><img :src="item.act_image_url" alt="" /></div>
          </div>
          <div class="border-name-class">
            <div class="title-class"><span></span>教案名称</div>
            <div>{{ item.act_name }}</div>
          </div>

          <div class="cover-class">
            <el-button
              type="success"
              plain
              size="mini"
              @click="actBodyDialog(item.act_id)"
            >
              教案详情
            </el-button>
            <el-button
              type="success"
              plain
              size="mini"
              @click="openDialog('edit', index, item)"
            >
              编辑
            </el-button>
            <el-popconfirm
              title="确定删除该模板吗？"
              @confirm="delTemplate(item.act_id)"
            >
              <template #reference>
                <el-button type="danger" plain size="mini">删除</el-button>
              </template>
            </el-popconfirm>
          </div>
        </div>
        <div class="after-add"></div>
        <div class="after-add"></div>
      </div>

      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.limit"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <el-dialog
      title="修改模板"
      v-model="dialogBox"
      width="40%"
      custom-class="dialog-box"
    >
      <div class="dialog-box">
        <el-form
          class="form-box"
          ref="form"
          :model="form"
          label-width="170px"
          :hide-required-asterisk="true"
          :status-icon="true"
        >
          <el-form-item label="教案标题">
            <el-input v-model="form.act_name" autosize></el-input>
          </el-form-item>

          <!-- <el-form-item label="教案介绍">
            <el-input type="textarea" v-model="form.act_intro"></el-input>
          </el-form-item>
          <el-form-item label="背景颜色选择">
            <el-color-picker v-model="form.act_theme_color"></el-color-picker>
          </el-form-item> -->
          <!-- <el-form-item label="教案封面">
            <el-upload
              class="avatar-uploader"
              action="/resource/v1/uploadFile"
              :headers="headersObj"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <el-image
                v-if="form.act_image_url"
                :src="form.act_image_url"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item> -->
          <el-form-item label="是否展示这个教案">
            <el-radio v-model="form.act_can_show" :label="1">是</el-radio>
            <el-radio v-model="form.act_can_show" :label="0">否</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogBox = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 活动页面弹出框 -->
    <el-dialog
      title="活动页面"
      v-model="bodyDialog"
      width="40%"
      custom-class="dialog-box"
    >
      <!-- <div style="display: flex">
        <el-upload
          class="avatar-uploader"
          action="/resource/v1/uploadFile"
          :headers="headersObj"
          :show-file-list="false"
          :on-success="handleAvatarSuccess1"
          :before-upload="beforeAvatarUpload1"
          multiple
          :limit="3"
        >
          <el-button type="primary" icon="el-icon-share">添加图片</el-button>

        </el-upload>
        <el-button type="primary" icon="el-icon-share" @click="bindActImg"
          >绑定图片</el-button
        >
      </div>

      <div class="img-list">
        <div v-for="(item, index) in planImgData" :key="index">
          <div class="img-big-class">
            <el-input
              v-model="item.body_pos"
              placeholder="权重"
              style="width: 70px"
            ></el-input>
            <img :src="item.body_image_url" alt="" />
            <span
              class="del-btn el-icon-close"
              @click="delIntroduceItem(index, item.id)"
            ></span>
          </div>
        </div>
      </div> -->
    </el-dialog>

    <!-- 活动页面弹出框 -->
    <div class="alert-class">
      <el-dialog
        title="教案图片"
        v-model="bodyDialog"
        width="80%"
        custom-class="dialog-box"
        :before-close="handleCloseBody"
      >
        <div class="dialog-box bodyDialog">
          <!-- <div
          class="introduce-box"
          v-for="(item, index) in actBodyList"
          :key="index"
        >
          <div v-if="item.body_type === 'image'" class="introduce-item">
            <img :src="item.body_image_url" alt="" />
          </div>
          <div v-else class="introduce-item">
            <el-input
              type="textarea"
              v-model="item.body_text"
              :ref="'input' + index"
              @change="editBody(item.id, 'text', item.body_text)"
              @click="inputClick(index)"
            ></el-input>
          </div>
          <span
            class="del-btn el-icon-close"
            @click="delIntroduceItem(index, item.id)"
          ></span>
          <span
            v-if="index !== 0"
            class="up-btn el-icon-top"
            @click="sortUp(index)"
          ></span>
          <span
            v-if="index !== actBodyList.length - 1"
            class="down-btn el-icon-bottom"
            @click="sortDown(index)"
          ></span>
        </div> -->
          <div class="but-class" style="padding: 10px 0">
            <el-upload
              class="avatar-uploader2"
              action="/resource/v1/uploadFile"
              :headers="headersObj"
              :show-file-list="false"
              :on-success="actBodySuccess"
              :before-upload="beforeAvatarUpload"
              multiple
              :limit="100"
            >
              <el-button type="primary" icon="el-icon-share"
                >添加图片</el-button
              >
            </el-upload>
          </div>
          <div class="dropx" v-if="actBodyState">
            <ImgSort
              :imageList="imageList"
              :sortType="'exchange'"
              :item-class="'citem'"
              @change="sortChange"
              @delIntroduceItem="delIntroduceItem"
            />
          </div>
          <div style="text-align: right; padding: 10px 0">
            <el-button type="primary" icon="el-icon-share" @click="bindSortImg"
              >图片顺序确认</el-button
            >
          </div>

          <div class="emojis-but-class">
            <EmoJis @appendEmoJis="appendEmoJis" />
          </div>
        </div>
      </el-dialog>
    </div>

    <ActivityTag v-if="createTagState" :actData="actData" @returnTag="returnTag"
      >12</ActivityTag
    >
  </div>
</template>
<script>
import { fetchData } from "../../api/api";
import NotFound from "../../components/NotFound.vue";
import DataUtils from "../../utils/dateUtils";
import ActivityTag from "../../components/activity/ActivityTag";
import axios from "axios";
import ImgSort from "../../components/imgSort/ImgSort";

export default {
  components: {
    ImgSort,
    NotFound,
    ActivityTag,
  },
  data() {
    return {
      inactiveValue: 0,
      activeValue: 1,
      is_highlighted: "0",
      value: "1",
      customizeMenu: [
        { field_name: "默认", field_type: "默认", can_empty: "不为空" },
      ],
      query: {
        page: 1,
        limit: 12,
      },
      color1: "#409EFF",
      pageTotal: 1,
      templateList: [],
      dialogBox: false,
      bodyDialog: false,
      dialogTitle: "添加模板",
      form_obj: {
        act_id: 0,
        act_name: "",
        act_type_id: 7,
        act_intro: "教案内容默认",
        act_expired_time: 0,
        act_image_id: 13692,
        act_image_url:
          "https://storage.shubenji.cn/GuoBao/resource/58686acce53ac647.png",
        act_can_show: 1,
        act_pos: 0,
        act_theme_color: "#AE3434",
      },
      form: {},
      theTypeId: 7,
      visitUrl: "",
      headersObj: {
        token: JSON.parse(localStorage.getItem("adminInfo")).token,
      },
      actBodyList: [],
      actBodyObj: {
        id: 0,
        act_id: 0,
        body_type: "",
        body_text: "",
        body_image_url: "",
        body_image_id: 0,
        body_pos: 0,
      },
      actTypes: [
        {
          type_id: 0,
          type_name: "所有类型",
        },
      ],
      typeList: [],
      theActId: 0,
      textIndex: "",
      DataUtils: DataUtils,

      createTagState: false,
      actData: {},
      dialogImageUrl: "",
      dialogVisible: false,
      imageUrl: "",
      planImgData: [],
      loadingAlert: false,
      imageList: [
        "https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500",
        "https://img1.baidu.com/it/u=1407750889,3441968730&fm=253&fmt=auto&app=120&f=JPEG?w=1200&h=799",
        "https://img0.baidu.com/it/u=1721391133,702358773&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=625",
        "https://img1.baidu.com/it/u=3316754777,2519856621&fm=253&fmt=auto&app=138&f=JPEG?w=653&h=500",
        "https://img1.baidu.com/it/u=3851364429,4209170710&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
        "https://img1.baidu.com/it/u=3851364429,4209170710&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
      ],
      actBodyState: false,
      newImageList: [],
    };
  },
  created() {
    // this.$set(123,'aaa','123')
    if (localStorage.getItem("adminInfo") !== null) {
      // 服务端拿的 token 可以从 vuex、localStorage 等地方取
      axios.defaults.headers["token"] = JSON.parse(
        localStorage.getItem("adminInfo")
      ).token;
    } else {
      axios.defaults.headers["token"] = ``;
    }
    this.form = this.form_obj;
    this.getTemplateList(this.theTypeId); //默认99 ppt
  },
  mounted() {
    // this.$set(this.typeList, "type", "type");
  },
  methods: {
    handleSwitch(item) {
      let form_obj = {
        act_id: item.act_id,
        act_name: item.act_name,
        act_type_id: item.act_type_id,
        act_intro: "教案内容默认",
        act_expired_time: 0,
        act_image_id: 13692,
        act_image_url:
          "https://storage.shubenji.cn/GuoBao/resource/58686acce53ac647.png",
        act_can_show: 1,
        act_pos: item.act_pos,
        is_highlighted: item.is_highlighted,
        act_theme_color: "#AE3434",
      };
      // this.form.is_highlighted = value
      fetchData(form_obj, "/act/v1/editAct", "POST").then(() => {
        console.log(item);
        let index = this.templateList.findIndex(
          (value) => value.act_id == item.act_id
        );
        this.templateList[index].is_highlighted = item.is_highlighted;
      });
    },
    addClick() {
      this.customizeMenu = [
        ...this.customizeMenu,
        { field_name: "默认", field_type: "默认", can_empty: "不为空" },
      ];
    },
    minusClick() {
      this.customizeMenu.pop();
    },
    // 标签返回数据
    returnTag(emit) {
      console.log(emit);
      let act_id = emit.act_id;
      this.templateList.forEach((item) => {
        if (item.act_id === act_id) {
          item.tags = [emit.tagData, ...item.tags];
        }
      });
    },
    deleteTag(item, infoItem) {
      fetchData({ act_tag_id: item.tag_id }, "/act/v1/deleteTag", "GET").then(
        () => {
          this.$message("删除成功");
          console.log(infoItem);
          this.templateList.forEach((val) => {
            if (val.act_id === infoItem.act_id) {
              val.tags.forEach((v, k) => {
                if (v.tag_id === item.tag_id) {
                  val.tags.splice(k, 1);
                }
              });
            }
          });
        }
      );
    },
    appendEmoJis(emo) {
      this.actBodyList[this.textIndex].body_text += emo;
      this.editBody(
        this.actBodyList[this.textIndex].id,
        "text",
        this.actBodyList[this.textIndex].body_text
      );
    },
    inputClick(index) {
      this.textIndex = index;
    },
    getTypes() {
      fetchData({ page: 1, limit: 999999 }, "/act/v1/listActTypes", "GET").then(
        (res) => {
          this.typeList = res.data.list;
          res.data.list.forEach((item) => {
            this.actTypes.push({
              type_name: item.type_name,
              type_id: item.type_id,
            });
          });
        }
      );
    },
    getTemplateList(type_id) {
      console.log(type_id);
      let data = this.query;

      if (type_id != 0) {
        data.act_type_id = type_id;
      } else {
        data.act_type_id = undefined;
      }
      fetchData(data, "/act/v1/listActs", "GET").then((res) => {
        this.pageTotal = res.data.count;
        this.templateList = res.data.list;
      });
    },
    //标签列出
    getListTagInfo(info) {
      info.forEach((item) => {
        fetchData({ act_id: item.act_id }, "/act/v1/listTags", "GET").then(
          (res) => {
            item.tags = res.data;
            this.templateList = info;
          }
        );
        this.templateList = info;
      });
    },
    handleClick() {
      this.getTemplateList(this.theTypeId);
    },
    openDialog(type, index, item) {
      this.dialogBox = true;
      this.handleType = type;

      //这里有问题

      switch (type) {
        case "add":
          this.dialogTitle = "添加模板";
          this.visitUrl = "/act/v1/createAct";
          this.form = this.form_obj;
          this.customizeMenu = [
            { field_name: "默认", field_type: "默认", can_empty: "不为空" },
          ];
          break;
        case "edit":
          this.form = this.templateList[index];
          this.dialogTitle = "修改模板";
          this.visitUrl = "/act/v1/editAct";

          this.customizeMenu = JSON.parse(item.act_user_attm);
          this.customizeMenu.forEach((item) => {
            if (item.can_empty === true) {
              item.can_empty = "不为空";
            } else if (item.can_empty === false) {
              item.can_empty = "为空";
            }
          });
          break;
      }
    },
    saveEdit() {
      // 用户自定义附加字段，json格式,  { "field_name":"", "field_type":"", "can_empty": false}
      let data = this.form;
      if (this.form.type_image_id === 0) delete data.type_image_id;

      let fieldState = true;
      console.log(this.customizeMenu);
      this.customizeMenu.forEach((item) => {
        if (item.field_name === "" || item.field_type === "") {
          fieldState = false;
        }
      });

      if (fieldState === false) {
        this.$message.error("自定义报名表单必传");
        return false;
      }

      this.form.act_user_attm = JSON.stringify(this.customizeMenu);
      // if (typeof this.form.act_expired_time !== "number") {
      //     this.form.act_expired_time = this.form.act_expired_time.getTime() / 1000;
      // }
      fetchData(this.form, this.visitUrl, "POST").then(() => {
        this.$message.success("操作成功");
        this.dialogBox = false;
        this.getTemplateList(this.theTypeId);
      });
    },
    delTemplate(id) {
      fetchData({ act_id: id }, "/act/v1/deleteAct", "GET").then(() => {
        this.$message.success("删除成功");
        this.getTemplateList(this.theTypeId);
      });
    },
    handleAvatarSuccess(res) {
      this.form.act_image_id = res.data.id;
      this.form.act_image_url = res.data.relativeUrl;
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeAvatarUpload(file) {
      console.log(123);
      if (file.type.indexOf("video") > -1) {
        alert("不可以上传视频封面");
        return;
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      this.loadingAlert = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // return isLt2M;
    },
    handlePageChange(val) {
      this.query.page = val;
      this.getTemplateList(this.theTypeId);
    },

    actBodyDialog(id) {
      this.bodyDialog = true;
      this.theActId = id;
      fetchData({ act_id: id }, "/act/v1/listBodies", "GET").then((res) => {
        this.actBodyList = res.data;

        console.log(res.data);
        let imageList = [];
        console.log(this.imageList);
        res.data.forEach((item) => {
          imageList = [...imageList, item.body_image_url];
        });
        this.imageList = imageList;
        console.log(this.imageList);
        this.actBodyState = true;
      });
    },
    handleCloseBody() {
      this.bodyDialog = false;
      this.actBodyState = false;
    },
    sortChange(newArr) {
      console.log(newArr);
      this.imageList = newArr;
    },
    bindSortImg() {
      console.log(this.actBodyList);
      let arrLenght = this.imageList.length;
      this.imageList.forEach((item, index) => {
        let id = this.actBodyList.find((val) => val.body_image_url == item).id;

        let data = {
          body_id: id,
          body_pos: index + 1,
        };
        fetchData(data, "/act/v1/editBody", "GET").then(() => {
          console.log(index + 1, arrLenght);
          if (index + 1 === arrLenght) {
            this.$message.success("顺序调整成功");
          }
        });
      });
    },

    delIntroduceItem(data) {
      console.log(data);
      let id = this.actBodyList.find(
        (val) => val.body_image_url === data.url
      ).id;
      let index = data.index;
      fetchData({ body_id: id }, "/act/v1/deleteBody", "GET").then(() => {
        this.$message.success("删除成功");
      });
      this.actBodyList.splice(index, 1);
      // this.imageList.splice(index, 1);
      // console.log(this.imageList)
      // let imageList = this.imageList.splice(index, 1);
      // let
      let imageList = [];
      this.imageList.forEach((item, key) => {
        console.log(item);
        if (index == key) {
          delete this.imageList[key];
        } else {
          imageList = [...imageList, item];
        }
      });
      this.imageList = imageList;
      console.log(imageList);
    },
    sortUp(index) {
      let forVal = [this.actBodyList[index - 1], this.actBodyList[index]];
      forVal.forEach((val, index) => {
        let data = {
          body_id: val.id,
          body_pos: forVal[Math.abs(index - 1)].body_pos,
        };
        fetchData(data, "/act/v1/editBody", "GET").then(() => {});
      });

      let value = this.actBodyList[index];
      this.actBodyList[index] = this.actBodyList[index - 1];
      this.actBodyList[index - 1] = value;
    },
    sortDown(index) {
      let forVal = [this.actBodyList[index + 1], this.actBodyList[index]];
      forVal.forEach((val, index) => {
        let data = {
          body_id: val.id,
          body_pos: forVal[Math.abs(index - 1)].body_pos,
        };
        fetchData(data, "/act/v1/editBody", "GET").then(() => {});
      });

      let value = this.actBodyList[index];
      this.actBodyList[index] = this.actBodyList[index + 1];
      this.actBodyList[index + 1] = value;
    },
    addTextItem() {
      fetchData(
        {
          act_id: this.theActId,
          body_type: "text",
          body_text: "",
          body_pos: this.getBodyPos(),
        },
        "/act/v1/createBody",
        "GET"
      ).then((res) => {
        let value = {
          id: res.data,
          body_type: "text",
          body_text: "",
          body_pos: this.getBodyPos(),
        };
        this.actBodyList.push(value);
      });
    },
    addImgItem(body_image_url) {
      fetchData(
        {
          act_id: this.theActId,
          body_type: "image",
          body_image_id: this.actBodyObj.body_image_id,
          body_pos: this.getBodyPos(),
        },
        "/act/v1/createBody",
        "GET"
      ).then((res) => {
        let value = {
          id: res.data,
          body_type: "image",
          body_image_url: body_image_url,
          body_image_id: this.actBodyObj.body_image_id,
          body_pos: this.getBodyPos(),
        };
        this.actBodyList.push(value);
        this.imageList = [...this.imageList, body_image_url];
        console.log(this.imageList);
        console.log(this.actBodyList);
      });
    },
    getBodyPos() {
      if (this.actBodyList.length === 0) return 1;
      return this.actBodyList[this.actBodyList.length - 1].body_pos + 1;
    },
    actBodySuccess(data) {
      console.log(data);
      // this.actBodyObj.body_image_url = data.data.relativeUrl;
      this.actBodyObj.body_image_id = data.data.id;
      this.addImgItem(data.data.relativeUrl);
      console.log(this.actBodyList.length);
      this.loadingAlert.close();
    },
    editBody(id, type, value = null, pos = 0) {
      console.log(value);
      if (value === null && pos === 0) return;

      let data = {
        body_id: id,
      };
      if (value === null) {
        data.pos = pos;
      }
      if (pos === 0) {
        type === "text"
          ? (data.body_text = value)
          : (data.body_image_id = this.actBodyObj.body_image_id);
      }
      fetchData(data, "/act/v1/editBody", "GET").then(() => {});
    },
    actCreateTag(item) {
      if (item.tags.length >= 2) {
        this.$message.error("活动标签只能添加两个，请先删除");
        return false;
      }
      this.actData = item;
      this.createTagState = true;
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
}

.container {
  .main-class:after {
    content: "";
    width: 120px;
  }

  .main-class {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-content: flex-start;
    width: 100%;

    div[class^="box"] {
      border-radius: 10px;
      background: #f6f6fb;
      padding: 10px 0;
      width: 23%;
      margin: 1%;
      position: relative;
    }

    .box-name-class:after {
      content: "";
      flex: auto;
    }

    .after-add {
      border-radius: 10px;
      padding: 10px 0;
      width: 23%;
      margin: 1%;
      position: relative;
    }

    .box-name-class:hover {
      cursor: pointer;
    }

    .box-name-class {
      div[class^="border"] {
        border-bottom: 1px solid rgba(51, 51, 51, 0.10196078431372549);
      }

      .cover-class {
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
        margin: 10px 10px 0px;
        position: relative;
      }

      .border-name-class,
      .name-class {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 10px;
        background: #ffffff;

        .title-class {
          display: flex;
          align-items: center;

          span {
            align-items: center;
            margin-right: 5px;
            width: 5px;
            height: 5px;
            background: #ff8000;
            opacity: 1;
            border-radius: 2px 0px 2px 0px;
          }
        }

        div:nth-child(2) {
          flex-shrink: 2;
          color: #ff8000;

          img {
            max-width: 150px;
            height: 90px;
            position: relative;
          }
        }
      }
    }
  }
}

.tag-class {
  display: flex;

  color: #ffffff !important;

  span:nth-child(1) {
    margin-right: 10px;
  }

  span {
    background: #c4c4c4;
    padding: 5px 10px;
    border-radius: 10px 0 10px 0;
  }

  i:hover {
    color: red;
  }
}

.the-tabs {
  margin-top: 1.5rem;

  .item {
    margin-bottom: 1.5rem;

    .box-card {
      .img-box {
        overflow: hidden;
        height: 11.5rem;
      }

      .title-box {
        position: relative;

        .title {
          font-size: 20px;
          margin: 1rem 0;
        }

        .btn-box {
          position: absolute;
          opacity: 0;
          right: 0;
          top: 0;
          transition: opacity 0.4s;
        }
      }
    }

    &:hover {
      & .box-card .title-box .btn-box {
        opacity: 1;
      }
    }
  }
}

.dialog-box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}

.dialog-box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(201, 199, 199, 0.2);
  background: #c4c4c4;
}

.dialog-box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(196, 195, 195, 0.2);
  border-radius: 10px;
  background: #ededed;
}

.dialog-box {
  .form-box {
    width: 600px;
  }

  overflow-y: auto;
  max-height: 80vh;
}

.introduce-box {
  position: relative;
  width: 90%;
  margin: 1rem auto;

  .introduce-item {
    padding-top: 5px;
    margin-bottom: 1rem;
  }

  .del-btn,
  .up-btn,
  .down-btn {
    position: absolute;
    cursor: pointer;
    top: -8px;
    right: -8px;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    padding: 3px;
    background-color: #f56c6c;
  }

  .up-btn {
    right: 92%;
    background-color: #409eff;
  }

  .down-btn {
    right: 82%;
    background-color: #409eff;
  }
}

.emojis-but-class {
  margin-left: auto;
  margin-right: 20px;
  position: absolute;
  top: 30%;
  right: -50px;
  width: 30px;
  height: 30px;
}

.bodyDialog {
  .el-button {
    margin-left: 5%;
  }

  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
    overflow: inherit;
  }

  ::v-deep .avatar-uploader2 {
    display: inline-block;
  }
}

.create-tag-alert {
  width: 300px;
  height: 50px;
  //background: #c4c4c4;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
  position: absolute;
  top: 30px;
  z-index: 50;
  //display: none;
}

.customize-menus {
  position: relative;
  padding-bottom: 10px;

  .but-class {
    position: absolute;
    right: -100px;
    top: 0;

    span {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #c4c4c4;
      text-align: center;
      line-height: 24px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.img-big-class {
  display: flex;
  align-items: center;
  margin: 16px 0;
  input {
    width: 70px;
    height: 30px;
  }
  img {
    width: 70%;
    margin-left: 10px;
    border: 2px solid #c4c4c4;
    border-radius: 10px;
  }
}
.img-list {
  overflow-y: scroll;
  height: 500px !important;
}

// position: relative;
// .el-icon-close {
//   align-self: baseline;
//   display: inline-block;
//   width: 30px;
//   height: 30px;
//   background: #f56c6c;
//   border-radius: 50%;
//   position: relative;
//   left: -10px;
//   color: #ffffff;
//   line-height: 30px;
//   text-align: center;
//   // position: absolute;
//   // top: 0;
//   // right: 0;
// }

::v-deep .avatar-uploader2 .el-upload--text {
  width: auto !important;
  height: auto !important;
  overflow: inherit;
}

::v-deep .alert-class .el-dialog {
  height: 90vh !important;
  margin-top: 5vh !important;
}
</style>
