<template>
    <div>
        <div class="container">
            <el-card class="box-card topLessonCard">
                <el-form ref="form" label-width="120px">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="选择课程、课时">
                                <el-select v-model="theCourseNow" placeholder="请选择课程" @change="getLessonList">
                                    <el-option v-for="item in courseList" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-select v-model="theLessonNow" placeholder="请选择课时" @change="changeLessonGetData">
                                    <el-option v-for="item in lessonList" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="可见类型" :label-width="formLabelWidth">
                                <el-radio v-for="item in visibleTypeList" v-model="seeVisibleType" :label="item.value"
                                    :key="item" border>{{ item.label }}</el-radio>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-tabs type="border-card" @tab-click="cutTemplatesTags" v-model="templatesListQuery.typeNow">
                        <el-tab-pane v-for="index in templateTypes" :key="index" :label="index.label"
                            :name="index.value">
                            <div v-infinite-scroll="loadLessonTemplate" infinite-scroll-disabled="disabled"
                                class="templateList" style="">
                                <el-card shadow="hover" v-for="(item, index) in templateList[index.value]" :key="index"
                                    style="margin-bottom: 1rem" class="infinite-list-item">
                                    <div v-html="item.content"></div>
                                    <div class="shadeBox">
                                        <div class="shadeConBox">
                                            <el-button type="primary" class="addTemplateBtn"
                                                @click="selectThisTem(item, index)"><i class="el-icon-plus"></i>选择该模板
                                            </el-button>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>
                <el-col :span="12">
                    <el-card class="box-card">
                        <template #header>
                            <div class="card-header" style="text-align: center;">
                                <span style="float:left;">课时内容</span>
                                <span>{{ stepTextNow }}</span>
                                <el-button class="right" type="success" size="small" @click="saveTemContent()">保存内容
                                </el-button>
                            </div>
                        </template>
                        <div class="temContentBox">
                            <div :id="'temStepBox' + item.step_serial_num" class="temStepBox"
                                v-for="(item, index) in templateContentList" :key="index">
                                <p class="lineBetween"
                                    v-if="item.step_serial_num === addTemSetpNow && addTemItemNow === 0">
                                    <span class="btnBetween">在此处添入</span>
                                </p>
                                <div class="temItemBox" v-for="(temItem, temIndex) in item.temPlateList" :key="temIndex"
                                    :style="{ display: seeVisibleType === 'all' ? 'block' : (temItem.visible_type !== 'all' && seeVisibleType !== temItem.visible_type ? 'none' : 'block') }"
                                    @mouseover="overTemCon(item.step_serial_num, temIndex)" @mouseleave="leaveTemCon">
                                    <el-tooltip content="删除此模块" placement="right-start" effect="dark">
                                        <div class="temItemBtn closeBtn el-icon-close"
                                            @click="delTemContent(item.step_serial_num, temIndex)"></div>
                                    </el-tooltip>
                                    <el-tooltip content="添加到该模块前" placement="right-start" effect="dark">
                                        <div class="temItemBtn addBeforeBtn el-icon-arrow-up"
                                            @click="addBeforeTemContent(item.step_serial_num, temIndex)"></div>
                                    </el-tooltip>
                                    <el-tooltip content="添加到该模块后" placement="right-start" effect="dark">
                                        <div class="temItemBtn addAfterBtn el-icon-arrow-down"
                                            @click="addAfterTemContent(item.step_serial_num, temIndex)"></div>
                                    </el-tooltip>
                                    <!-- <el-tooltip content="添加到下一步" placement="right-start" effect="dark">
                                    <div class="temItemBtn addAfterStepBtn el-icon-bottom"
                                         @click="addAfterSetpContent(item.step_serial_num)"></div>
                                </el-tooltip> -->
                                    <el-tooltip content="修改内容" placement="right-start" effect="dark">
                                        <div class="temItemBtn addAfterStepBtn el-icon-edit"
                                            @click="editLessonTemCon(temItem, index, temIndex)"></div>
                                    </el-tooltip>
                                    <div class="contentBox" v-html="temItem.content"></div>
                                    <p class="lineBetween"
                                        v-if="item.step_serial_num === addTemSetpNow && addTemItemNow === temIndex + 1">
                                        <span class="btnBetween">在此处添入</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card class="box-card">
                        <template #header>
                            <div class="card-header">
                                <span>步骤</span>
                                <span class="setpExplain right">添加到第{{ addTemSetpNow }}步</span>
                            </div>
                        </template>
                        <div class="temStepPuck">
                            <div class="temStepBox" v-for="(item, index) in stepList" :key="index">
                                <el-tooltip :content="item.content" placement="top" effect="dark">
                                    <div class="button">
                                        {{ item.title }}
                                        <span class="skip" @click="scrollSkip(item.serial_num)">跳转到此步</span>
                                        <span class="add"
                                            @click="addTemSetpNow = item.serial_num; addTemSetpIdNow = item.id">添加到此步</span>
                                        <span class="edit" @click="editSkip(item)">编辑</span>
                                        <span class="delete" @click="delSkip(item, index)">删除</span>
                                    </div>
                                </el-tooltip>
                                <div class="line"></div>
                                <span class="el-icon-arrow-down arrows"></span>
                            </div>
                            <div class="temStepBox">
                                <div class="button" @click="openAddStepDialog"><i class="el-icon-plus"></i>添加步骤</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <el-dialog title="自定义您的数据" v-model="templateFieldDialog">
            <el-form>
                <el-form-item v-for="(item, index) in templateFieldList" :key="index" :label="item.title"
                    :label-width="formLabelWidth">
                    <el-input v-if="item.add_type === 'input'" v-model="item.value" autocomplete="off"></el-input>
                    <oss-file v-if="item.add_type === 'upload'" v-model="item.value"></oss-file>
                    <el-input v-else-if="item.add_type === 'textarea'" type="textarea" v-model="item.value"></el-input>
                </el-form-item>
                <el-form-item v-if="isEditTem" label="模板排序" :label-width="formLabelWidth">
                    <el-input v-model="theEditTemData.sort"></el-input>
                </el-form-item>
                <el-form-item label="可见类型" :label-width="formLabelWidth">
                    <el-radio v-for="item in visibleTypeList" v-model="visibleType" :label="item.value" :key="item"
                        border>{{ item.label }}</el-radio>
                </el-form-item>
                <el-form-item style="text-align:right;">
                    <el-button v-if="isEditTem" type="primary" @click="editInTem()">确认修改
                    </el-button>
                    <el-button v-else type="primary" @click="optInTem()">确认加入
                    </el-button>
                    <el-button @click="resetTemplateList()">全部重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="添加步骤" v-model="addStepDialog">
            <el-form label-width="120px">
                <el-form-item label="标题">
                    <el-input v-model="addStepData.title"></el-input>
                </el-form-item>
                <el-form-item label="说明">
                    <el-input type="textarea" v-model="addStepData.content"></el-input>
                </el-form-item>
                <el-form-item style="text-align:right;">
                    <el-button type="primary" @click="addStepDataMethods()">确认添加
                    </el-button>
                    <el-button @click="addStepDialog = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="编辑步骤" v-model="editStepDialog">
            <el-form label-width="120px">
                <el-form-item label="标题">
                    <el-input v-model="editStepData.title"></el-input>
                </el-form-item>
                <el-form-item label="说明">
                    <el-input type="textarea" v-model="editStepData.content"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="editStepData.serial_num"></el-input>
                </el-form-item>
                <el-form-item style="text-align:right;">
                    <el-button type="primary" @click="editStepDataMethods()">确认修改
                    </el-button>
                    <el-button @click="editStepDialog = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../../api/api";
import { loadJs } from "../../utils/loadJavascript";
import OssFile from '../../components/resource/OssFile.vue';
export default {
    name: "createLessonCon",
    data() {
        return {
            template: {
                content:
                    '<div class="imgBoxCard" style="position: relative;background-image:url(\'http://bookperiod.oss-cn-shanghai.aliyuncs.com/test/card.jpg\');background-size: 100%;background-repeat: no-repeat;width: 100%;height: auto;font-size: 100%;aspect-ratio: 16/12;"><p class="textBox1" style="	position: absolute;top: 12%;left: 33%;font-size: 170%;">中秋节快乐</p><p class="textBox2" style="	padding: 0 25%;text-align: center;position: absolute;top: 31%;letter-spacing: 1px;text-indent: 16%;">又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！</p></div>',
            },
            addTemSetpNow: 1,
            addTemSetpIdNow: 0,
            addTemItemNow: 1,
            seeVisibleType: 'all',
            visibleType: 'all',
            visibleTypeList: [
                { label: '所有人可见', value: 'all' },
                { label: '仅学生可见', value: 'only_student' },
                { label: '仅老师可见', value: 'only_teacher' }
            ],
            addStepData: {
                serial_num: 0,
                title: '',
                content: ''
            },
            editStepData: {
                id: '',
                title: '',
                content: '',
                serial_num: ''
            },
            templateTypes: [
                { label: "视频", value: "video" },
                { label: "图片", value: "image" },
                { label: "文字", value: "text" },
                { label: "题目", value: "question" },
            ],
            templateList: {
                video: [],
                image: [],
                text: [],
                question: []
            },
            filevalue: '',
            // templateList: {
            //     video: [
            // {
            //     content:
            //         '<div class="imgBoxCard" style="position: relative;background-image:url(\'http://bookperiod.oss-cn-shanghai.aliyuncs.com/test/card.jpg\');background-size: 100%;background-repeat: no-repeat;width: 100%;height: auto;font-size: 100%;aspect-ratio: 16/12;"><p class="textBox1" style="	position: absolute;top: 12%;left: 33%;font-size: 170%;">中秋节快乐</p><p class="textBox2" style="	padding: 0 25%;text-align: center;position: absolute;top: 31%;letter-spacing: 1px;text-indent: 16%;">又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！</p></div>',
            // 	field: [
            // 		{
            // 			class: 'textBox1',
            // 			title: '标题',
            // 			add_type: 'input',
            // 			value: '中秋节快乐',
            // 			attr: 'innter'
            // 		},
            // 		{
            // 			class: 'textBox2',
            // 			title: '内容',
            // 			add_type: 'textarea',
            // 			value: '又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！',
            // 			attr: 'innter'
            // 		}
            // 	]
            // },
            //         {
            //             content:
            //                 '<div class="imgBoxCard" style="position: relative;background-image:url(\'http://bookperiod.oss-cn-shanghai.aliyuncs.com/test/card.jpg\');background-size: 100%;background-repeat: no-repeat;width: 100%;height: auto;font-size: 100%;aspect-ratio: 16/12;"><p class="textBox1" style="	position: absolute;top: 12%;left: 33%;font-size: 170%;">中秋节不快乐</p><p class="textBox2" style="	padding: 0 25%;text-align: center;position: absolute;top: 31%;letter-spacing: 1px;text-indent: 16%;">又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！</p></div>',
            // 			field: [
            // 				{
            // 					class: 'textBox1',
            // 					title: '标题',
            // 					add_type: 'input',
            // 					value: '中秋节快乐',
            // 					attr: 'innter'
            // 				},
            // 				{
            // 					class: 'textBox2',
            // 					title: '内容',
            // 					add_type: 'textarea',
            // 					value: '又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！',
            // 					attr: 'innter'
            // 				}
            // 			]
            //         },
            //     ],
            // 	image: [
            // 		{
            //             content:
            //                 '<div class="imgBoxCard" style="position: relative;background-image:url(\'http://bookperiod.oss-cn-shanghai.aliyuncs.com/test/card.jpg\');background-size: 100%;background-repeat: no-repeat;width: 100%;height: auto;font-size: 100%;aspect-ratio: 16/12;"><p class="textBox1" style="	position: absolute;top: 12%;left: 33%;font-size: 170%;">中秋节快乐</p><p class="textBox2" style="	padding: 0 25%;text-align: center;position: absolute;top: 31%;letter-spacing: 1px;text-indent: 16%;">又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！</p></div>',
            // 			field: [
            // 				{
            // 					class: 'textBox1',
            // 					title: '标题',
            // 					add_type: 'input',
            // 					value: '中秋节快乐',
            // 					attr: 'innter'
            // 				},
            // 				{
            // 					class: 'textBox2',
            // 					title: '内容',
            // 					add_type: 'textarea',
            // 					value: '又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！',
            // 					attr: 'innter'
            // 				}
            // 			]
            //         },
            // 	]
            // },
            templateFieldDialog: false,
            addStepDialog: false,
            editStepDialog: false,
            formLabelWidth: '120px',
            templateFieldList: [],
            templateContent: '',
            templateId: 0,
            stepList: [
                // {
                // 	serial_num: 1,
                // 	title: '步骤一',
                // 	content: '第一步就是你开始的地方！',
                // },
                // {
                // 	serial_num: 2,
                // 	title: '步骤二',
                // 	content: '第二步就是你开始的地方！',
                // }
            ],
            templateContentList: [
                // {
                // 	step_serial_num: 1,
                // 	temPlateList: [
                // 		{
                // 			content:
                // 				'<div class="imgBoxCard" style="position: relative;background-image:url(\'http://bookperiod.oss-cn-shanghai.aliyuncs.com/test/card.jpg\');background-size: 100%;background-repeat: no-repeat;width: 100%;height: auto;font-size: 100%;aspect-ratio: 16/12;"><p class="textBox1" style="	position: absolute;top: 12%;left: 33%;font-size: 170%;">中秋节快乐</p><p class="textBox2" style="	padding: 0 25%;text-align: center;position: absolute;top: 31%;letter-spacing: 1px;text-indent: 16%;">又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！</p></div>',
                // 		},
                // 		{
                // 			content:
                // 				'<div class="imgBoxCard" style="position: relative;background-image:url(\'http://bookperiod.oss-cn-shanghai.aliyuncs.com/test/card.jpg\');background-size: 100%;background-repeat: no-repeat;width: 100%;height: auto;font-size: 100%;aspect-ratio: 16/12;"><p class="textBox1" style="	position: absolute;top: 12%;left: 33%;font-size: 170%;">中秋节快乐</p><p class="textBox2" style="	padding: 0 25%;text-align: center;position: absolute;top: 31%;letter-spacing: 1px;text-indent: 16%;">又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！</p></div>',
                // 		},
                // 		{
                // 			content:
                // 				'<div class="imgBoxCard" style="position: relative;background-image:url(\'http://bookperiod.oss-cn-shanghai.aliyuncs.com/test/card.jpg\');background-size: 100%;background-repeat: no-repeat;width: 100%;height: auto;font-size: 100%;aspect-ratio: 16/12;"><p class="textBox1" style="	position: absolute;top: 12%;left: 33%;font-size: 170%;">中秋节快乐</p><p class="textBox2" style="	padding: 0 25%;text-align: center;position: absolute;top: 31%;letter-spacing: 1px;text-indent: 16%;">又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！</p></div>',
                // 		}
                // 	]
                // },
                // {
                // 	step_serial_num: 2,
                // 	temPlateList: [
                // 		{
                // 			content:
                // 				'<div class="imgBoxCard" style="position: relative;background-image:url(\'http://bookperiod.oss-cn-shanghai.aliyuncs.com/test/card.jpg\');background-size: 100%;background-repeat: no-repeat;width: 100%;height: auto;font-size: 100%;aspect-ratio: 16/12;"><p class="textBox1" style="	position: absolute;top: 12%;left: 33%;font-size: 170%;">中秋节快乐</p><p class="textBox2" style="	padding: 0 25%;text-align: center;position: absolute;top: 31%;letter-spacing: 1px;text-indent: 16%;">又是一年圆月夜，秋风习习庆佳节，中秋十五悬灯彩，家家户户乐陶陶，平安团圆中秋夜，美滋美味品月饼，祝你健康更幸福，祝你快乐更开心！</p></div>',
                // 		}
                // 	]
                // }
            ],
            stepTextNow: '$_$',


            templatesListQuery: {
                typeNow: 'video',
                video: { page: 1, limit: 10, total: 0 },
                image: { page: 1, limit: 10, total: 0 },
                text: { page: 1, limit: 10, total: 0 },
                question: { page: 1, limit: 10, total: 0 }
            },
            courseList: [],
            lessonList: [],
            theCourseNow: '',
            theLessonNow: '',
            addTemConDataList: [],

            editIndex: 0,
            editTemIndex: 0,
            isEditTem: false,
            theEditTemData: {
                id: 0,
                content: null,
                sort: null,
                visible_type: null
            },
        };
    },
    components: {
        OssFile
    },
    computed: {
    },
    created() {
        this.getLessonTemplates();
        this.getCourseList();
    },
    methods: {
        loadLessonTemplate() {
            let typeNow = this.templatesListQuery.typeNow,
                total = this.templatesListQuery[typeNow].total,
                pageNow = this.templatesListQuery[typeNow].page
            if (pageNow >= total && total !== 0) return false;
            this.getLessonTemplates();
            this.templatesListQuery[typeNow].page++;
            // this.templateList.video.push(this.template);
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        resetTemplateList() {
            this.templateFieldList.forEach(item => {
                item.value = '';
            });
        },
        scrollSkip(index) {
            document.getElementById('temStepBox' + index).scrollIntoView();
        },
        delTemContent(step, index) {
            this.templateContentList.forEach(item => {
                if (item.step_serial_num === step) {
                    if (item.temPlateList[index].id === undefined) {
                        this.addTemConDataList.splice(item.temPlateList[index].dataIndex, 1);
                        item.temPlateList.splice(index, 1);
                    } else {
                        // 接口删除
                        this.$confirm('确认删除已保存模板么？', '删除模板', {
                            confirmButtonText: '确认',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
                            fetchData({ id: item.temPlateList[index].id }, "/admin/v1/delLessonTemCon", "POST").then(() => {
                                item.temPlateList.splice(index, 1);
                                this.$message.success("删除成功");
                            });
                        })
                    }
                }
            });
        },
        addBeforeTemContent(step, index) {
            this.addTemSetpNow = step;
            this.addTemItemNow = index;
        },
        addAfterTemContent(step, index) {
            this.addTemSetpNow = step;
            this.addTemItemNow = index + 1;
        },
        addAfterSetpContent(step) {
            if (step === this.stepList.length) {
                this.$message.error('没有下一步了，请添加步骤！');
                return false;
            }
            this.addTemSetpNow = step + 1;
        },
        selectThisTem(item, index) {
            this.templateFieldList = item.field;
            this.templateContent = item.content;
            this.templateId = item.id;
            this.templateFieldDialog = true;

            if (this.templateFieldList.length === 0) {
                this.getLessonTemFields(item.id, (arr) => {
                    let type = this.templatesListQuery.typeNow;
                    this.templateList[type][index].field = this.templateFieldList = arr;
                });
            }
        },
        optInTem() {
            if (this.isSelectLesson() === false) return;
            if (this.stepList.length == 0) this.$message.error("请先添加步骤");
            this.templateContentList.forEach(item => {
                if (this.addTemSetpNow === item.step_serial_num) {

                    this.templateFieldList.forEach(field => {
                        var reg = '';
                        if (field.attr === 'innter') {
                            reg = new RegExp('<' + '(.*?)' + 'class="' + field.class + '"' + '(.*?)' + '>' + '(.*?)' + '<');
                        } else {
                            reg = new RegExp('<' + '(.*?)' + 'class="' + field.class + '"' + '(.*?)' + field.attr + '="' + '(.*?)' + '"');
                        }

                        var strs = this.templateContent.match(reg);
                        if (strs !== null) {
                            if (field.attr === 'innter') {
                                this.templateContent = this.templateContent.replace(
                                    strs[1] + 'class="' + field.class + '"' + strs[2] + '>' + strs[3] + '<',
                                    strs[1] + 'class="' + field.class + '"' + strs[2] + '>' + field.value + '<',
                                );
                            } else {
                                this.templateContent = this.templateContent.replace(
                                    strs[1] + 'class="' + field.class + '"' + strs[2] + field.attr + '="' + strs[3],
                                    strs[1] + 'class="' + field.class + '"' + strs[2] + field.attr + '="' + field.value,
                                );
                            }
                        }
                    });

                    item.temPlateList.splice(this.addTemItemNow, 0, {
                        dataIndex: this.addTemConDataList.length,
                        content: this.templateContent,
                        visible_type: this.visibleType
                    });
                    this.addTemConDataList.push(
                        {
                            // step_id: item.step_id,
                            step_id: this.addTemSetpIdNow,
                            template_id: this.templateId,
                            content: this.templateContent,
                            sort: this.addTemItemNow,
                            visible_type: this.visibleType
                        }
                    );

                    this.addTemItemNow++;
                    this.templateFieldDialog = false;
                }
            });
        },
        editInTem() {
            this.templateFieldList.forEach(field => {
                if (field.value) {
                    var reg = "";
                    if (field.attr === "innter") {
                        reg = new RegExp('<' + '(.*?)' + 'class="' + field.class + '"' + '(.*?)' + '>' + '(.*?)' + '<');
                    } else {
                        reg = new RegExp('<' + '(.*?)' + 'class="' + field.class + '"' + '(.*?)' + field.attr + '="' + '(.*?)' + '"');
                    }

                    var strs = this.templateContent.match(reg);
                    if (strs !== null) {
                        if (field.attr === 'innter') {
                            this.templateContent = this.templateContent.replace(
                                strs[1] + 'class="' + field.class + '"' + strs[2] + '>' + strs[3] + '<',
                                strs[1] + 'class="' + field.class + '"' + strs[2] + '>' + field.value + '<',
                            );
                        } else {
                            this.templateContent = this.templateContent.replace(
                                strs[1] + 'class="' + field.class + '"' + strs[2] + field.attr + '="' + strs[3],
                                strs[1] + 'class="' + field.class + '"' + strs[2] + field.attr + '="' + field.value,
                            );
                        }
                        // this.templateContent = this.templateContent.replace(
                        //     strs[1] + 'class="' + field.class + '"' + strs[2] + field.attr + '="' + strs[3],
                        //     strs[1] + 'class="' + field.class + '"' + strs[2] + field.attr + '="' + field.value,
                        // );
                    }
                }
            });
            this.theEditTemData.content = this.templateContent;
            this.theEditTemData.visible_type = this.visibleType;
            this.templateContentList[this.editIndex].temPlateList[this.editTemIndex].content = this.templateContent;
            fetchData(this.theEditTemData, "/admin/v1/editLessonTemCon", "POST").then(
                () => {
                    this.templateFieldDialog = this.isEditTem = false;
                    this.$message.success("编辑成功");
                }
            );
        },
        openAddStepDialog() {
            this.addStepDialog = this.isSelectLesson();
        },
        addStepDataMethods() {
            fetchData({
                serial_num: this.stepList.length + 1,
                title: this.addStepData.title,
                content: this.addStepData.content,
                lesson_id: this.theLessonNow
            }, "/admin/v1/addLessonStep", "POST").then(
                () => {
                    this.getLessonStepList();
                    this.templateContentList.push({ step_serial_num: this.stepList.length + 1, temPlateList: [] });
                    this.addStepData = { title: '', content: '' };
                    this.addStepDialog = false;
                }
            );
        },
        editStepDataMethods() {
            fetchData({
                id: this.editStepData.id,
                title: this.editStepData.title,
                content: this.editStepData.content,
                serial_num: this.editStepData.serial_num,
            }, "/admin/v1/editLessonStep", "POST").then(
                () => {
                    this.getLessonStepList();
                    this.templateContentList.push({ step_serial_num: this.stepList.length + 1, temPlateList: [] });
                    this.editStepData = { title: '', content: '', id: '', serial_num: '' };
                    this.editStepDialog = false;
                    this.$message.success("修改成功");
                }
            );
        },
        cutTemplatesTags(tag) {
            this.templatesListQuery.typeNow = tag.props.name;
            this.loadLessonTemplate();
            // this.getLessonTemplates();
        },
        isSelectLesson() {
            if (this.theLessonNow === '') {
                this.$message.error("请先选择课时");
                return false;
            }
            return true;
        },
        overTemCon(stepNum, conNum) {
            this.stepTextNow = "当前第" + stepNum + "步第" + parseInt(conNum + 1) + "块";
        },
        leaveTemCon() {
            this.stepTextNow = "$_$";
        },


        getLessonTemplates() {
            var type = this.templatesListQuery.typeNow,
                page = this.templatesListQuery[type].page,
                limit = this.templatesListQuery[type].limit;
            fetchData({ type: type, page: page, limit: limit }, "/admin/v1/listLessonTem", "GET").then(
                (res) => {
                    let list = res.data.list;
                    this.templatesListQuery[type].total = res.data.total;
                    list.forEach(item => {
                        this.templateList[type].push({
                            id: item.id,
                            content: item.content,
                            field: []
                        });
                    });
                }
            );
        },
        getLessonTemFields(templateId, fun) {
            fetchData({ template_id: templateId }, "/admin/v1/listLessonTemField", "GET").then(
                (res) => {
                    let arr = [];
                    res.data.forEach(item => {
                        let obj = {};
                        obj.class = item.class_name;
                        obj.title = item.title;
                        obj.add_type = item.add_type;
                        obj.value = item.value;
                        obj.attr = item.attr;

                        arr.push(obj);
                    });
                    fun(arr);
                }
            );
        },
        getCourseList() {
            fetchData({ page: 1, limit: 99999 }, "/admin/v1/listCourse", "GET").then(
                (res) => {
                    this.courseList = res.data.list;
                }
            );
        },
        getLessonList() {
            fetchData({ course_id: this.theCourseNow, page: 1, limit: 99999 }, "/edu/v1/listLesson", "GET").then(
                (res) => {
                    this.lessonList = res.list;
                }
            );
        },
        getLessonStepList() {
            fetchData({ lesson_id: this.theLessonNow, page: 1, limit: 99999 }, "/admin/v1/listLessonStep", "GET").then(
                (res) => {
                    this.stepList = res.data.list;
                    this.stepList.forEach((item, key) => {
                        if (key === this.stepList.length - 1) {
                            this.addTemSetpNow = item.serial_num;
                            this.addTemSetpIdNow = item.id;
                        }
                        if (this.templateContentList[key] === undefined) {
                            this.templateContentList.push({
                                step_serial_num: item.serial_num,
                                step_id: item.id,
                                temPlateList: []
                            });
                        }
                    });
                    if (this.stepList.length > 0) {
                        this.getLessonConList(0);
                    }
                }
            );
        },
        getLessonConList(stepKey) {
            if (stepKey > this.stepList.length - 1) return;
            var stepId = this.stepList[stepKey].id;
            // if (this.templateContentList[stepKey].temPlateList.length === 0) {
            fetchData({ step_id: stepId }, "/admin/v1/listLessonTemCon", "GET").then(
                (res) => {
                    this.templateContentList[stepKey].temPlateList = res.data;
                    res.data.forEach((item, index) => {
                        if (this.stepList[stepKey].serial_num == this.addTemSetpNow && index === res.data.length - 1) {
                            this.addTemItemNow = res.data.length;
                        }
                        loadJs(item.js_url);
                    });
                    this.getLessonConList(stepKey + 1);
                }
            );
            // } else {
            //     this.getLessonConList(stepKey+1);
            // }
        },
        changeLessonGetData() {
            this.templateContentList = [];
            this.getLessonStepList();
        },
        saveTemContent() {
            // this.addTemConDataList.forEach((item,index) => {
            if (this.addTemConDataList.length === 0) return;
            fetchData(this.addTemConDataList[0], "/admin/v1/addLessonTemCon", "POST").then(
                () => {
                    if (this.addTemConDataList.length === 1) {
                        this.$message.success("保存成功");
                    }
                    this.addTemConDataList.splice(0, 1);
                    this.saveTemContent();
                }
            );
            // });
        },
        editLessonTemCon(item, index, temIndex) {
            this.editIndex = index;
            this.editTemIndex = temIndex;
            this.templateFieldDialog = this.isEditTem = true;
            this.templateContent = item.content;
            this.getLessonTemFields(item.template_id, (arr) => {
                arr.forEach(t => {
                    t.value = "";
                });
                this.templateFieldList = arr;
            });
            this.theEditTemData.id = item.id;
        },
        editSkip(item) {
            if (this.addTemConDataList.length > 0) {
                this.$message.error("您还有未保存的模板哦！^_^");
                return;
            }
            this.editStepData.serial_num = item.serial_num;
            this.editStepData.title = item.title;
            this.editStepData.content = item.content;
            this.editStepData.id = item.id;
            this.editStepDialog = true;
        },
        delSkip(skip, index) {
            this.$confirm('确认删除步骤<  ' + skip.title + '  >么？ 删除步骤后该步骤下课时内容也将会被删除 ！', '删除步骤', {
                confirmButtonText: '确认删除',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                fetchData({ id: skip.id }, "/admin/v1/delLessonStep", "GET").then(() => {
                    this.templateContentList[index].temPlateList.forEach((item, temConKey) => {
                        this.delTemContent(this.templateContentList[index].step_serial_num, temConKey);
                    });
                    this.stepList.splice(index, 1);
                    this.templateContentList.splice(index, 1);
                    this.templateContentList.forEach((item, temKey) => {
                        item.serial_num = temKey + 1;
                    });
                    this.$message.success("删除成功");
                });
            })
        },

        load() {
            loadJs('http://bookperiod.oss-cn-shanghai.aliyuncs.com/GuoBao/lessonTemResource/js/test.js').then(() => {
                // 加载成功，进行后续操作
            })
        }
    },
};
</script>

<style scoped lang='scss'>
.topLessonCard {
    margin-bottom: 1rem;

    .el-form-item {
        margin-bottom: 0;

        .el-select {
            margin-right: 1rem;
        }
    }
}

.temContentBox {
    height: 67vh;
    overflow: auto;
    scroll-behavior: smooth;

    .temStepBox {
        font-size: 27px;
        border: 3px solid #fff;

        &:hover {
            border: 3px solid rgb(219, 84, 84);
        }

        .temItemBox {
            position: relative;
            border: 3px solid #fff;
            min-height: 160px;

            .temItemBtn {
                position: absolute;
                cursor: pointer;
                border-radius: 50%;
                color: #fff;
                font-size: 20px;
                padding: 2px;
                z-index: 10;
            }

            .contentBox {
                height: 100%;
            }

            &:hover {
                .temItemBtn {
                    z-index: 1;
                    right: 8px;
                    background: #409EFF;
                }

                .closeBtn {
                    top: 10px;
                    background: red;
                }

                .addBeforeBtn {
                    top: 40px;
                }

                .addAfterBtn {
                    top: 70px;
                }

                .addAfterStepBtn {
                    top: 100px;
                }

                border: 3px solid #409EFF;
            }
        }

        .lineBetween {
            position: relative;
            border: 1px dashed;
            margin: 3px 0;
            text-align: center;

            .btnBetween {
                position: absolute;
                z-index: 1;
                font-size: 14px;
                color: #fff;
                background: red;
                border-radius: 7px;
                padding: 3px 7px;
                top: -12px;
                left: 46%;

                .nextStepBtn {
                    position: absolute;
                    z-index: 1;
                    font-size: 14px;
                    color: #fff;
                    background: red;
                    border-radius: 7px;
                    padding: 3px 7px;
                    top: -12px;
                    left: 46%;
                }
            }

        }
    }
}

.temStepPuck {
    height: 67vh;
    overflow: auto;

    .temStepBox {
        text-align: center;

        .button {
            position: relative;
            color: #409eff;
            background-color: #ecf5ff;
            border-radius: 5px;
            padding: 18px;
            cursor: pointer;
            overflow: hidden;

            span {
                position: absolute;
                font-size: 14px;
                width: 50%;
                height: 50%;
                line-height: 200%;
                color: #fff;
                transition: top 0.5s, left 0.5s, right 0.5s, bottom 0.5s;
            }

            .skip {
                top: -50%;
                left: -50%;
                background-color: #909399;
            }

            .add {
                bottom: -50%;
                left: -50%;
                background-color: #67C23A;
            }

            .edit {
                top: -50%;
                right: -50%;
                background-color: #E6A23C;
            }

            .delete {
                bottom: -50%;
                right: -50%;
                background-color: #F56C6C;
            }

            &:hover {
                border: 1px solid #409eff;

                // background-color: #409EFF;
                // color: #fff;
                .skip,
                .add {
                    left: 0;
                }

                .skip,
                .edit {
                    top: 0;
                }

                .add,
                .delete {
                    bottom: 0;
                }

                .edit,
                .delete {
                    right: 0;
                }
            }
        }

        .line {
            border: 1px dashed #409EFF;
            width: 0;
            margin: 0 auto;
            height: 2rem;
        }

        .arrows {
            color: #409EFF;
            font-size: 21px;
            margin-top: -10px;
        }
    }
}

.templateList {
    height: 74vh;
    overflow: auto;

    .infinite-list-item {
        position: relative;
        overflow: hidden;
    }

    .shadeBox {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 0);
        top: 0;
        left: 0;

        .shadeConBox {
            position: relative;
            height: 100%;
            display: none;

            .addTemplateBtn {
                position: absolute;
                top: 40%;
                left: 38%;
                // margin-top: 30%;
            }
        }
    }

    .shadeBox:hover {
        background: rgb(0 0 0 / 40%);

        .shadeConBox {
            display: block;
        }
    }
}

.right {
    float: right;
}



*::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

/*正常情况下滑块的样式*/
*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在该类指向的控件上时滑块的样式*/
*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*鼠标悬浮在滑块上时滑块的样式*/
*::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
}

/*正常时候的主干部分*/
*::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0);
    background-color: white;
}

/*鼠标悬浮在滚动条上的主干部分*/
*::-webkit-scrollbar-track:hover {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.01);
}
</style>
