<template>
  <div class="main">
    <div class="container">
      <div class="handle-box">
        <el-button icon="el-icon-plus" type="primary" @click="openDialog('add')"
          >创建套餐服务
        </el-button>
      </div>
      <div class="main-class">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="cont">
            <div style="height: 100px" v-if="item.imglist.length != 0">
              <img
                style="height: 100px"
                :src="item.imglist[0].image_url"
                alt=""
              />
            </div>
            <div style="height: 100px" v-else>
              <img
                style="height: 100px"
                src="../../assets/img/img.jpg"
                alt=""
              />
            </div>
            <div class="label" style="margin-top: 10px; border-top: none">
              <div>服务标题</div>
              <div>{{ item.title }}</div>
            </div>
            <div class="label">
              <div>子标题</div>
              <div>{{ item.sub_title }}</div>
            </div>
            <div class="label">
              <div>套餐名</div>
              <div>{{ item.service_name }}</div>
            </div>
            <div class="label">
              <div>服务类型</div>
              <div style="color: red" v-if="item.service_type_id === 1">
                后台套餐
              </div>
            </div>
            <div class="save-box">
              <!-- <el-button
                size="mini"
                type="success"
                class="but"
                @click="openActDrawer(item)"
                >绑定活动类型
              </el-button>-->
              <!-- <el-button
                size="mini"
                type="error"
                class="but"
                @click="deleteClick(item)"
                >删除</el-button
              > -->
              <el-button
                size="mini"
                type="success"
                class="but"
                @click="openDialog('edit', item)"
                >编辑</el-button
              >
            </div>
            <div v-if="item.can_show === 0" class="cover-class"></div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.limit"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      v-model="alertState"
      :title="textName + '套餐服务'"
      width="40%"
      :before-close="handleClose"
    >
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        :disabled="disabledState"
      >
        <el-tab-pane :label="textName + '服务'" name="first">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="套餐名称">
              <el-select
                v-model="form.service_id"
                placeholder="请选择套餐"
                @change="selectGoodsChange"
              >
                <el-option
                  v-for="(item, index) in serviceGoodsList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                  >{{ item.name }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="服务标题">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="子标题">
              <el-input v-model="form.sub_title"></el-input>
            </el-form-item>
            <el-form-item label="主内容">
              <el-input
                type="textarea"
                v-model="form.main_content"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="子内容">
              <el-input
                type="textarea"
                v-model="form.sub_content"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="视频">
              <el-upload
                class="avatar-uploader"
                action=""
                list-type="picture-card"
                :show-file-list="false"
                :file-list="videofileList"
                :http-request="Uploadfile"
                :on-preview="handlePictureCardPreview"
                :before-upload="studentWorkBeforeUpload2"
                :on-remove="handleVideoRemove"
              >
                <img
                  v-if="videoImgUrl"
                  :src="videoImgUrl"
                  class="avatar"
                  style="width: 100%; height: 100%"
                />
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="视频" v-if="textName == '编辑'">
              <video style="width: 100%" controls :src="videoUrl"></video>
            </el-form-item>
            <el-form-item label="服务类型">
              <el-radio v-model="form.service_type_id" label="1"
                >后台套餐</el-radio
              >
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">立即创建</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          :label="textName + '图片'"
          name="second"
          :disabled="disabledState"
        >
          <div>
            <el-upload
              class="avatar-uploader"
              action=""
              list-type="picture-card"
              :file-list="studentWorkfileList"
              :http-request="Uploadfile"
              :on-preview="handlePictureCardPreview"
              :before-upload="studentWorkBeforeUpload"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-button type="primary" @click="bindImage" class="bing-img"
              >绑定图片</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="textName + '标签'"
          name="third"
          :disabled="disabledState"
        >
          <el-form ref="form" :model="tagForm" label-width="80px">
            <el-form-item label="标签">
              <el-input v-model="tagForm.tag_name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmitTag"
                >创建标签</el-button
              >
            </el-form-item>
          </el-form>
          <div>
            <span
              v-for="(item, index) in tagArr"
              :key="index"
              style="
                margin-right: 10px;
                background: #666666;
                color: #ffffff;
                padding: 2px 4px;
              "
              >{{ item.tag_name }} <span @click="unBindTag(item)">X</span></span
            >
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import { fetchData } from "../../api/api";
import Qs from "qs";
export default {
  data() {
    return {
      videoImgUrl: "",
      tagArr: [],
      tagForm: {
        tag_name: "",
      },
      disabledState: true,
      headersObj: {
        token: JSON.parse(localStorage.getItem("adminInfo")).token,
      },

      studentWorkfileList: [],
      studentWorkfileList2: [],
      activeName: "first",
      alertState: false,
      form: {
        service_id: "",
        service_type_id: "1",
        title: "",
        sub_title: "",
        main_content: "",
        sub_content: "",
      },
      serviceGoodsList: [],
      list: [],
      service_intro_id: 0,
      textName: "添加",
      deleteData: [],
      videofileList: [],
      videoUrl: "",
      query: {
        page: 1,
        limit: 10,
      },
      pageTotal: 0,
    };
  },
  created() {
    this.getServiceGoodsList();
    this.getListServiceIntro();
  },
  watch: {
    // list(newVal) {
    //   newVal.forEach((item) => {
    //     item.service_name = this.serviceGoodsList.find(
    //       (val) => item.service_id === val.id
    //     ).name;
    //     // this.getListImages(item.id)
    //   });
    // },
  },
  methods: {
    unBindTag(item) {
      let params = [
        { tag_id: item.tag_id, service_intro_id: this.service_intro_id },
        "/edu/v1/unbindServiceTag",
        "POST",
      ];
      fetchData(...params).then((res) => {
        console.log(res);
        let index = this.tagArr.findIndex((val) => item.tag_id === val.tag_id);
        this.tagArr.splice(index, 1);
        // this.tagArr = [
        //   ...this.tagArr,
        //   { tag_id: tag_id, tag_name: this.tagForm.tag_name },
        // ];
      });
    },
    onSubmitTag() {
      // this.tagArr = [...this.tagArr,]
      // if (this.textName == "添加") {
      let params = [this.tagForm, "/edu/v1/createServiceTag", "POST"];
      fetchData(...params).then((res) => {
        // this.serviceGoodsList = res.data.list;
        let tag_id = res.data;
        let params = [
          { tag_id, service_intro_id: this.service_intro_id },
          "/edu/v1/bindServiceTag",
          "POST",
        ];
        fetchData(...params).then((res) => {
          console.log(res);
          this.tagArr = [
            ...this.tagArr,
            { tag_id: tag_id, tag_name: this.tagForm.tag_name },
          ];
        });
      });
      // }
    },
    openDialog(state, item = {}) {
      this.alertState = true;

      if (state == "add") {
        this.textName = "添加";
        this.videoUrl = "";
        this.form = {
          service_id: "",
          service_type_id: "1",
          title: "",
          sub_title: "",
          main_content: "",
          sub_content: "",
        };
        this.disabledState = true;
      } else {
        this.textName = "编辑";
        this.videoUrl = item.video_url;
        this.disabledState = false;
        this.service_intro_id = item.id;
        this.tagArr = item.tag_array;
        console.log(item);
        // console.log
        // this.serviceGoodsList.find((val)=> {
        //   // console.log(val.name)
        //   // console.log(item.service_name)
        //   if(val.name == item.service_name){
        //     console.log(val)
        //   }
        // })
        this.form = {
          service_id: this.serviceGoodsList.find(
            (val) => val.name == item.service_name
          ).name,
          service_type_id: "1",
          title: item.title,
          sub_title: item.sub_title,
          main_content: item.main_content,
          sub_content: item.sub_content,
        };

        if (item.imglist.length != 0) {
          console.log(item.imglist);
          let studentWorkfileList = [];
          item.imglist.forEach((val) => {
            studentWorkfileList.push({
              id: val.image_id,
              name: 123,
              url: val.image_url,
              type: "image",
              rec_id: val.id,
            });
          });

          let studentWorkfileList2 = [];
          item.imglist.forEach((val) => {
            studentWorkfileList2.push({
              id: val.image_id,
              name: 123,
              url: val.image_url,
              type: "image",
              rec_id: val.id,
            });
          });

          console.log(item);
          this.studentWorkfileList = studentWorkfileList;
          this.studentWorkfileList2 = studentWorkfileList2;
        }

        if (item.video_id != "") {
          this.videofileList = [
            {
              id: item.video_id,
              name: 123,
              url: item.video_url,
              type: "video",
              rec_id: 0,
            },
          ];
          console.log(this.videofileList);
        }
      }
    },
    onSubmit() {
      console.log("submit!");
      console.log(this.form.service_id);
      if (this.form.service_id == "") {
        this.$message.error("错了哦，请选择套餐");
        return false;
      }
      if (this.form.service_type_id == "") {
        this.$message.error("错了哦，勾选服务类型");
        return false;
      }
      if (this.form.title == "") {
        this.$message.error("错了哦，请输入服务标题");
        return false;
      }
      if (this.form.sub_title == "") {
        this.$message.error("错了哦，请输入子标题");
        return false;
      }
      if (this.form.main_content == "") {
        this.$message.error("错了哦，请输入主内容");
        return false;
      }
      if (this.form.sub_content == "") {
        this.$message.error("错了哦，请输入子内容");
        return false;
      }
      if (this.textName != "编辑") {
        this.form["video_id"] = this.videofileList[0].id;
        let params = [this.form, "/edu/v1/createServiceIntro", "POST"];
        fetchData(...params).then((res) => {
          console.log(res);
          this.activeName = "second";
          this.service_intro_id = res.data;
          this.getListServiceIntro();
          // this.serviceGoodsList = res.data.list;
        });
      } else {
        this.form["video_id"] = this.videofileList[0].id;
        this.form["service_intro_id"] = this.service_intro_id;
        let params = [this.form, "/edu/v1/editServiceIntro", "POST"];
        fetchData(...params).then((res) => {
          console.log(res);
          // this.activeName = "second";
          this.service_intro_id = res.data;
          this.getListServiceIntro();
          // this.alertState = false;
          // this.serviceGoodsList = res.data.list;
        });
      }
    },
    //套餐列表
    getServiceGoodsList() {
      let params = [
        {
          address: "",
          name: "",
          page: 1,
          limit: 99999,
        },
        "/edu/v1/listGoods",
        "GET",
      ];
      fetchData(...params).then((res) => {
        console.log(res);

        this.serviceGoodsList = res.data.list;
      });
    },
    handlePageChange(val) {
      this.query.page = val;
      this.getListServiceIntro();
    },
    selectGoodsChange() {
      console.log(this.form);
    },
    //列表数据
    getListServiceIntro() {
      let params = [
        { service_type_id: 1, page: this.query.page, limit: this.query.limit },
        "/edu/v1/listServiceIntro",
        "GET",
      ];
      fetchData(...params).then((res) => {
        this.pageTotal = res.data.count;
        res.data.list.forEach((item) => {
          //   let service_name = this.serviceGoodsList.find(
          //     (val) => item.service_id === val.id
          //   );

          //   item.service_name = service_name.name;
          this.getListImages(item, (info) => {
            // console.log(info);
            item.service_name = info.service_name;
            item.imglist = info.imglist;
          });
        });
        //  res.data.list.forEach((item) => {
        //      this.getListImages(item.id);
        //  })
        console.log(res.data.list);
        setTimeout(() => {
          this.list = res.data.list;
        }, 1000);
      });
    },
    getListImages(item, success = () => {}) {
      setTimeout(() => {
        let params = [
          { service_intro_id: item.id },
          "/edu/v1/listImages",
          "GET",
        ];
        fetchData(...params).then((res) => {
          let service_name = this.serviceGoodsList.find(
            (val) => item.service_id === val.id
          );

          let arr = { service_name: service_name.name, imglist: res.data.list };
          // console.log(arr)
          success(arr);
        });
      },500);
    },
    getUUID() {
      return "x-xxx-4xx-yxx-xxxx".replace(/[xy]/g, (c) => {
        return (
          c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8"
        ).toString(16);
      });
    },
    Uploadfile(paramData) {
      if (this.videofileList.length == 2) {
        this.$message.error("只可以上传一次视频");
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      loading.$el.style.fontSize = "30px";
      let file = paramData.file; // 得到文件的内容
      console.log(file);
      // 发送axios请求获取签名
      let params = [{}, "/bureau/v1/upload", "GET"];
      fetchData(...params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          let list = res.data;
          let ossUrl = list.host;
          let accessUrl = list.dir + this.getUUID() + file.name; //设置上传的访问路径
          let sendData = new FormData(); // 上传文件的data参数
          sendData.append("ossaccessKeyId", list.accessid);
          sendData.append("policy", list.policy);
          sendData.append("signature", list.signature);
          sendData.append("key", accessUrl); //上传的文件路径
          sendData.append("success_action_status", 200); // 指定返回的状态码
          sendData.append("file", file);
          let params1 = [sendData, ossUrl, "POST"];
          fetchData(...params1).then(() => {
            let numberValidateForm = accessUrl; //获得到的url需要将其存数据库中
            const request = Qs.stringify({
              resource_url: numberValidateForm,
            });
            console.log(request);
            let params2 = [
              { resource_url: numberValidateForm },
              "/bureau/v1/saveResource",
              "POST",
            ];
            fetchData(...params2).then((res2) => {
              console.log(res2);
              if (res2.code == 200) {
                let resource_id = res2.data.resource_id;
                let url = "";
                let type = "";
                if (paramData.file.type.indexOf("video") > -1) {
                  url = "./img/login-bg.jpg";
                  type = "video";
                } else {
                  url =
                    "https://bookperiod.oss-cn-shanghai.aliyuncs.com/" +
                    numberValidateForm;
                  type = "image";
                }
                if (type === "image") {
                  this.studentWorkfileList.push({
                    id: resource_id,
                    name: 123,
                    url: url,
                    type: type,
                    rec_id: 0,
                  });
                } else {
                  this.videofileList.push({
                    id: resource_id,
                    name: 123,
                    url: url,
                    type: type,
                    rec_id: 0,
                  });
                  this.videoImgUrl =
                    "https://storage.shubenji.cn/GuoBao/resource/58686acce53ac647.png";
                }
                console.log(this.studentWorkfileList);
                // this.studentWorkfileList = this.studentWorkfileList;
                loading.close();
              }
            });
          });
        }
      });
    },
    handlePictureCardPreview(file) {
      console.log(file);
    },
    studentWorkBeforeUpload(file) {
      console.log(file);
    },
    studentWorkBeforeUpload2(file) {
      console.log(file);
      // var fileSize = file.size / 1024 / 1024 < 150;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的格式");
        return false;
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      let index = this.studentWorkfileList.findIndex(
        (item) => item.id === file.id
      );
      if (this.textName == "编辑") {
        let rec_id = this.studentWorkfileList[index].rec_id;
        this.deleteData = [...this.deleteData, rec_id];
      }
      //   this.studentWorkfileList[index].deleteState = true;
      this.studentWorkfileList.splice(index, 1);
      //   console.log(this.studentWorkfileList);
    },
    handleVideoRemove(file, fileList) {
      console.log(fileList);
      let index = this.videofileList.findIndex((item) => item.id === file.id);
      this.videofileList.splice(index, 1);
    },
    //input点击
    bindImage() {
      let arr3 = [];
      if (this.textName == "编辑") {
        console.log(this.studentWorkfileList, this.studentWorkfileList2);
        arr3 = this.studentWorkfileList.filter((v) => {
          return this.studentWorkfileList2.every((e) => e.id != v.id);
        });
        console.log(arr3);
      } else {
        arr3 = this.studentWorkfileList;
      }
      arr3.forEach((item) => {
        console.log(item);
        let params2 = [
          {
            service_intro_id: this.service_intro_id,
            image_id: item.id,
            pos: 99,
          },
          "/edu/v1/createImage",
          "POST",
        ];
        fetchData(...params2).then((res2) => {
          console.log(res2);
          if (this.textName != "编辑") {
            this.activeName = "third";
          }
        });
      });

      if (this.textName == "编辑") {
        if (this.deleteData.length != 0) {
          this.deleteData.forEach((rec_id) => {
            let params2 = [
              {
                id: rec_id,
              },
              "/edu/v1/deleteImage",
              "POST",
            ];
            fetchData(...params2).then((res2) => {
              console.log(res2);
            });
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.main-class {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .item {
    flex-basis: 20%;
    padding-right: 20px;
    margin-bottom: 16px;

    .cont {
      padding: 10px;
      background: #f6f6fb;
      border-radius: 10px;
      line-height: 30px;
      font-size: 14px;
      position: relative;

      .label {
        //margin: 0 10px;
        padding: 0 10px;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
      }

      .label:not(:last-child) {
        border-top: 1px solid #bcbec2;
      }
    }

    .save-box {
      display: flex;
      justify-content: end;
      padding: 10px 0 0 0;
      position: relative;
      z-index: 10;
    }
  }
}

.cover-class {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.25);
}

.rule-box {
  margin: 0 20px;

  .card-class {
    padding: 20px;
    background: #f6f6fb;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    align-items: end;
    position: relative;

    .saveRuleBUt {
      align-self: end;
      margin-left: auto;
    }
  }

  ::v-deep .el-form-item {
    margin-bottom: 12px !important;
  }

  ::v-deep .el-radio__label {
    color: #606266 !important;
  }

  ::v-deep .is-checked {
    .el-radio__inner {
      border-color: #409eff !important;
      background: #2d8cf0 !important;
    }
  }

  ::v-deep .el-form-item__content {
    position: relative;
    z-index: 1;
  }

  ::v-deep .el-input__inner {
    border: none;
  }

  ::v-deep .el-input.is-disabled .el-input__inner {
    color: #333333 !important;
  }
}

.detail-msg {
  display: flex;
  text-align: center;
  margin-bottom: 10px;

  .item-class {
    flex: 1;
    margin-right: 10px;
  }
}

.save-but-class {
  display: flex;

  div {
    flex: 1;
    background: #2d8cf0;
    border-radius: 10px;
    font-size: 20px;
    color: #ffffff;
    margin: 0 20px;
    text-align: center;
  }
}
.avatar-uploader {
  position: relative;
}

.el-upload-list__item {
  position: absolute;
}
.input-box {
  position: absolute;
  left: 0;
  top: 0px;
}
.bing-img {
  margin-top: 50px;
  float: right;
}
::v-deep .el-dialog {
  height: 80% !important;
  overflow-y: scroll;
}
</style>