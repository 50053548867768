<template>
    <!-- <div class="crumbs"> -->
        <!-- <el-breadcrumb separator="/">
          <el-breadcrumb-item>
            <i class="el-icon-lx-cascades"></i> 获客信息
          </el-breadcrumb-item>
        </el-breadcrumb> -->
    <!-- </div> -->
    <div class="container">
        <div class="handle-box">
            <h3 style="color: #333333">获客信息</h3>
        </div>
        <div class="count-box">

            <div v-for="(item, index) in countData" :key="index" class="flex-class">
                <div class="lt">
                    类型名: <span>{{ item.type_name }}</span>
                </div>
                <div class="lt">
                    活动数量: <span>{{ item.act_num }}</span>
                </div>
                <div class="lt">
                    收益: <span>{{ item.money }}元</span>
                </div>
            </div>
        </div>
        <el-table :data="list" border class="table" ref="multipleTable" header-cell-class-name="table-header">
            <el-table-column prop="bureau_name" label="机构名"></el-table-column>
            <el-table-column label="用户头像" :width="100">
                <template #default="scope">
                    <img :src="scope.row.user_image_url" alt=""
                        style="width: 48px; height: 48px; border-radius: 10px;" />
                </template>
            </el-table-column>
            <el-table-column prop="user_name" label="用户名"></el-table-column>
            <el-table-column prop="bureau_phone" label="手机号"></el-table-column>
            <el-table-column prop="money" label="总收款金额"></el-table-column>
            <el-table-column label="是否vip" :width="80">
                <template #default="scope">
                    <el-tag type="success" v-if="scope.row.is_vip == 1">是</el-tag>
                    <el-tag type="warning" v-else>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="vip_start_time" label="会员开始时间"></el-table-column>
            <el-table-column prop="vip_end_time" label="会员结束时间"></el-table-column>
            <el-table-column prop="bureau_address" label="机构地址"></el-table-column>
            <el-table-column label="操作" align="center" :width="250">
                <template #default="scope">
                    <el-button type="primary" size="mini" icon="el-icon-share" @click="handleCheck(scope.row)">查看活动
                    </el-button>
                    <el-button type="primary" size="mini" icon="el-icon-sell" @click="addTimeOFVip(scope.row)">续时会员
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination">
            <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="limit"
                :total="PageTotal" @current-change="handlePageChange"></el-pagination>
        </div>

        <el-drawer title="我的活动" v-model="drawer" :direction="direction" :before-close="handleClose" size="40%">
            <div class="list-scroll">
                <el-table :data="subList" border class="table" ref="multipleTable"
                    header-cell-class-name="table-header">
                    <el-table-column prop="act_name" label="活动名"></el-table-column>
                    <el-table-column prop="user_name" label="活动图片">
                        <template #default="scope">
                            <img :src="scope.row.act_image_url" style="width: 100px" alt="" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="money" label="活动收入"></el-table-column>
                    <el-table-column label="编辑">
                        <template #default="scope">
                            <el-button type="primary" size="mini" icon="el-icon-share"
                                @click="handleCheckInfo(scope.row)">统计数据
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="subPage" :page-size="limit"
                    :total="subPageTotal" @current-change="handleSubPageChange"></el-pagination>
            </div>
        </el-drawer>

        <el-dialog title="数据统计" v-model="dialogVisible" width="30%" :before-close="handleInfoClose">
            <div class="alert-count">
                <div class="item-count">
                    <div class="label">活动名字：</div>
                    <div class="value">{{ templateInfo.act_name }}</div>
                </div>
                <div class="item-count">
                    <div class="label">活动时间：</div>
                    <div class="value">{{ DataUtils.formatDate(templateInfo.act_start_time, 'YY-MM-DD hh:mm') }} 至
                        {{ DataUtils.formatDate(templateInfo.act_expired_time, 'YY-MM-DD hh:mm') }}
                    </div>
                </div>
                <div class="item-count">
                    <div class="label">活动访问人数：</div>
                    <div class="value">{{ templateInfo.act_user_number }}人</div>
                </div>
                <div class="item-count">
                    <div class="label">活动访问次数：</div>
                    <div class="value">{{ templateInfo.act_access_number }}次</div>
                </div>
                <div class="item-count">
                    <div class="label">活动参与数量：</div>
                    <div class="value">{{ templateInfo.act_success_number }}人</div>
                </div>
                <div class="item-count">
                    <div class="label">活动原始价格：</div>
                    <div class="value">{{ templateInfo.act_price }}元</div>
                </div>
                <div class="item-count">
                    <div class="label">活动最终价格：</div>
                    <div class="value">{{ templateInfo.act_final_price }}元</div>
                </div>
                <div class="item-count">
                    <div class="label">活动收入：</div>
                    <div class="value">{{ templateInfo.money }}</div>
                </div>

            </div>
        </el-dialog>
    </div>
</template>

<script>
import { fetchData } from "../../api/api";
import DataUtils from "../../utils/dateUtils"

export default {
    data() {
        return {
            DataUtils: DataUtils,
            list: [],
            drawer: false,
            direction: "rtl",
            subList: [],
            subPage: 1,
            limit: 6,
            subPageTotal: 0,
            subitem: "",
            page: 1,
            PageTotal: 0,
            dialogVisible: false,
            templateInfo: {},
            countData: []
        };
    },
    created() {
        this.getListTemplateTypeUsageData();
        this.getList();
    },
    methods: {
        getList() {
            fetchData(
                { page: this.page, limit: 6 },
                "/wapp/stats/getClients",
                "GET"
            ).then((res) => {
                console.log(res);
                this.list = res.data.list;
                this.PageTotal = res.data.count;
            });
        },
        handleCheck(item) {
            console.log(item);
            this.subitem = item;
            fetchData(
                { user_id: item.user_id, page: this.subPage, limit: this.limit },
                "/wapp/stats/listBureauClientActs",
                "GET"
            ).then((res) => {
                console.log(res);
                this.subList = res.data.list;
                this.subPageTotal = res.data.count;
            });
            this.drawer = true;
        },
        handleClose(done) {
            console.log(done);
            this.drawer = false;
        },
        handleSubPageChange(val) {
            this.subPage = val;
            this.handleCheck(this.subitem);
        },
        handlePageChange(val) {
            this.page = val;
            this.getList();
        },
        //统计数据
        handleCheckInfo(item) {
            console.log(item);
            this.dialogVisible = true;

            fetchData(
                { act_user_id: this.subitem.user_id, act_id: item.act_id, },
                "/wapp/stats/getActInfo",
                "GET"
            ).then((res) => {
                console.log(res.data.list[0]);
                this.templateInfo = res.data.list[0];
            });
        },
        handleInfoClose() {
            this.dialogVisible = false;
        },
        getListTemplateTypeUsageData() {
            fetchData({}, " /wapp/stats/listTemplateTypeUsageData", "GET").then(
                (res) => {
                    console.log(res);
                    this.countData = res.data;
                }
            );
        },
        addTimeOFVip(res) {
            var day = prompt("请输入要添加的天数：");
            if (!day) return;
            fetchData(
                { wapp_user_id: res.user_id, days: day },
                "/wapp/stats/addVipTime",
                "GET"
            ).then(() => {
                this.$message.success('添加成功');
                this.getList();
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 10px;
}

.list-scroll {
    overflow-y: scroll;
    height: calc(100vh - 200px);
}

.count-box {
    display: flex;
    font-size: 16px;
    margin-bottom: 10px;

    .flex-class {
        flex: 1;
        background: #f8f8f8;
        border-radius: 10px;
        padding: 10px;

        .lt {
            line-height: 30px;
            margin-left: 10px;
            color: #666666;
            font-weight: bold;

            span {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    .flex-class:not(:first-child) {
        margin-left: 10px;
    }
}

.alert-count {
    .item-count {
        display: flex;
        margin-top: 10px;

        .label {
            font-weight: bold;
        }
    }
}
</style>
