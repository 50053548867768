<template>
    <div class="obj-item">
        <el-row class="obj-head">
            <el-col :span="2">
                <el-checkbox 
					class="all-check" 
					v-model="checkAll"
					:indeterminate="isIndeterminate"
					@change="checkAllChange"
					></el-checkbox>
            </el-col>
            <el-col :span="22">
                <div class="obj-info">
                    <span class="name">{{ objName }}</span>
                    <span class="intro">{{ objIntro }}</span>
                </div>
            </el-col>
        </el-row>
        <div class="obj-con">
            <div v-for="(item,index) in pathlists" :key="index">
				<div class="item" v-if="isShow(item.id)">
					<el-tooltip
						class="item"
						effect="dark"
						:content="item.intro"
						placement="right"
					>
						<el-checkbox v-model="item.checked" @change="checkCitiesChange(item)">{{ item.path }}</el-checkbox>
					</el-tooltip>
				</div>
            </div>
        </div>
    </div>
</template>
<script>


export default {
	name: "Paths",
	props: ["objId" ,"objName", "objIntro", "pathlists","showType","rolePaths","unBindList"],
	emits: ["getBindList", "getUnBindList"],
	computed: {
		isShow() {
			return (id) => {
				if (this.showType==="all") { return true }
				if (this.showType==="neverHad" && this.rolePaths.indexOf(id) === -1) { return true }
				if (this.showType==="had" && this.rolePaths.indexOf(id) !== -1) { return true }
				return false;
			}
		}
	},
	data() {
		return {
			checked: true,
			checkAll: false,
			isIndeterminate: true
		}
	},
	methods: {
		checkAllChange(val) {
			let ids = [];
			this.pathlists.forEach(item => {
				if ((this.rolePaths.indexOf(item.id) === -1 && val) || this.unBindList.indexOf(item.id) !== -1) {
					ids.push(item.id);
				}
				if (!val) { 
					ids.push(item.id);
				}
			});
			if (val) {
				this.$emit('getBindList', ids, this.objId);
			} else {
				this.$emit('getUnBindList', ids, this.objId);
			}
			this.isIndeterminate = false;
		},
		checkCitiesChange(item) {
			if (item.checked) {
				this.$emit('getBindList', [item.id], this.objId);
			} else {
				this.$emit('getUnBindList', [item.id], this.objId);
			}
			let count = 0;
			this.pathlists.forEach(path => {
				count = path.checked ? count+1 : count;
			});
			this.checkAll = count === this.pathlists.length;
			this.isIndeterminate = count > 0 && count < this.pathlists.length;
		}
	}
};
</script>
<style scoped lang="scss">
.obj-item {
    background-color: #f9f9f9;
    border: 1px solid #66b1ff;
    border-radius: 5px;
    padding: 10px;
    margin-top: 1rem;
    box-shadow: 0px 5px 6px 0px #c0c4cc;
    .all-check {
        line-height: 30px;
    }
    .obj-info {
        border-radius: 5px;
        background: #79bbff;
        font-size: 14px;
        padding: 5px;
        margin-left: 10px;
        .name {
            background-color: #d9ecff;
            color: #409eff;
            padding: 4px 7px;
            border-radius: 5px;
        }
        .intro {
            color: #fff;
            font-size: 12px;
            margin-left: 10px;
        }
    }
    .obj-con {
        .item {
            cursor: pointer;
            padding: 3px 0;
            div {
                display: inline-block;
            }
            .name {
                margin-left: 10px;
            }
        }
    }
}
</style>