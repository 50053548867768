<template>
<div class="mainBox">
    <div class="clickBtn"
        :class="btnIsDown ? 'clickBtnDown' : ''"
        @mousedown="btnIsDown = true"
        @mouseup="btnIsDown = false"
        @click="isShow = true"
    >选择 oss 文件</div>
    <el-input v-model="filePathByInput"></el-input>
    
    <el-dialog title="选择文件" v-model="isShow">
        <div class="fileListBox">
            <el-row :gutter="30" class="fileListRowBox">
                <el-col :span="7">
                    <div class="folderListBox">
                        <floader-list
                            v-for="(item, index) in folderList"
                            :key="index"
							:data="item"
                            :theKey="index"
                            :selectedFloaderKey="theFloaderKey"
                            @spread="floaderSpread"
                        >
                        </floader-list>
                    </div>
                </el-col>
                <el-col :span="17" class="fileListBoxRight">
                    <el-row class="top">
                        <el-col :span="9" >名称</el-col>
                        <el-col :span="8" >修改日期</el-col>
                        <el-col :span="3" >类型</el-col>
                        <el-col :span="4" >大小</el-col>
                    </el-row>
                    <div class="fileBox">
                        <el-row :class="fileNameNow === item.name ? 'selectedClass' : ''" :gutter="0" v-for="(item,index) in fileList" :key="index" @click="pitchOnFile(item.name)">
                            <el-col :span="9" :title="item.name">
                                <i v-if="item.type === 'video'" class="el-icon el-icon-video-camera"></i>
                                <i v-else-if="item.type === 'image'" class="el-icon el-icon-picture-outline"></i>
                                <i v-else-if="item.type === 'text'" class="el-icon el-icon-document"></i>
                                <i v-else class="el-icon el-icon-tickets"></i>
                                <span>{{ item.name }}</span>
                            </el-col>
                            <el-col :span="8" :title="item.lastModified">{{ item.lastModified }}</el-col>
                            <el-col :span="3" :title="item.type">{{ item.type }}</el-col>
                            <el-col :span="4" :title="item.size">{{ item.size }}</el-col> 
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div class="shadeBox" v-show="shadeShow">
                <i class="arrow el-icon el-icon-loading"></i>
            </div>
        </div>
        <div>
            <el-button type="primary" @click="optInTem()">确认选择</el-button>
            <el-button @click="isShow = false; shadeShow = false;">取消</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import FloaderList from "./FloaderList.vue";
import {fetchData} from "../../api/api";

export default {
    name: "oss-file",
    components: {
        FloaderList,
    },
    props: ["modelValue"],
    emits: ["update:modelValue"],
    data() {
        return {
            isShow: false,
            shadeShow: false,
            btnIsDown: false,
            isMore: false, // 是否为多选
            selectFile: "", // 单个文件
            selectFileList: [], // 多个文件

            path: "",
            fileNameNow: "",
            theFloaderKey: "",
            filePathByInput: "",
            pathLog: [], 
            folderList: [],
            // folderList: [
            //     {
            //         open: true,
            //         text: "歌曲合集",
            //         child: [
            //             {
            //                 open: false,
            //                 text: "流行歌曲",
            //                 child: [
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [],
            //                     },
            //                 ],
            //             },
            //             {
            //                 open: false,
            //                 text: "流行歌曲",
            //                 child: [
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [
            //                             {
            //                                 open: false,
            //                                 text: "流行歌曲",
            //                                 child: [
            //                                     {
            //                                         open: false,
            //                                         text: "好听的歌",
            //                                         child: [
            //                                             {
            //                                                 open: false,
            //                                                 text: "流行歌曲",
            //                                                 child: [
            //                                                     {
            //                                                         open: false,
            //                                                         text: "好听的歌",
            //                                                         child: [
            //                                                             {
            //                                                                 open: false,
            //                                                                 text: "流行歌曲",
            //                                                                 child: [
            //                                                                     {
            //                                                                         open: false,
            //                                                                         text: "好听的歌",
            //                                                                         child: [
            //                                                                             {
            //                                                                                 open: false,
            //                                                                                 text: "流行歌曲",
            //                                                                                 child: [
            //                                                                                     {
            //                                                                                         open: false,
            //                                                                                         text: "好听的歌",
            //                                                                                         child: [
            //                                                                                             {
            //                                                                                                 open: false,
            //                                                                                                 text: "流行歌曲",
            //                                                                                                 child: [
            //                                                                                                     {
            //                                                                                                         open: false,
            //                                                                                                         text: "好听的歌",
            //                                                                                                         child: [],
            //                                                                                                     },
            //                                                                                                 ],
            //                                                                                             },
            //                                                                                         ],
            //                                                                                     },
            //                                                                                 ],
            //                                                                             },
            //                                                                         ],
            //                                                                     },
            //                                                                 ],
            //                                                             },
            //                                                         ],
            //                                                     },
            //                                                 ],
            //                                             },
            //                                         ],
            //                                     },
            //                                 ],
            //                             },
            //                         ],
            //                     },
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [],
            //                     },
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [],
            //                     },
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [],
            //                     },
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [],
            //                     },
            //                 ],
            //             },
            //             {
            //                 open: false,
            //                 text: "流行歌曲",
            //                 child: [
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [],
            //                     },
            //                 ],
            //             },
            //         ],
            //     },
            //     {
            //         open: false,
            //         text: "歌曲合集",
            //         files: [
            //             {
            //                 name: '',
            //                 suffix: '',
            //                 path: '',
            //                 type: '',
            //                 size: '',
            //                 lastModified: ''
            //             }
            //         ],
            //         child: [
            //             {
            //                 open: true,
            //                 text: "流行歌曲",
            //                 child: [
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [],
            //                     },
            //                 ],
            //             },
            //             {
            //                 open: true,
            //                 text: "流行歌曲",
            //                 child: [
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [],
            //                     },
            //                 ],
            //             },
            //         ],
            //     },
            //     {
            //         open: false,
            //         text: "歌曲合集",
            //         child: [
            //             {
            //                 open: true,
            //                 text: "流行歌曲",
            //                 child: [
            //                     {
            //                         open: false,
            //                         text: "好听的歌",
            //                         child: [],
            //                     },
            //                 ],
            //             },
            //         ],
            //     },
            // ],
 
            fileList: [
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                //                 {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                //                 {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
                // {
                //     name: "文件名称",
                //     suffix: "mp4",
                //     path: "guobao/video",
                //     type: "image",
                //     size: "1212",
                //     lastModified: "2021/01/02",
                // },
            ]
        };
    },
    created() {
        this.shadeShow = true;
        this.filePathByInput = this.modelValue;
        fetchData({path: "GuoBao"}, "/resource/v1/listOssResource").then(
            (res) => {
                res.data.floaderList.forEach(item => {
                    this.floaderPush(item, this.folderList);
                });
                res.data.fileList.forEach(item=>{
                    this.filePush(item, this.fileList);
                });
                this.shadeShow = false;
            }
        );
    },
    computed: {
        filePath() {
            return this.path + '/' + this.fileNameNow;
        }
    },
    watch: {
        filePathByInput(newV) {
            this.$emit('update:modelValue', newV);
        }
    },
    methods: {
        getList(html) {
            this.templateHtml = html;
        },
        pitchOnFile(name) {
            this.fileNameNow = name;
        },
        floaderSpread(msg) {
            var floaderObj = '',
                splitArr = typeof msg === 'number' ? [msg] : msg.split('-');

            this.path = "GuoBao/";
            this.theFloaderKey = msg;
                
            splitArr.forEach((key,index) => {
                floaderObj = index === 0 ? this.folderList[key] : floaderObj['child'][key];
                floaderObj.open = index === splitArr.length - 1 ? !floaderObj.open : true;
                this.path += index === splitArr.length - 1 ? floaderObj.text : floaderObj.text + '/';
            });
            
            this.shadeShow = true;
            if (this.pathLog.includes(this.path)) {
                this.fileList = floaderObj.files;
                this.shadeShow = false;
            } else {
                fetchData({path: this.path, limit: 900}, "/resource/v1/listOssResource").then(
                    (res) => {
                        res.data.floaderList.forEach(item => {
                            this.floaderPush(item, floaderObj.child);
                        });
                        res.data.fileList.forEach(item=>{
                            this.filePush(item, floaderObj.files);
                        });
                        if (res.data.fileList.length < 1) {
                            this.fileList = floaderObj.files;
                        }
                        this.pathLog.push(this.path);
                        this.shadeShow = false;
                    }
                );
            }

        },
        floaderPush(item, floaderArr) {
            let nameArr = item.name.split("/");
            floaderArr.push({
                open: false,
                text: nameArr[nameArr.length-1],
                child: [],
                files: []
            });
        },
        filePush(item, fileArr) {
            fileArr.push({
                name: item.name + "." + item.suffix,
                suffix: item.suffix,
                path: item.path,
                type: item.type,
                size: item.size,
                lastModified: item.lastModified
            });
            this.fileList = fileArr;
        },
        optInTem() {
            if (this.fileNameNow) {
                this.shadeShow = true;
                fetchData({path: this.filePath}, "/resource/v1/getOssResourceUrl").then(
                    (res) => {
                        this.filePathByInput = res.data;
                        this.shadeShow = this.isShow = false;
                    }
                );
            } else {
                this.isShow = false;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .mainBox {
        line-height: initial;
    }
    .clickBtn {
        width: 100px;
        background: #409EFF;
        font-size: 15px;
        color: rgb(255, 255, 255);
        text-align: center;
        padding: 10px 15px;
        cursor: pointer;
        border-radius: 12px;
        &:hover {
            background: rgb(83, 168, 255);
        }
    }
    .clickBtnDown {
        background: #3a8ee6;
    }
    .fileListBox { 
        position: relative;
        height: 50vh;
        .shadeBox {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0);
            i {
                font-size: 40px;
                position: absolute;
                top: 43%;
                left: 48%;
            }
        }
        .fileListRowBox {
            height: 100%;
            .el-col {
                height: 100%;
                .folderListBox {
                    height: 100%;
                    overflow-y: auto;
                }
            }
            .fileListBoxRight{
                border-left: 1px solid;
                .top {
                    height: auto;
                    .el-col {
                        border-right:1px solid;
                        height: auto;
                    }
                }
                .fileBox {
                    height: 95%;
                    overflow: hidden;
                    overflow-y: auto;
                    .el-row {
                        width: 100%;
                        .el-col {
                            overflow:hidden; //超出的文本隐藏
                            text-overflow:ellipsis; //溢出用省略号显示
                            white-space:nowrap; //溢出不换行
                            .el-icon {
                                margin-right: 5px;
                            }
                        }
                    }
                    .selectedClass {
                        color: #fff;
                        background-color: #409effed;
                        border-color: #3a8ee6;
                    }
                }
                .el-col {
                    padding: 4px 0;
                    padding-left: 10px;
                }
            }
        }
    }
    .fileBox {
        .el-row{
            cursor: pointer;
            border: 1px solid #fff;
            border-radius: 12px;
            font-size: 18px;
            &:hover {
                background-color: #66b1ffeb;
				color: #fff;
            }
        }
    }
    *::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    }
    /*正常情况下滑块的样式*/
    *::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
    }

    /*鼠标悬浮在该类指向的控件上时滑块的样式*/
    *:hover::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
    }

    /*鼠标悬浮在滑块上时滑块的样式*/
    *::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
    }

    /*正常时候的主干部分*/
    *::-webkit-scrollbar-track {
        border-radius: 10px;
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0);
        background-color: white;
    }

    /*鼠标悬浮在滚动条上的主干部分*/
    *::-webkit-scrollbar-track:hover {
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.4);
        background-color: rgba(0, 0, 0, 0.01);
    }
</style>