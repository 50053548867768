<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 帮助列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-button
                            icon="el-icon-plus"
                            type="primary"
                            @click="handleAdd"
                        >添加小程序网页内容
                        </el-button>
                    </el-col>
                </el-row>
            </div>
            <el-table
                :data="list"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
            >
                <!-- <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                ></el-table-column> -->
                <el-table-column
                    prop="title"
                    label="标题"
                ></el-table-column>
                <el-table-column
                    prop="sub_title"
                    label="子标题"
                ></el-table-column>
                <el-table-column
                    prop="content_url"
                    label="跳转地址"
                ></el-table-column>
                <el-table-column
                    prop="image_url"
                    label="标题icon"
                >
                    <template #default="scope">
                        <img :src="scope.row.image_url" alt="" style="width: 20px">
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="250" align="center">
                    <template #default="scope">
                        <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button size="mini" @click="handleDelete(scope.row)" type="danger">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 30px;">报名小程序网页</div>
            <el-table
                :data="listOfUser"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                style="margin-top: 20px; color: #999;"
            >
                <!-- <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                ></el-table-column> -->
                <el-table-column
                    prop="id"
                    label="id"
                ></el-table-column>
                <el-table-column
                    prop="title"
                    label="标题"
                ></el-table-column>
                <el-table-column
                    prop="sub_title"
                    label="子标题"
                ></el-table-column>
                <el-table-column
                    prop="content_url"
                    label="跳转地址"
                ></el-table-column>
                <el-table-column
                    prop="image_url"
                    label="标题icon"
                >
                    <template #default="scope">
                        <img :src="scope.row.image_url" alt="" style="width: 20px">
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="250" align="center">
                    <template #default="scope">
                        <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button size="mini" @click="handleDelete(scope.row)" type="danger">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>


        <!-- 添加弹出框 -->
        <el-dialog title="帮助信息" v-model="addVisible" width="65%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="网页类型">
                    <el-radio v-model="form.type_id" :label="1">获客小程序</el-radio>
                    <el-radio v-model="form.type_id" :label="2">报名小程序</el-radio>
                </el-form-item>
                <el-form-item label="标题">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="子标题">
                    <el-input v-model="form.sub_title"></el-input>
                </el-form-item>
                <el-form-item label="内容链接">
                    <el-input v-model="form.content_url"></el-input>
                </el-form-item>
                <el-form-item label="标识">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="icon图标">
                    <el-upload
                        class="avatar-uploader"
                        action="/resource/v1/uploadFile"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <el-image
                            style="height: 100%"
                            v-if="image_url"
                            :src="image_url"
                        ></el-image>
                        <i v-else class="el-icon-plus avatar-uploader-icon" style="margin-top: 5rem"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="帮助内容">
<!--                    <el-input type="textarea" v-model="form.content_text"></el-input>-->
                    <editor @input="changeInput" :content_text="form.content_text"></editor>
                </el-form-item>
                <el-form-item>
                    <el-button v-if="submitButState === 1" type="primary" @click="onSubmit">立即创建</el-button>
                    <el-button v-else type="primary" @click="onEdit">编辑</el-button>
                    <el-button @click="addVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
// import { fetchData } from "../../api/api";
// import DataUtils from "../../utils/dateUtils";
import Editor from "../../components/wangEnduit/wangEnduit"
import {fetchData} from "../../api/api";

export default {
    name: "goods",
    components: {
        Editor
    },
    data() {
        return {
            headersObj: {token: JSON.parse(localStorage.getItem('adminInfo')).token},
            image_url: '',
            image_id: 0,
            addVisible: false,
            form: {
                type_id: 1,
                title: '',
                sub_title: '',
                content_url: '',
                content_text: '',
                name: ''
            },
            doc: "",
            list: [],
            itemData: {},
            submitButState:1,
            help_id:0,

            listOfUser: []
        };
    },
    created() {
        this.getHelpsData();
    },
    methods: {
        changeInput(val){
            console.log(val)
            this.form.content_text = val;
        },
        onSubmit() {
            this.form.image_id = this.image_id;
            let params = [
                this.form,
                "/wapp/v1/createHelp", "POST"
            ];
            fetchData(...params).then(
                (res) => {
                    console.log(res)
                    this.addVisible = false;
                    this.getHelpsData();
                }
            )
        },
        handleAdd() {
            this.addVisible = true;
            this.submitButState = 1;
            this.image_url = '';
            this.form = {
                title: '',
                sub_title: '',
                content_url: '',
                content_text: ''
            }
        },
        //编辑
        handleEdit(item) {
            this.help_id = item.id;
            this.submitButState = 2
            this.addVisible = true;
            let params = [
                {help_id: item.id, type_id: item.type_id},
                "/wapp/v1/getHelps", "GET"
            ];
            fetchData(...params).then(
                (res) => {
                    console.log(res.data[0])
                    this.form = {
                        title: res.data[0].title,
                        sub_title: res.data[0].sub_title,
                        content_url: res.data[0].content_url,
                        content_text: res.data[0].content_text,
                        name: res.data[0].name,
                        type_id: res.data[0].type_id
                    }
                    this.image_url = res.data[0].image_url;
                    this.image_id = res.data[0].image_id;
                    console.log(this.form)
                }
            )
        },
        onEdit(){
            console.log(this.help_id)
            this.form.help_id = this.help_id;
            this.form.image_id = this.image_id;
            let params = [
                this.form,
                "/wapp/v1/editHelp", "POST"
            ];
            fetchData(...params).then(
                (res) => {
                    console.log(res)
                    this.addVisible = false;
                    this.getHelpsData();
                }
            )
        },
        // 删除
        handleDelete(item){
            let help_id = item.id;
            let params = [
                {help_id},
                "/wapp/v1/deleteHelp", "GET"
            ];
            fetchData(...params).then(
                () => {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getHelpsData();
                }
            )
        },
        getHelpsData() {
            let params = [
                {},
                "/wapp/v1/getHelps", "GET"
            ];
            fetchData(...params).then(
                (res) => {
                    console.log(res)
                    this.list = res.data;
                }
            )

            fetchData({type_id: 2},"/wapp/v1/getHelps", "GET").then(
                (res) => {
                    console.log(res)
                    this.listOfUser = res.data;
                }
            )
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;


            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        handleAvatarSuccess(res, file) {
            this.image_id = res.data.id;
            this.image_url = URL.createObjectURL(file.raw);
        },
    }
};
</script>

<style lang="scss" scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.table-td-avatar-url {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.form-avatar-url {
    display: block;
    width: 40px;
    height: 40px;
}

.avatar-uploader .el-upload {
    width: 120px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
}

.avatar {
    width: 120px;
    height: 120px;
    display: block;
}

::v-deep .el-upload--text {
    width: 120px;
    height: 120px;
}

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.demonstration {
    margin-right: 1rem;
}
::v-deep .el-overlay{
    z-index: 1 !important;
}
</style>
