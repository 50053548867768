<template>
    <div
        v-if="isShow(sign)"
        class="path-item"
        :class="!isShowSub ? 'hide' : ''"
    >
        <i
            class="select-box"
            :class="checked ? 'el-icon-check' : ''"
            @click="checkMenu"
        ></i>
        <i :class="icon"></i>
        {{ name }}
        <i class="up el-icon-arrow-up" @click="clickMenu"></i>
        <div class="sub-item">
            <menu-list
                v-for="(item, index) in childList"
                :key="index"
                :id="item.id"
                :parentid="item.parentid"
                :sign="item.sign"
                :name="item.name"
                :childList="item.child"
                :checkedSignList="checkedSignList"
                :checked="item.isChecked"
                :parentChecked="checked"
                :indexStr="indexStr + '-' + index"
                :isShowSub="item.isShowSub"
                @checkMenu="childCheckMenu"
                @clickMenu="childClickMenu"
            ></menu-list>
        </div>
    </div>
</template>
<script>
export default {
    name: "menu-list",
    data() {
        return {};
    },
    computed: {
        isShow() {
            return (sign) => {
                if (this.checkedSignList.length === 0) {
                    return true;
                }
                let res = this.checkedSignList.indexOf(sign) !== -1;
                switch (this.radioType) {
                    case "neverHad":
                        res = !res;
                        break;
                    case "all":
                        res = true;
                        break;
                }
                return res;
            };
        },
    },
    props: [
        "radioType",
        "childList",
        "checkedSignList",
        "sign",
        "checked",
        "parentChecked",
        "name",
        "icon",
        "id",
        "parentid",
        "indexStr",
        "isShowSub",
    ],
    emits: ["checkMenu", "clickMenu"],
    methods: {
        checkMenu() {
            // if (this.parentChecked) {
            this.$emit("checkMenu", this.indexStr);
            // }
        },
        childCheckMenu(indexStr) {
            this.$emit("checkMenu", indexStr);
        },
        clickMenu() {
            this.$emit("clickMenu", this.indexStr);
        },
        childClickMenu(indexStr) {
            this.$emit("clickMenu", indexStr);
        },
    },
};
</script>
<style scoped lang="scss">
.show {
    display: block;
}
.path-item {
    padding: 12px 9px;
    padding-right: 0;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
    .up {
        cursor: pointer;
        position: absolute;
        right: 10%;
        transform: rotate(180deg);
        transition: transform 0.4s;
    }
    .select-box {
        cursor: pointer;
        border: 1px solid;
        margin-right: 5px;
        border-radius: 50%;
        padding: 2px;
        font-size: 12px;
        width: 10px;
        display: inline-block;
        height: 10px;
    }
}
.hide {
    // display: none;
    .up {
        transform: rotate(90deg);
    }
    .sub-item {
        height: 0;
        overflow: hidden;
    }
}
</style>