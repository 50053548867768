<template>
	<div class="main">
		<div class="container">
			<div class="handle-box">
				<el-button icon="el-icon-plus" type="primary" @click="openDialog('add')">添加文案
				</el-button>
			</div>
			<not-found v-if="dataList.length === 0" text="暂时还没有朋友圈文案啦"></not-found>
			<el-row :gutter="30" class="content">
				<el-col :span="6" v-for="(item, index) in dataList" :key="index" class="text item">
					<el-card class="box-card">
						<div class="time">{{ item.time_str }}</div>
						<el-input type="textarea" placeholder="请输入内容"
							:autosize="{ minRows: 6, maxRows: 6 }" :show-word-limit="true" :disabled="true"
							:value="item.text">
						</el-input>
						<div class="img-box more">
							<div class="img" v-for="(imgs, imgIndex ) in item.images" :key="imgIndex" alt="">
								<div class="img-pack">
									<img :src="imgs.image_url" />
								</div>
								<el-popconfirm title="确定删除吗？" @confirm="delFriendCircleImg(imgs.id, index, imgIndex)">
									<template #reference>
										<i class="el-icon-close"></i>
									</template>
								</el-popconfirm>
							</div>
							<div class="img" alt="">
								<div class="img-pack">
									<el-upload class="uploader" action="/resource/v1/uploadFile"
										:on-progress="() => handleAvatarProgress(index)"
										:headers="headersObj" :show-file-list="false" :on-success="handleAvatarSuccess"
										:before-upload="beforeAvatarUpload">
										<i class="el-icon-plus avatar-uploader-icon"></i>
									</el-upload>
									<!-- <i class="el-icon-plus"></i> -->
								</div>
							</div>
						</div>
						<div class="title-box">
							<p class="title">
								<!-- {{ item.type_name }} -->
							</p>
							<div class="btn-box">
								<el-button type="success" plain size="mini" @click="openDialog('edit', index)">编辑
								</el-button>
								<el-popconfirm title="确定删除该文案吗？" @confirm="delFriendCircle(item.id)">
									<template #reference>
										<el-button type="danger" plain size="mini">删除</el-button>
									</template>
								</el-popconfirm>
							</div>
						</div>
					</el-card>
				</el-col>
			</el-row>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
					:page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<!-- 编辑弹出框 -->
		<el-dialog :title="dialogTitle" v-model="dialogBox" width="40%" custom-class="dialog-box">
			<div class="dialog-box">
				<el-form class="form-box" ref="form" :model="form" label-width="170px" :hide-required-asterisk="true"
					:status-icon="true">
					<el-form-item label="朋友圈文案">
						<el-input type="textarea" v-model="form.text" :autosize="{ minRows: 6, maxRows: 6 }"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogBox = false">取 消</el-button>
					<el-button type="primary" @click="saveEdit">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
import NotFound from "../../components/NotFound.vue";
import { fetchData } from "../../api/api";
import DataUtils from "../../utils/dateUtils";

export default {
	setup() {
		let types = [
			{
				label: "所有模板方案",
				type_id: 0,
			},
		];
		let activeName = "all";

		return {
			types,
			activeName,
		};
	},
	components: {
		NotFound,
	},
	data() {
		return {
			query: {
				page: 1,
				limit: 12,
			},
			pageTotal: 1,
			dataList: [],
			dialogBox: false,
			handleType: "add",
			dialogTitle: "添加模板",
			visitUrl: "",
			visitType: "",
			form: {},
			imgData: {
				id: 0,
				url: ""
			},
			friendsText: "",
			headersObj: {
				token: JSON.parse(localStorage.getItem("adminInfo")).token,
			},

			nowCircleIndex: 0
		};
	},
	created() {
		this.getTypeList();
	},
	methods: {

		getTypeList() {
			fetchData(
				{ page: this.query.page, limit: this.query.limit },
				"/wapp/firend/getFirendCircles",
				"GET"
			).then((res) => {
				res.data.forEach(item => {
					item.time_str = DataUtils.formatDate(item.create_time, "YY-MM-DD hh:mm");
				});
				this.dataList = res.data;
				// this.pageTotal = res.data.count;
			});
		},
		openDialog(type, index) {
			this.dialogBox = true;
			this.handleType = type;
			this.visitUrl = "/wapp/firend/firendCircle";

			switch (type) {
				case "add":
					this.dialogTitle = "添加朋友圈文案";
					this.visitType = "POST";
					this.form = {
						text: ''
					};
					break;
				case "edit":
					this.visitType = "PUT";
					this.form = {
						id: this.dataList[index].id,
						text: this.dataList[index].text
					}
					this.dialogTitle = "修改朋友圈文案";
					break;
			}
		},
		saveEdit() {
			let data = this.form;
			if (this.form.type_image_id === 0) delete data.type_image_id;
			fetchData(this.form, this.visitUrl, this.visitType).then(() => {
				this.$message.success("操作成功");
				this.dialogBox = false;
				this.getTypeList();
			});
		},
		handlePageChange(val) {
			this.query.page = val;
			this.getTypeList();
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		delFriendCircle(id) {
			fetchData({ id: id }, "/wapp/firend/firendCircle", "DELETE").then(() => {
				this.$message.success("删除成功");
				this.getTypeList();
			});
		},
		handleAvatarSuccess(res) {
			// this.imgData.id = res.data.id;
			// this.imgData.url = res.data.relativeUrl;
			fetchData({ img_id: res.data.id, friend_circle_id: this.dataList[this.newCircleIndex].id  }, "/wapp/firend/firendCircleImg", "POST").then(() => {
				this.$message.success("添加成功");
				let images = this.dataList[this.newCircleIndex].images;

				images.push({image_url: res.data.relativeUrl});
				this.dataList[this.newCircleIndex].images = images;
			});
		},
		delFriendCircleImg(id, index, imgIndex ) {
			fetchData({ id: id }, "/wapp/firend/firendCircleImg", "DELETE").then(() => {
				this.$message.success("删除成功");
				let images = this.dataList[index].images;
				images.splice(imgIndex, 1);
				this.dataList[index].images = images;
			});
		},
		beforeRemove(file) {
			return this.$confirm(`确定移除 ${file.name}？`);
		},
		beforeAvatarUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				this.$message.error("上传头像图片大小不能超过 2MB!");
			}
			return isLt2M;
		},
		handleAvatarProgress(index) {
			this.newCircleIndex = index;
		},
		// handleAvatarSuccess2(res) {
		// 	this.form.type_intro_image_id = res.data.id;
		// 	this.form.type_intro_image_url = res.data.relativeUrl;
		// 	console.log(this.form);
		// },
		// beforeAvatarUpload2(file) {
		// 	const isLt2M = file.size / 1024 / 1024 < 2;

		// 	if (!isLt2M) {
		// 		this.$message.error("上传头像图片大小不能超过 2MB!");
		// 	}
		// 	return isLt2M;
		// }
	},
};
</script>
<style lang="scss" scoped>
img {
	width: 100%;
}

.item {
	margin-bottom: 1.5rem;

	.time {
		margin-bottom: 10px;
		font-size: 14px;
	}

	.img-box {
		display: flex;
		flex-flow: wrap;
		overflow: hidden;

		.img {
			position: relative;
			margin: 0 0.5%;
			margin-top: 8px;

			.img-pack {
				width: 100%;
				height: auto;
				overflow: hidden;
				aspect-ratio: 16/12;
				border-radius: 10px;
				.uploader {
					width: 100%;
					height: 100%;
					::v-deep .el-upload {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		i {
			position: absolute;
			cursor: pointer;
			top: -2px;
			right: -2px;
			color: #fff;
			background: #d57474;
			border-radius: 50%;
			padding: 3px;
			font-size: 12px;

		}

		.el-icon-plus {
			width: 100%;
			height: 100%;
			font-size: 50px;
			background: #fff;
			color: #ccc;
			padding-top: 15%;
		}
	}

	.img-box.more {
		.img {
			width: 32%;
		}
	}


	.title-box {
		position: relative;

		.title {
			font-size: 20px;
			margin: 1rem 0;
		}

		.btn-box {
			// position: absolute;
			// opacity: 0;
			right: 0;
			top: 0;
			transition: opacity 0.4s;
		}
	}

	&:hover {
		& .box-card .title-box .btn-box {
			opacity: 1;
		}
	}
}

.dialog-box {
	.form-box {
		width: 600px;
	}

	overflow-y: auto;
	max-height: 60vh;
}

.introduce-box {
	position: relative;

	.introduce-item {
		padding-top: 5px;
		margin-bottom: 1rem;
	}

	.del-btn,
	.up-btn,
	.down-btn {
		position: absolute;
		cursor: pointer;
		top: -8px;
		right: -8px;
		color: #fff;
		border-radius: 50%;
		font-size: 14px;
		padding: 3px;
		background-color: #f56c6c;
	}

	.up-btn {
		right: 92%;
		background-color: #409eff;
	}

	.down-btn {
		right: 82%;
		background-color: #409eff;
	}
}

.pagination {
	margin-top: 1rem;
}

.content {
	overflow: hidden;
	margin-top: 1rem;
	margin-bottom: 1rem;

	.item {
		margin-bottom: 1rem;
	}
}
</style>
