<template>
    <div class="error-page">
        <!-- <div class="error-code">4<span>0</span>3</div> -->
        <div class="error-desc">
			<p>测试</p>
			<p>
				<!-- <todo-button>
					啥是一个好地方
				</todo-button>
                <oss-file></oss-file> -->
			</p>
        </div>
        <!-- <admin-label></admin-label> -->
        <role-permission :roleId="3"></role-permission>
    </div>
</template>

<script>
// import TodoButton from '../../components/test/todo-button.vue'
// import OssFile from '../../components/resource/OssFile.vue'
// import AdminLabel from '../../components/resource/adminTag/AdminLabel.vue'
import RolePermission from "../../components/permission/Rolepermission.vue";


export default {
	components: {
		// TodoButton,
        // OssFile,
        // AdminLabel,
        RolePermission
	},
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>


<style scoped>
.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #f3f3f3;
    box-sizing: border-box;
}
.error-code {
    line-height: 1;
    font-size: 250px;
    font-weight: bolder;
    color: #f02d2d;
}
.error-code span {
    color: #00a854;
}
.error-desc {
    font-size: 30px;
    color: #777;
}
.error-handle {
    margin-top: 30px;
    padding-bottom: 200px;
}
.error-btn {
    margin-left: 100px;
}
</style>