<template>
    <div class="box-class">
        <div class="left-box-class">
            <!--            <img src="../../assets/img/img.jpg" style="width: 100%" alt="">-->
            <div v-html="htmlText" @click="tokenDetail($event)"></div>
        </div>
        <div class="right-box-class">
            <div class="detail-class" v-for="(val,index) in detailData"
                 :key="index">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="标题">
                        <el-input v-model="val.title"></el-input>
                    </el-form-item>
                    <el-form-item label="类名">
                        <el-input v-model="val.class_name"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="val.value"></el-input>
                    </el-form-item>
                    <el-form-item label="标签属性">
                        <el-select v-model="val.attr" placeholder="请选择标签属性">
                            <el-option v-for="(item,index) in attrData" :key="index" :value="item.value"
                                       :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="输入类型">
                        <el-select v-model="val.add_type" placeholder="请选择输入类型">
                            <el-option v-for="(item,index) in addTypeData" :key="index" :value="item.value"
                                       :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-button class="addTemplateBut" type="primary" @click="onSubmit">创建模板字段</el-button>
    </div>
</template>

<script>

import {fetchData} from "../../api/api";

export default {
    props: ['addContent'],
    name: "TemplateDetail",
    data() {
        return {
            detailData: [],
            attrData: [
                {label: 'innter', value: 'innter'},
                {label: 'src', value: 'src'},
                {label: 'value', value: 'value'},
                {label: 'poster', value: 'poster'},
            ],
            addTypeData: [
                {label: 'input', value: 'input'},
                {label: 'upload', value: 'upload'},
                {label: 'textarea', value: 'textarea'}
            ],
            form: {
                attr: '',
                add_type: '',
                title: '',
                class_name: '',
                value: '',
            },
            isShow: undefined,
            templateHtml: `<img src="./img/img.jpg" style="width: 100%" alt="">`,
            // htmlText: `<div class="tem-box-class" style="100%">
            //                 <div class="img-box-class">
            //                     <div class="img-class" style="width: 20%;height: 0px;padding-bottom: 20%;background: pink;color: #666;float: left;line-height: 20%;position:relative;">
            //                         <img className="img1" src="./img/login-bg.jpg" style="width: 100%;display:flex;vertical-align: middle;position:absolute;top:50%;transform:translateY(-50%);" alt="">
            //                     </div>
            //                     <div class="img-class" style="width: 20%;height: 0px;padding-bottom: 20%;background: pink;color: #666;float: left;line-height: 20%;position:relative;">
            //                         <img className="img2" src="./img/login-bg.jpg" style="width: 100%;display:flex;vertical-align: middle;position:absolute;top:50%;transform:translateY(-50%);" alt="">
            //                     </div>
            //                     <div class="img-class" style="width: 20%;height: 0px;padding-bottom: 20%;background: pink;color: #666;float: left;line-height: 20%;position:relative;">
            //                         <img className="img3" src="./img/login-bg.jpg" style="width: 100%;display:flex;vertical-align: middle;position:absolute;top:50%;transform:translateY(-50%);" alt="">
            //                     </div>
            //                     <div class="img-class" style="width: 20%;height: 0px;padding-bottom: 20%;background: pink;color: #666;float: left;line-height: 20%;position:relative;">
            //                         <img className="img4" src="./img/login-bg.jpg" style="width: 100%;display:flex;vertical-align: middle;position:absolute;top:50%;transform:translateY(-50%);" alt="">
            //                     </div>
            //                     <div class="img-class" style="width: 20%;height: 0px;padding-bottom: 20%;background: pink;color: #666;float: left;line-height: 20%;position:relative;">
            //                         <img className="img5" src="./img/login-bg.jpg" style="width: 100%;display:flex;vertical-align: middle;position:absolute;top:50%;transform:translateY(-50%);" alt="">
            //                     </div>
            //                 </div>
            //             </div>`
            htmlText: this.addContent.content,
            template_id: this.addContent.template_id
        }
    },
    methods: {
        getList(html) {
            this.templateHtml = html;
        },
        tokenDetail: function (e) {
            console.log(e.target.attributes)

            let attr_data = '';
            let add_type = '';
            let title = '';
            let classNameValue = '';
            let d_value = '';
            e.target.attributes.forEach(item => {
                console.log(item.name)
                if (item.name === 'classname') {
                    classNameValue = item.value;
                    title = item.value + '标题';
                }
                if (item.name === 'src') {
                    attr_data = item.name;
                    add_type = 'upload';
                    d_value = item.value;
                }
            })

            let state = 0;
            this.detailData.forEach(item => {
                if (item.class_name === classNameValue) {
                    state = 1;
                }
            })
            if (state === 1) {
                this.$message('已经添加过该类名，请编辑');
                return;
            }

            let info = {
                class_name: classNameValue,
                title: title,
                value: d_value,
                attr: attr_data,
                add_type: add_type,
            }

            this.detailData = [info, ...this.detailData];
            // this.detailData.push(info)
            console.log(this.detailData);
        },
        onSubmit() {
            const _this = this;
            this.detailData.forEach(item => {
                item.template_id = _this.template_id;
                fetchData(item, "/admin/v1/addLessonTemField", "POST").then(
                    (res) => {
                        if (res.code === 200) {
                            console.log(res)
                        }
                    }
                );
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.box-class {
    height: 60vh;
}

.left-box-class {
    width: 48%;
    height: 50vh;
    float: left;
    border: 1px solid green;
}

.right-box-class::-webkit-scrollbar {
    width: 0 !important
}

.right-box-class {
    -ms-overflow-style: none;
}

.right-box-class {
    overflow: -moz-scrollbars-none;
}

.right-box-class {
    padding: 2%;
    width: 46%;
    height: 44.5vh;
    float: right;
    overflow-y: auto;
    //border: 1px solid green;
    background: #f2f2f2;
    border-radius: 5px;
}


.detail-class {
    border: 1px solid #606266;
    padding: 10px 10px 0 10px;
    border-radius: 5px;
}

.detail-class:not(:first-child) {
    margin-top: 15px;
}
.addTemplateBut{
    position: absolute;
    bottom: 5%;
    right: 10%;
}
</style>
