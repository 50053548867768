<template>
    <div>
        <p class="top">
            <span class="managePoint" @click="managePoint">{{ managePointsContent }}</span>
            <el-input :class="inputType" ref="input" v-model="inputValue" size="mini" placeholder="请输入内容"></el-input>
            <span class="addClick" :class="inputType" @click="inputDwon">确定</span>
        </p>
        <hr />
        <Tag
            :points="points"
            :isClick="isClick"
            :isEdit="true"
            @addpoints="addPoints"
            @selectPoints="selectPoints"
            @editPoint="editPoint"
        ></Tag>
            <!-- @dangerPoints="dangerPoints" -->
    </div>
</template>

<script>
import Tag from "./Tag.vue";
import { fetchData } from "../../../api/api";

export default {
    name: "LabelDialog",
    components: {
        Tag,
    },
    computed: {
        inputType() {
            return this.isShowPointInput ? 'show' : '';
        }
    },
    props: ["category", "categoryId"],
    emits: ["getCheckedpoints"],
    data() {
        return {
            isClick: true,
            isShowPointInput: false,
            managePointsContent: "管理标签",
            inputValue: "",
            theInputOperate: "add", // add,  edit
            editPointId: 0,
            points: [],
        };
    },
    created() {
        this.getTags();
    },
    methods: {
        getTags() {
            fetchData({partial_name: '', page: 1, limit: 999999}, "/edu/v1.2/listPoints", "GET").then((res) => {
                this.points = res.data.list;
            });
        },
        addPoints() {
            this.isShowPointInput = true;
            this.$refs.input.focus();
        },
        inputDwon() {
            this.addPoints();
            if (this.theInputOperate === "add") {
                fetchData({ point_name: this.inputValue}, "/edu/v1.2/createPoint", "POST").then(() => {
                    this.getTags();
                    this.$message.success("添加成功");
                    this.isShowPointInput = false;
                });
            }
            if (this.theInputOperate === "edit") {
                fetchData({point_id: this.editPointId, point_name: this.inputValue}, "/edu/v1.2/editPoint", "GET").then(() => {
                    this.getTags();
                    this.$message.success("修改成功");
                    this.isShowPointInput = false;
                });
            }
        },
        editPoint(Point) {
            this.addPoints();
            this.inputValue = Point.point_name;
            this.editPointId = Point.point_id;
            this.theInputOperate = 'edit';
        },
        selectPoints(points) {
            this.$emit("getCheckedpoints", points);
        },
        managePoint() {
            this.isClick = !this.isClick;
            this.managePointsContent = this.isClick ? "管理标签" : "返回";
        },
        // dangerPoints(id) {
        //     fetchData({id: id}, "/bureau/v1.2/deleteTag", "GET").then(() => {
        //         this.getTags();
        //         this.$message.success("删除成功");
        //     });
        // },
    },
};
</script>

<style lang="scss" scoped>
.top {
    .managePoint {
        padding: 3px 5px;
        cursor: pointer;
        border-radius: 5px;
        background-color: #0099CC;
        color: #fff;
    }
    .el-input {
        margin-left: 5px;
        padding: 5px;
        border-radius: 5px;
        outline-color: #f99;
        width: 0px;
        opacity: 0;
        transition: width 0.5s, opacity 0.5s;
        &.show {
            width: 40%;
            opacity: 1;
        }
    }
    .addClick {
        margin-left: 5px;
        padding: 5px 5px;
        cursor: pointer;
        border-radius: 5px;
        background-color: #99CC66;
        color: #fff;
        opacity: 0;
        transition: opacity 0.5s;
        &.show {
            opacity: 1;
        }
    }
}
hr {
    margin: 5px 0;
}
</style>